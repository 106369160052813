import { useCallback } from "react";
import { useSelector } from "react-redux";
import localStore, { STORE_NAME, STORE_PREFIX } from "../utils/localStore";

export const dependencyStore = localStore.createInstance({
  storeName: `${STORE_PREFIX}-dependencies`,
  name: STORE_NAME,
});

export function useDependencies() {
  const netConversions = useSelector((state) => state.web.netConversions);
  const dependencies = useSelector((state) => state.web.dependencies || {});
  const {
    banks,
    countries,
    service_categories: categories,
    currencies,
    services,
    registration_types,
  } = dependencies;

  const getBank = useCallback(
    (id) => (id ? banks?.find((bank) => bank.id === id) : banks),
    [banks]
  );

  const getCountry = useCallback(
    (id) =>
      id ? countries?.find((country) => +country.id === +id) : countries,
    [countries]
  );

  const getService = useCallback(
    (id) => (id ? services?.find((service) => service.id === +id) : services),
    [services]
  );

  const getCategory = useCallback(
    (id) =>
      id ? categories?.find((category) => category.id === id) : categories,
    [categories]
  );

  const getCurrency = useCallback(
    (id) =>
      id ? currencies?.find((currency) => +currency.id === +id) : currencies,
    [currencies]
  );

  const getStoredDependencies = async () => {
    const dependency = await dependencyStore.getItem("data");
    return dependency;
  };

  const storeDependeciesToLocal = (deps, callback) => {
    dependencyStore.setItem("data", deps, () => callback && callback());
  };

  const getNetConversion = (buyingCurrency = 1, sellingCurrency = 1) => {
    const othersToCurrency = netConversions?.othersToCurrency;

    return othersToCurrency[buyingCurrency][sellingCurrency]["net_rate"];
  };

  return {
    dependencies,
    registrationTypes: registration_types,
    getNetConversion,
    getBank,
    getCountry,
    getService,
    getCategory,
    getCurrency,
    getStoredDependencies,
    storeDependeciesToLocal,
  };
}
