import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import useVerify from "../../../../modules/web/pages/Verification/useVerify";
import { GreenText } from "../../../../styling/Text";
import Button from "../../../library/Button";
import Input from "../../../library/Input";
import { ResendCode } from "./style";
import CurrencyModal from "../../../UI/CurrencyChanger";
import { loginHandler } from "../../../../modules/auth/service";
import Notify from "../../../../utils/notify";
import useAuthentication from "../../../../hooks/useAuthetication";

function Form({ onVerify }) {
  const [data, setData] = useState({ loading: false, code: "" });
  const [showCurrencyModal, setCurrencyModal] = useState(false);
  const { authenticated } = useAuthentication();

  const dispatch = useDispatch();

  const toggleLoading = useCallback(
    () => setData((prev) => ({ ...prev, loading: !prev.loading })),
    []
  );

  const { resendCode, loginUser, verifyUser } = useVerify();

  const onCurrencyChange = async () => {
    await dispatch(loginHandler());
    onVerify();
  };

  const loginSuccess = () => {
    toggleLoading();
    setCurrencyModal(true);
  };

  const handleChange = (e) =>
    setData((prev) => ({ ...prev, code: e.target.value }));

  const postVerificationCallback = async () => {
    Notify.success("Verification successful");

    if (!authenticated) {
      return loginUser(loginSuccess);
    }

    setCurrencyModal(true);
    toggleLoading();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    toggleLoading();
    await verifyUser({
      code: data.code,
      onSuccess: postVerificationCallback,
      onError: toggleLoading,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Input code here e.g. 123456"
          maxLength="6"
          value={data.code}
          onChange={handleChange}
        />
        <ResendCode type="button" onClick={resendCode}>
          <GreenText bold>Resend Code</GreenText>
        </ResendCode>
        <Button isLoading={data.loading} size="lg" type="submit" isFullWidth>
          Verify
        </Button>
      </form>
      <CurrencyModal
        show={showCurrencyModal}
        onHide={() => setCurrencyModal(false)}
        changeSuccess={onCurrencyChange}
      />
    </>
  );
}

export default Form;
