export const buttonStyle = {
  defaultProps: {
    variant: "primary",
  },
  baseStyle: {
    fontSize: "50px",
    _focus: {
      outline: "none",
      transform: "scale(1.05)",
    },
  },
  sizes: {
    xl: {
      h: "56px",
      fontSize: "xl",
      px: "32px",
    },
  },
  variants: {
    solid: {
      borderRadius: "button",
    },
    white: {
      background: "whiteAlpha.900",
      color: "text.body",
      minWidth: "100px",
      borderRadius: "button",
      _hover: {
        background: "whiteAlpha.700",
        color: "text.body",
        boxShadow: "md",
      },
      _active: {
        boxShadow: "sm",
        transform: "scale(0.99)",
        dataActive: "true",
      },
    },
    primary: {
      background: "primary.500",
      color: "white",
      minWidth: "100px",
      borderRadius: "button",
      _hover: {
        background: "primary.600",
        boxShadow: "md",
      },
      _active: {
        boxShadow: "sm",
        transform: "scale(0.99)",
        dataActive: "true",
      },
    },
    seller: {
      background: "secondary.500",
      color: "white",
      minWidth: "100px",
      borderRadius: "button",
      _hover: {
        background: "secondary.600",
        boxShadow: "md",
      },
      _active: {
        boxShadow: "sm",
        transform: "scale(0.99)",
        dataActive: "true",
      },
    },
    primaryOutline: {
      background: "white",
      color: "primary.500",
      minWidth: "100px",
      borderColor: "primary.500",
      borderRadius: "button",
      border: "1px solid",
      _hover: {
        background: "primary.600",
        boxShadow: "md",
        color: "white",
      },
      _active: {
        boxShadow: "sm",
        transform: "scale(0.99)",
        dataActive: "true",
      },
    },
    sellerOutline: {
      background: "white",
      color: "secondary.500",
      minWidth: "100px",
      borderColor: "secondary.500",
      borderRadius: "button",
      border: "1px solid",
      _hover: {
        background: "secondary.600",
        boxShadow: "md",
        color: "white",
      },
      _active: {
        boxShadow: "sm",
        transform: "scale(0.99)",
        dataActive: "true",
      },
    },
    disabled: {
      borderRadius: "button",
      opacity: 0.8,
      background: "gray.500",
    },
    icon: {
      background: "transparent",
      minWidth: "0",
      borderRadius: "radius",
      p: 2,
      color: "#9797AA",
    },
  },
};
