import { LandingSection } from './LandingSection';
import { Box, Heading, Text } from '@chakra-ui/layout';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const testimonials = [
  {
    comment:
      'I work with freelancers and full-time workers and pay them in any currency of their choice while terawork allows me to manage all that seamlessly.',
    company: 'StackWise',
    name: 'Helen',
    role: 'COO',
  },

  {
    comment:
      'I partnered with TERAWORK when I was looking for AI professionals which we lack in our team to help integrate AI/ML into our systems. They were able to get us a freelance talent who has mastered the skill to help execute, delivering maximum value for our time and investment.',
    company: 'Inbrid Energies',
    name: 'Ahmad',
    role: 'CTO',
  },
  {
    comment:
      'The main reason we chose to work with TERAWORK is that our in-house HR was limited in getting talents with the right skills, culture, and flexibility we needed across our entire workforce.',
    company: 'NIT',
    name: 'Alex',
    role: 'Founder',
  },
  {
    comment:
      'We have hired more than 100 professionals on TERAWORK and we have experienced a 100% success rate.',
    company: 'Helium Health',
    name: 'Bolanle',
    role: 'Head of Service Management',
  },
  {
    comment:
      'TERAWORK has successfully supplied us with reliable and creative professionals,  we truly commend their role in giving young African talents the opportunity to work without borders.',
    company: 'Afex',
    name: 'Ayodeji',
    role: 'CEO',
  },
  {
    comment:
      'Super proud of the amazing work you are doing. And thank you for giving us access to amazing professionals who have contributed to the growth of our company.',
    company: 'Teal Harmony',
    name: 'Folake',
    role: 'Founder',
  },
];

const TestimonialCard = ({ company, comment, name, role }) => {
  return (
    <Box
      rounded="3xl"
      p="6"
      bg="bg.emphasis"
      // boxShadow="rgba(33, 192, 252, 0.1) 0px 10px 15px -3px, rgba(33, 192, 252, 0.05) 0px 4px 6px -2px"
    >
      <Text my="6" fontStyle="italic">
        "{comment}"
      </Text>

      <Text fontWeight={700} fontSize="lg">
        {name}
      </Text>
      <Text color="text.nav" fontSize="sm">
        {role}, {company}
      </Text>
    </Box>
  );
};

export const ReviewTestimonial = () => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 991: 2, 1150: 3 }}>
      <Masonry gutter="20px">
        {testimonials.map((review) => (
          <TestimonialCard {...review} key={review.name} />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export const Testimonials = () => {
  return (
    <LandingSection>
      <Heading size="4xl" mb="10" maxW="400px">
        What our customers say
      </Heading>

      <Box mt={[8, null, 12]} mb={['4', null, '30']}>
        <ReviewTestimonial />
      </Box>
    </LandingSection>
  );
};
