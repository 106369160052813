import Auth from './Auth';

export const isTalent = () => {
  const auth = new Auth();

  const data = auth.getDataFromToken();

  if (data && data['cognito:groups']) {
    return data['cognito:groups'].includes('Talents');
  }

  return false;
};
