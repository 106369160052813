export const SET_PENDING_ORDER = 'SET_PENDING_ORDER';

export const SET_BUYER_DATA = 'SET_BUYER_DATA';

export const SAVED_PROFILE = 'SAVED_PROFILE';
export const RECENTLY_VISITED = 'RECENTLY_VISITED';
export const RECENT = 'RECENT';
export const ONGOING_WORK = 'ONGOING_WORK';
export const WORK_HISTORY = 'WORK_HISTORY';
export const SET_JOBPOSTS = 'SET_JOBPOSTS';
export const SET_POST = 'SET_POST';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_WALLET = 'SET_WALLET';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';
export const SET_REVIEWS = 'SET_REVIEWS';
