import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Bold } from '../../../../styling/Text';
import { Link } from 'react-router-dom';
import { closePromoBanner } from '../../../../modules/web/pages/PromoStore/service';
import { usePromoBanner } from '../../../../hooks/usePromoBanner';
import { TextColor } from '../../../../constants/Colors';
import { Banner } from '../../../Banner';

const PromoLink = styled(Link)`
  border-bottom: 1px solid ${TextColor.primary};
  &:hover {
    border-bottom: none;
  }
`;

const PromoBanner = ({ showBanner }) => {
  const dispatch = useDispatch();
  const { banner } = usePromoBanner();

  const closeBanner = (promo) => {
    dispatch(closePromoBanner(promo));
  };

  return banner.show && banner.promo && showBanner ? (
    <Banner onClose={() => closeBanner(banner.promo)}>
      <span role="img" aria-label="ddfd">
        🥳🥳
      </span>{' '}
      <Bold>Amazing Discounts | </Bold>
      <PromoLink
        target="_blank"
        rel="noreferrer noopener"
        to={`/promo/${banner.promo?.id}`}
      >
        {' '}
        {banner.promo?.title}
      </PromoLink>
    </Banner>
  ) : null;
};

export default PromoBanner;
