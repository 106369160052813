import React, { useCallback } from 'react';
import Socket from './modules/buyer/pages/Chat/services/socket';
import { connect } from 'react-redux';
import _ from 'lodash';
import Notify from './utils/notify';
import {
  initWSHandler,
  initConversationsHandler,
  connectionStatusHandler,
  addMessageHandler,
  deleteMessageHandler,
  initMessagesHandler,
  addConversationMessage,
  setLastMessage,
} from './modules/buyer/pages/Chat/service';
import {
  setActiveChats,
  setSocket,
  setMessage,
  updateConversations,
} from './modules/buyer/pages/Chat/store';
import { useEffect } from 'react';
import { useRef } from 'react';
import {
  addNewUser,
  setAdminConv,
} from './modules/buyer/pages/Chat/store/actions';
import { useToast } from '@chakra-ui/toast';
import { trimText } from './utils/transfomer';
import { useChat } from './hooks/useChat';

const SocketHandler = ({
  authenticated,
  activeChat,
  buyer,
  dispatch,
  children,
  messages,
}) => {
  const socketOpened = useRef(false);
  const activeChatRef = useRef(activeChat);
  const toast = useToast();
  const { setNewMessageIndicator } = useChat();
  const setNewMessageIndicatorRef = useRef();
  setNewMessageIndicatorRef.current = setNewMessageIndicator;

  const sendNotification = useCallback((msg) => {
    //conditions to not send notification
    if (
      msg.conversationId === activeChatRef.current.conversationId ||
      !msg.passedViolation
    ) {
      return;
    }

    Notify.success(
      `New ${msg?.messageType ?? 'message'} from ${msg.senderName}`
    );
  }, []);

  const sendAdminNoty = useCallback(
    (msg) => {
      if (
        !activeChatRef.current?.conversationId ||
        activeChatRef.current.conversationId !== msg.conversationId
      ) {
        toast({
          title: 'New Message from TERAWORK Support',
          description: trimText(msg.message, 40),
          position: 'top-right',
          isClosable: true,
          status: 'success',
          variant: 'subtle',
          duration: 5000,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    activeChatRef.current = activeChat;
  }, [activeChat]);

  useEffect(() => {
    if (authenticated && buyer?.username && !socketOpened.current) {
      const username = buyer?.username;
      const ws = dispatch(initWSHandler(username));
      let socket = new Socket(username, ws);
      socketOpened.current = true;

      socket.on('conv-list', (data) => {
        dispatch(initConversationsHandler(data.convs, username));
        dispatch(connectionStatusHandler(data.conns));
      });

      socket.on('online-users', (conn) => {
        dispatch(connectionStatusHandler(conn.users));
      });

      socket.on('sent', (msg) => {
        //Replace conversationId for newUser
        if (
          activeChatRef.current.newUser &&
          msg.receiverName === activeChatRef.current.name
        ) {
          dispatch(
            setActiveChats({
              ...activeChat,
              newUser: false,
              conversationId: msg.conversationId,
            })
          );
          dispatch(setMessage([msg]));
        }
        dispatch(setLastMessage({ msg }));
        dispatch(addMessageHandler(msg, username));
      });

      socket.on('failed-msg', (msg) => {
        dispatch(deleteMessageHandler(msg));
      });

      socket.on('incoming', (msg) => {
        dispatch(addMessageHandler(msg, username));
        dispatch(setLastMessage({ msg }));
        sendNotification(msg);
        setNewMessageIndicatorRef.current({ msg });
      });

      socket.on('onsend-message', (msg) => {
        dispatch(addMessageHandler(msg, username));
        socket.send(JSON.stringify(msg));
      });

      socket.on('msg-list', (data) => {
        const message = dispatch(initMessagesHandler(data, messages, username));
        let conversationId = activeChat.conversationId || undefined;
        if (conversationId) {
          dispatch(setMessage(message[conversationId]));
        }
      });

      socket.on('user-add', (user) => dispatch(addNewUser(user)));

      socket.on('chat-support', () => {
        Notify.success(
          'Your Request has been sent, an Admin will be with you soon'
        );
      });

      socket.on('tera-conv-list', (data) => {
        dispatch(setAdminConv(data.convs[0]));
      });

      socket.on('tera-conv-update', (data) => {});

      socket.on('tera-msg-list', (data) => {
        const message = dispatch(initMessagesHandler(data, messages, username));
        let conversationId = activeChat.conversationId || undefined;
        if (conversationId) {
          dispatch(setMessage(message[conversationId]));
        }
      });

      socket.on('tera-incoming', (msg) => {
        dispatch(addMessageHandler(msg, username));
        dispatch(setLastMessage({ msg, isAdminChat: true }));
        sendAdminNoty(msg);
        setNewMessageIndicatorRef.current({
          msg,
          isAdminChat: true,
        });
      });

      socket.on('conv-update', (data) => {
        dispatch(setActiveChats(data));
        dispatch(updateConversations(data));
        dispatch(addConversationMessage(data));
      });

      dispatch(setSocket(socket));
    }
  }, [
    authenticated,
    buyer,
    dispatch,
    activeChat,
    messages,
    sendNotification,
    sendAdminNoty,
  ]);

  useEffect(() => {
    const activeConversation = activeChat?.conversationId;

    if (
      activeConversation &&
      !_.isEqual(messages[activeConversation], messages) &&
      messages[activeConversation]
    ) {
      dispatch(setMessage(messages[activeConversation]));
    }
  }, [messages, activeChat, dispatch]);

  return <>{children}</>;
};

const mapStateToProps = (state) => {
  return {
    users: state.chat.users,
    activeChat: state.chat.activeChat,
    messages: state.chat.messages,
    message: state.chat.message,
    socket: state.chat.socket,
    chatType: state.chat.chatType,
    onlineUsers: state.chat.onlineUsers,
    application: state.auth.application,
    authenticated: state.auth.authenticated,
    buyer: state.buyer.user,
    seller: state.seller.user,
  };
};

export default connect(mapStateToProps)(SocketHandler);
