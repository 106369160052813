export const ElementColor = {
  primary: '#83BC23',
  secondary: '#21C0FC',
  dark: '#4B7466',
  white: '#FFF',
  darkGrey: '#BDBDBD',
  line: '#AFAFAF',
  lineLight: '#ddd',
  primaryTrns: 'rgba(131, 188, 35, 0.2);',
  secondaryTrns: 'rgba(33, 192, 252, 0.2);',
  offWhite: '#FAFAFA',
  danger: '#D80027',
  star: '#ffbe5b',
};

export const TextColor = {
  primary: '#18191f',
  green: '#37A000',
  label: '#969BAB',
  red: '#D80027',
  yellow: '#ffd700',
  gold: '#ffbe5b',
};
