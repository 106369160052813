import React from 'react';
import { connect } from 'react-redux';
import { toggleAuthModal } from '../../../../modules/auth/store/actions';
import { GreenText } from '../../../../styling/Text';
import { PlainButton } from '../../../library/Button/style';
import { VStack } from '../../../library/layout/Stack';
import Modal from '../../../library/Modal';
import Form from './form';

function LoginModal({ show, toggle, toggleSignup, authDetails }) {
  const showSignup = () => {
    toggle();
    toggleSignup();
  };

  return (
    <Modal isOpen={show} toggleModal={toggle}>
      <Modal.Content>
        <Modal.Header>Log in</Modal.Header>
        <Modal.Body>
          <VStack spacing="1rem" alignItems="stretch">
            <Form toggle={toggle} authDetails={authDetails} />
            <PlainButton $isFullWidth onClick={showSignup}>
              <GreenText $isLink>Don't have an account? Signup here</GreenText>
            </PlainButton>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.auth.modals.login,
    authDetails: state.auth.authDetails,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    toggle: () => dispatch(toggleAuthModal('login')),
    toggleSignup: () => dispatch(toggleAuthModal('signup')),
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(LoginModal);
