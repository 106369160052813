import * as actions from "./store";
import moment from "moment";
import store from "../../../../store";
import socketIOClient from "socket.io-client";
import { updateAdminLastMessage, updateLastMessage } from "./store/actions";

export const initWSHandler = (username) => {
  return (dispatch) => {
    const ws = socketIOClient(
      `wss://chat.terawork.com:8443?username=${username}`
    );

    dispatch(actions.setWs(ws));
    return ws;
  };
};

export const initConversationsHandler = (convs, username) => {
  return (dispatch) => {
    const messages = convs.reduce((obj, item) => {
      return {
        ...obj,
        [item["conversationId"]]: [],
      };
    }, {});

    const conversations = convs.map((conv) => {
      const newObj = { ...conv, newMessage: false };
      if (!newObj.timestamp) {
        newObj.timestamp = 0;
      }
      const timestamp = parseInt(conv.timestamp, 10);
      const myLastSeen = conv.participants.find(
        (member) => member.name?.toLowerCase() === username?.toLowerCase()
      )?.lastseen;

      if (
        myLastSeen &&
        moment(new Date(myLastSeen)).isBefore(
          moment(new Date(newObj.timestamp)),
          "seconds"
        )
      ) {
        newObj.newMessage = true;
      }

      return {
        ...newObj,
        lastMessageTime: conv.lastMessageTime || timestamp,
        timestamp,
      };
    });

    dispatch(actions.setConversations(conversations));
    dispatch(actions.setMessages(messages));
  };
};

export const addConversationHandler = (conv) => {
  return (dispatch) => {
    const message = { [conv["conversationId"]]: [] };

    const { chat } = store.getState();

    //If conversation is a workstore conversation, create the conversation first
    if (conv.workstoreId) {
      const msgStruct = {
        action: "newConversation",
        receiverName: conv.receiverName,
        receiverType: conv.receiverType,
        receiverId: conv.receiverId,
        timestamp: conv.timestamp,
        senderName: conv.senderName,
        senderType: conv.senderType,
        senderId: conv.senderId,
        PK: conv.PK,
        workstoreId: conv.workstoreId,
      };

      chat.socket.emit(msgStruct.action, msgStruct);
    }

    dispatch(actions.setConversations([conv, ...chat.conversations]));
    dispatch(actions.setMessages({ ...message, ...chat.messages }));
  };
};

export const addConversationMessage = (conv) => {
  return (dispatch) => {
    const { chat } = store.getState();
    const msgExist = chat.messages[conv.conversationId];
    if (!msgExist) {
      dispatch(
        actions.setMessages({ [conv.conversationId]: [], ...chat.messages })
      );
    }
  };
};

export const connectionStatusHandler = (connections) => {
  return (dispatch) => {
    let onlineUsers = [...new Set(connections)];
    dispatch(actions.setOnlineUsers(onlineUsers));
  };
};

export const initMessagesHandler = (data, messages, username) => {
  return (dispatch) => {
    const appStore = store.getState();
    const conversations = appStore.chat.conversations;
    let orderedMessages = data.messages.map((r) => {
      let timestamp =
        r.deliver && r.senderName !== username ? r.deliveryTime : r.timestamp;

      timestamp = parseInt(timestamp, 10);

      let date = moment(new Date(timestamp)).format("DD MM YYYY");
      let time = moment(new Date(timestamp)).format("HH:mm");

      return { ...r, date, time, timestamp };
    }, {});

    orderedMessages = orderedMessages.sort(function (a, b) {
      return a.timestamp - b.timestamp;
    });

    orderedMessages = orderedMessages.map((r) => {
      let { timestamp } = r;

      let date = moment(new Date(timestamp)).format("DD MM YYYY");
      let time = moment(new Date(timestamp)).format("HH:mm");

      return { ...r, date, time };
    }, {});
    const message = {
      ...messages,
      [data.conversationId]: orderedMessages,
    };

    const convs = conversations.map((obj) => {
      if (
        obj.conversationId === data.conversationId &&
        orderedMessages.length > 0
      ) {
        const timeline =
          orderedMessages[orderedMessages.length - 1].timestamp ||
          obj.timestamp;
        return {
          ...obj,
          timestamp: timeline,
          lastMessageTime: timeline,
        };
      }
      return obj;
    });

    dispatch(actions.setConversations(convs));
    dispatch(actions.setMessages(message));
    return message;
  };
};

export const addMessageHandler = (msg, username) => {
  return (dispatch) => {
    const appStore = store.getState();
    const conversations = appStore.chat.conversations;
    const messages = appStore.chat.messages;

    let messageExist = messages[msg.conversationId];
    let oldConversation;

    /**
     * If message does not exist,
     * further check if the receiver belongs to a conversation,
     * change conversation id if true
     **/

    if (!messageExist) {
      const newUser = conversations.find(
        (conv) => conv.members.includes(msg.receiverName) && conv.newUser
      );

      if (!newUser) {
        return;
      }

      oldConversation = newUser;
    }

    let timestamp =
      msg.deliver && msg.senderName !== username
        ? msg.deliveryTime
        : msg.timestamp;

    timestamp = parseInt(timestamp, 10);

    let date = moment(new Date(timestamp)).format("DD MM YYYY");
    let time = moment(new Date(timestamp)).format("HH:mm");

    //let message = messages;
    const messageConversationId = oldConversation
      ? oldConversation.conversationId
      : msg.conversationId;

    const message = messages[messageConversationId].find(
      (message) => message.PK === msg.PK
    );

    const payload = { ...msg, date, time, timestamp };

    if (!message) {
      const data = [...messages[messageConversationId], payload];
      let orderedMessages = data.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });
      const newMessages = Object.assign({}, messages, {
        [msg.conversationId]: orderedMessages,
      });

      const convs = conversations.map((obj) => {
        if (
          obj.conversationId === msg.conversationId &&
          orderedMessages.length > 0
        ) {
          const timeline =
            orderedMessages[orderedMessages.length - 1].timestamp;

          return {
            ...obj,
            timestamp: timeline,
            lastMessageTime: timeline,
          };
        }
        return obj;
      });

      if (!messageExist && oldConversation) {
        let newConvo;
        //replace the conversationId with the new one from message
        const replacedConvs = convs.map((obj) => {
          if (obj.conversationId === oldConversation.conversationId) {
            const newConvObject = {
              ...obj,
              newUser: false,
              conversationId: msg.conversationId,
            };

            newConvo = { ...newConvObject };
            return newConvObject;
          }
          return obj;
        });

        delete newMessages.newUserConvoId;
        dispatch(actions.setConversations(replacedConvs));
        dispatch(actions.setMessages(newMessages));
        dispatch(actions.setActiveChats(newConvo));
      }

      dispatch(actions.setConversations(convs));
      dispatch(actions.setMessages(newMessages));
    } else {
      const data = messages[messageConversationId].map((obj) => {
        if (obj.PK === payload.PK) {
          return { ...payload };
        }
        return obj;
      });

      let orderedMessages = data.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });

      const newMessages = Object.assign({}, messages, {
        [msg.conversationId]: orderedMessages,
      });

      const convs = conversations.map((obj) => {
        if (
          obj.conversationId === messageConversationId &&
          orderedMessages.length > 0
        ) {
          const timeline =
            orderedMessages[orderedMessages.length - 1].timestamp;
          return {
            ...obj,
            timestamp: timeline,
            lastMessageTime: timeline,
            newMessage: msg.SK === "QUO" ? true : false,
          };
        }
        return obj;
      });

      if (oldConversation && !oldConversation.conversationId) {
        dispatch(actions.setConversations(convs));
      }
      dispatch(actions.setMessages(newMessages));
    }
  };
};

export const readMessageHandler = (conversationId, conversations) => {
  return (dispatch) => {
    const convs = conversations.map((obj) => {
      if (obj.conversationId === conversationId) {
        return { ...obj, newMessage: false };
      }
      return obj;
    });
    dispatch(actions.setConversations(convs));
  };
};

export const deleteMessageHandler = (msg) => {
  return (dispatch) => {
    const appStore = store.getState();
    const messages = appStore.chat.messages;

    const data = messages[msg.conversationId].filter(
      (obj) => obj.PK !== msg.PK
    );
    let orderedMessages = data.sort(function (a, b) {
      return a.timestamp - b.timestamp;
    });
    const newMessages = Object.assign({}, messages, {
      [msg.conversationId]: orderedMessages,
    });

    dispatch(actions.setMessages(newMessages));
  };
};

const composeLastMessage = (msg) => {
  const payload = {
    lastMessageType: msg.SK,
    lastMessageTime: msg.deliveryTime,
    timestamp: msg.deliveryTime,
    lastMessageSenderName: msg.senderName,
    lastMessageSenderType: msg.senderType,
    conversationId: msg.conversationId,
    oldPK: msg.oldPK,
  };

  if (msg.deliver && msg.SK === "MSG") {
    payload.lastMessage = msg.message;
  } else if (msg.deliver && msg.SK === "QUO") {
    payload.lastMessage = "Shared Quotation";
  } else if (msg.deliver && msg.SK === "FIL") {
    payload.lastMessage = msg.filename;
  }

  return payload;
};

export const setLastMessage = ({ msg, isAdminChat = false }) => {
  return (dispatch) => {
    const lastMessageDetails = composeLastMessage(msg);

    if (isAdminChat) {
      return dispatch(updateAdminLastMessage(lastMessageDetails));
    }
    dispatch(updateLastMessage(lastMessageDetails));
  };
};

//check for bypass in messages

export const checkForBypass = async (text) => {
  const checkUrl = `https://filter.terawork.com/check-message`;

  const response = await fetch(checkUrl, {
    method: "POST",
    body: JSON.stringify({
      text,
    }),
  });
  const isTextPure = await response.json();

  return isTextPure ? true : false;
};

export const sendWorkstoreMessage = async (message, success) => {
  const { chat } = store.getState();
  const { conversationId } = chat.activeChat;

  const payload = { ...message, conversationId };
  await chat.socket.emit(payload.action, payload);
  success();
};
