import React from "react";
import PropTypes from "prop-types";

import { DesktopOnly } from "../../../styling/breakpoints";
import { HStack } from "../../library/layout/Stack";
import { DesktopSearchInput } from "./style";
import Button from "../../library/Button";

function DesktopSearch({ text, onChange, ...props }) {
  return (
    <DesktopOnly>
      <HStack>
        <DesktopSearchInput
          value={text}
          onChange={onChange}
          placeholder="Try “writing blog article”"
          maxLength={40}
          {...props}
        />
        <Button type="submit" size="lg">
          Search
        </Button>
      </HStack>
    </DesktopOnly>
  );
}

DesktopSearch.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DesktopSearch;
