export const colors = {
  borderColor: '#CED2D9',
  emphasisBg: '#FAFAFA',
  brand: {
    dark: '#4B7466',
    growEvent: '#131313',
  },
  growEvent: {
    500: '#131313',
    600: 'rgba(0, 0, 0, 0.36)',
  },
  text: {
    brand: '#37A000',
    label: '#BDBDBD',
    gray: '#777777',
    body: '#18191f',
    secondary: '#898787',
    warning: '#FF9811',
    offWhite: '#CCCACA',
  },
  primary: {
    200: '#83BC2320',
    500: '#83BC23',
    600: '#37A000',
  },
  secondary: {
    200: 'rgba(33, 192, 252, 0.2)',
    500: '#21C0FC',
    600: '#1CAAE0',
  },
  dark: {
    900: '#222',
  },
  star: {
    500: '#ffbe5b',
  },
  bg: {
    purple: '#563BDB',
    emphasis: '#F5F5F5',
    black: '#111318',
  },
};
