import { Box, Flex, Heading, Image, Link, VStack } from "@chakra-ui/react";
import React from "react";
import AppStoreIcon from "../../../../assets/img/brands/appstore.svg";
import PlayStoreIcon from "../../../../assets/img/brands/playstore.svg";

export function InstallApp({ showTitle = true, ...props }) {
  return (
    <VStack spacing="8" alignItems="flex-start" px="2" w="full">
      {showTitle && (
        <Heading as="h4" size="md">
          Install App
        </Heading>
      )}

      <Flex
        w="full"
        flexDir={{ base: "column", sm: "row", lg: "column" }}
        gridGap="2"
        {...props}
      >
        <Box>
          <Link
            href="https://apps.apple.com/us/app/terawork-hire-top-freelancers/id1576454269"
            isExternal
          >
            <Image src={AppStoreIcon} alt="appstore" />
          </Link>
        </Box>
        <Box>
          <Link
            href="https://play.google.com/store/apps/details?id=com.terawork.mobile&gl=PL"
            isExternal
          >
            <Image src={PlayStoreIcon} alt="playstore" />
          </Link>
        </Box>
      </Flex>
    </VStack>
  );
}
