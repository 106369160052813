import React from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import Footer from "./new/Footer";
import Header from "./new/Header";

export const FullScreenLayout = ({ children }) => {
  const { search } = useLocation();
  const { agent } = parse(search);
  return (
    <>
        {agent !== "mobile" && <Header />}
        {children}
        {agent !== "mobile" && <Footer />}
    </>
  );
};
