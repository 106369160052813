import { API_SUCCESS } from '../../../../constants/constants';
import { requestApi } from '../../../../utils/api';
import * as actions from '../../store/actions';
import { stringifyQuery } from '../../../../utils/UrlParameter';
import localStore, {
  STORE_NAME,
  STORE_PREFIX,
} from '../../../../utils/localStore';

const promoStore = localStore.createInstance({
  storeName: `${STORE_PREFIX}-promo`,
  name: STORE_NAME,
});

const PromoKeyPrefix = 'promo_';

export function getOngoingPromos() {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', '/promos');
    type === API_SUCCESS && dispatch(actions.setOngoingPromos(data.promos));
  };
}

export function getPendingPromos() {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', '/promos/pending');
    type === API_SUCCESS && dispatch(actions.setPendingPromos(data.promos));
  };
}

export function getAttachablePromos() {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', '/promos/attachable');
    type === API_SUCCESS && dispatch(actions.setAttachabalePromos(data.promos));
  };
}

export function getPromo({ id, ...params }) {
  return async (dispatch) => {
    const searchQuery = stringifyQuery(params);
    const { data, type } = await requestApi(
      'get',
      `/promos/${id}?${searchQuery}`
    );
    type === API_SUCCESS && dispatch(actions.getPromo(data));
  };
}

export function getPromoProfile({ id, urlName }) {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      `/sellers/${urlName}/promo/${id}`
    );

    if (type === API_SUCCESS && data.seller_service) {
      dispatch(actions.setPromoProfile(data.seller_service));
      return true;
    }
  };
}

export const setPromoBanner = (promo) => {
  return async (dispatch) => {
    //check if selected promo has been previously close in this browser
    const promoHasBeenClosedByUser = await promoStore.getItem(
      `${PromoKeyPrefix}${promo?.id}`
    );

    !promoHasBeenClosedByUser &&
      dispatch(actions.setPromoBanner({ show: true, promo }));
  };
};

export const closePromoBanner = (promo) => {
  return async (dispatch) => {
    //add promo to List of closed promos on storage;
    // promoStore.setItem(`${PromoKeyPrefix}${promo?.id}`, true);

    dispatch(actions.setPromoBanner({ show: false, promo: null }));
  };
};
