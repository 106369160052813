import React, { useMemo } from 'react';
import { ElementColor } from '../../../constants/Colors';
import { Small } from '../../../styling/Text';
import { VStack } from '../layout/Stack';
import { InputLabel, InputStyle, InputWapper } from './style';
import { getAllowedInputType } from './utils';

const Input = React.forwardRef(
  (
    {
      type = 'text',
      label = '',
      value = '',
      onChange = () => {},
      isInline = false,
      isFullWidth = true,
      className = '',
      isRequired = false,
      isOptional = false,
      size = 'md',
      error = '',
      subLabel = '',
      ...rest
    },
    ref
  ) => {
    const inputType = useMemo(() => getAllowedInputType(type), [type]);

    return (
      <VStack>
        <InputWapper
          alignItems="flex-start"
          direction={isInline ? 'row' : 'column'}
        >
          {label && (
            <VStack>
              <InputLabel
                $required={isRequired}
                $optional={isOptional}
                htmlFor={label}
              >
                {label}
              </InputLabel>

              {subLabel && <Small>{subLabel}</Small>}
            </VStack>
          )}

          <InputStyle
            value={value}
            type={inputType}
            onChange={onChange}
            required={isRequired}
            $size={size}
            $hasError={!!error}
            $isFullWidth={isFullWidth}
            className={className}
            ref={ref}
            {...rest}
          />
        </InputWapper>
        <Small color={ElementColor.danger}>{error}</Small>
      </VStack>
    );
  }
);

export default Input;
