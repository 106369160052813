import { API_SUCCESS } from '../../../constants/constants';
import { requestApi } from '../../../utils/api';
import Notify from '../../../utils/notify';
import Transformer from '../../../utils/transformer';

export const postTalkToUs = async ({ body }) => {
  const { type, data } = await requestApi('post', '/tickets/talk-to-us', body);
  if (type === API_SUCCESS) {
    Notify.success("Thanks for contacting us. We'll be with you shortly");
    return [null];
  }
  const error = Transformer.resetValidationFields({ errors: data });
  return [error];
};
