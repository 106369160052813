import * as actionTypes from './actionsTypes';

export const setPendingOrder = (payload) => {
  return {
    type: actionTypes.SET_PENDING_ORDER,
    payload,
  };
};

export const setBuyerData = (payload) => {
  return {
    type: actionTypes.SET_BUYER_DATA,
    payload,
  };
};

export const savedProfile = (payload) => {
  return {
    type: actionTypes.SAVED_PROFILE,
    payload,
  };
};

export const recentlyVisited = (payload) => {
  return {
    type: actionTypes.RECENTLY_VISITED,
    payload,
  };
};

export const setRecent = (payload) => {
  return {
    type: actionTypes.RECENT,
    payload,
  };
};

export const setBuyerOngoingWorks = (payload) => {
  return {
    type: actionTypes.ONGOING_WORK,
    payload,
  };
};

export const setBuyerWorkHistory = (payload) => {
  return {
    type: actionTypes.WORK_HISTORY,
    payload,
  };
};

export const setJobPosts = (payload) => {
  return {
    type: actionTypes.SET_JOBPOSTS,
    payload,
  };
};

export const setPost = (payload) => {
  return {
    type: actionTypes.SET_POST,
    payload,
  };
};

export const setNotifications = (payload) => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    payload,
  };
};

export const setTransactions = (payload) => {
  return {
    type: actionTypes.SET_TRANSACTIONS,
    payload,
  };
};

export const setWallet = (payload) => {
  return {
    type: actionTypes.SET_WALLET,
    payload,
  };
};

export const setUserCurrency = (payload) => {
  return {
    type: actionTypes.SET_CURRENCY,
    payload,
  };
};

export const setUserCurrencyRates = (payload) => {
  return {
    type: actionTypes.SET_CURRENCY_RATES,
    payload,
  };
};

export const setReviews = (payload) => {
  return {
    type: actionTypes.SET_REVIEWS,
    payload,
  };
};
