import { requestApi } from '../../utils/api';
import { extractMicroProfile } from '../../utils/transfomer';
import { API_SUCCESS } from '../../constants/constants';
import { setApplication } from '../auth/store';
import * as actions from './store/actions';
import { extractProfiles } from '../web/pages/Mall/redux/actions';
import Transformer from '../../utils/transformer';
import { setPrivateCurrency } from '../../routes/service';
import { setSentryUser } from '../../utils/sentry';

export function setPendingOrderReq(val) {
  return (dispatch) => dispatch(actions.setPendingOrder(val));
}

export function setBuyerDataReq(val) {
  return (dispatch) => dispatch(actions.setBuyerData(val));
}

export function getBuyer() {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', '/profile', null, true);

    if (type === API_SUCCESS && data.user) {
      setSentryUser({
        username: data.user?.username,
        email: data.user?.email,
      });
      dispatch(actions.setBuyerData(data.user));
      dispatch(setPrivateCurrency(data.user?.wallet?.currency_id));
      return data.user;
    }
  };
}

export function getPendingOrders() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      '/buyer/cart/pending-orders',
      false,
      true
    );

    if (type === API_SUCCESS && data) {
      const reversedOrders = data.pending_orders.reverse();
      dispatch(setPendingOrderReq(reversedOrders));
      return reversedOrders;
    }
  };
}

export function handleSavedProfile() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      'buyer/saved-profiles',
      null,
      true
    );

    if (type === API_SUCCESS) {
      const transformedResult = Transformer.fetch(data.profiles);

      dispatch(actions.savedProfile(transformedResult));
      dispatch(extractProfiles(transformedResult.hits));
    }
  };
}

export function handleRecentlyVisited() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      'buyer/recently-visited',
      null,
      true
    );

    if (type === API_SUCCESS) {
      const extractedRecent = data.profiles.hits.map(({ _source: source }) =>
        extractMicroProfile(source)
      );

      dispatch(actions.recentlyVisited(extractedRecent));
      dispatch(actions.setRecent(data));
      return;
    }
  };
}

export const getRecentlyVisitedProfiles = () => {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', 'buyer/recently-visited');

    if (type === API_SUCCESS) {
      const transformedResult = Transformer.fetch(data.profiles);

      dispatch(actions.recentlyVisited(transformedResult));
    }
  };
};

export function switchToBuyerAppHandler() {
  return (dispatch) => {
    dispatch(setApplication('buyer'));
  };
}

export function getOngoingWork() {
  return async (dispatch) => {
    const { data, type: status } = await requestApi(
      'get',
      `buyer/ongoing-works`,
      false,
      true
    );

    if (status === API_SUCCESS) {
      const ongoingWorks = data.ongoing_works.reverse();

      dispatch(actions.setBuyerOngoingWorks(ongoingWorks));
      return;
    }

    dispatch(actions.setBuyerOngoingWorks([]));
  };
}

export function getWorkHistory({ page = 1 }) {
  return async (dispatch) => {
    const { data, type: status } = await requestApi(
      'get',
      `buyer/work-histories?page=${page}`,
      false,
      true
    );

    if (status === API_SUCCESS) {
      dispatch(actions.setBuyerWorkHistory(data.work_histories));
      return;
    }

    dispatch(actions.setBuyerWorkHistory({}));
  };
}

export function getNotifications() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      'buyer/notifications',
      false
    );

    if (type === API_SUCCESS && data) {
      dispatch(actions.setNotifications(data));
    }
  };
}

export function getUserConversionRates(id) {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      `/currencies/net-conversions/get-rates/${id}`
    );

    if (type === API_SUCCESS && data) {
      dispatch(actions.setUserCurrencyRates(data.net_conversion_rates));
    }
  };
}
