import styled from 'styled-components';
import breakpoints from '../../../constants/breakpoints';
import { ElementColor } from '../../../constants/Colors';
import { PlainButton } from '../../library/Button/style';

export const TalkToUsContainer = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 15px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    z-index: 10;
    bottom: 20px;
  }
`;

export const TalkButton = styled(PlainButton)`
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background: ${ElementColor.dark};
  color: white;
  border: 1px solid ${ElementColor.dark};
  font-size: 1.5rem;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-left: auto;
    height: 50px;
    width: 50px;
  }
`;

export const TalkFormContainer = styled.div`
  width: 100vw;
  height: auto;
  padding: 1rem;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.sm}px) {
    position: relative;
    width: 350px;
    height: auto;
    box-shadow: 3px 4px 12px #00000020;
    margin-bottom: 1rem;
    bottom: unset;
    top: 0;
    padding: 0;
  }
`;

export const CloseButton = styled(PlainButton)`
  margin: 0 0 1rem auto;
  color: ${ElementColor.white};
`;
export const FormTop = styled.div`
  background: ${ElementColor.dark};
  padding: 1rem 1rem 1.5rem;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 1.5rem 2rem 2rem;
  }
`;

export const FormBody = styled.form`
  padding: 1.5rem;
  background: white;
  box-shadow: 3px 4px 12px #00000020;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
