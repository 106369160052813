import React from "react";
import { JobCard, SellerCard } from "./cards";
import { ImageContainer, ImageStyle } from "./style";
import Graphics from "../../../../../../assets/img/Hero/graphics.webp";

function GraphicsImage() {
  return (
    <ImageContainer>
      <JobCard
        title="I need a Mobile App Developer"
        price="$100"
        time="2 days ago"
        jobKey="graphics"
        href="/category/service/mobile-app-development"
      />
      <ImageStyle $size="90%" src={Graphics} alt="graphics design" />
      <SellerCard
        avatar="https://d17meyd56oy0jr.cloudfront.net/seller-logo/330000626"
        title="Mobile Developer"
        name="Alofkeys solution"
        rating="5.00"
        reviewsCount="9"
        href="/category/service/computer-software-development"
      />
    </ImageContainer>
  );
}

export default GraphicsImage;
