export const API_URL = process.env.REACT_APP_BASE_URL;

export const PUBLIC_API_URL = 'https://api.terawork.com/';

export const compareStorageKey = 'x_comp_id';

export const reCaptchaSiteKey = '6Ldq0MwUAAAAADcYd8NpCVhcUdBBjdVowJ7aCaSZ';

export const API_SUCCESS = 'success';

export const API_FAIL = 'error';

export const SELLER_URL_PATH = 'services';

export const PAYMENT_KEYS = {
  paystack_test: 'pk_test_28ac7d160505754d2885c673b4cdfdbd485f283d',
  rave_test: 'FLWPUBK_TEST-87e8c8d1019de9c000b73cbf138165a3-X',
  paypal_test:
    'AYAI6C8ViZyX4gqWfeUrJVDP8liHvFbVf3WPBtxWcM4ZkcSI_VPg6C-UZMrasXjKHoDW7tWnRgULVNbm',
  paystack_live: 'pk_live_593ceb1e37bab56e82fd642dece51112a91cff41',
  rave_live: 'FLWPUBK-2b54df5cbcfa65221e71a7bbb0619c38-X',
  paypal_live:
    'AWt-zlhoGfV6oDs_upeHJ34FvhE17pvFcU6xkMClkfQiXdHSDPw_XwS5JHnXh2GXdpxpIz7Gt7Vee5L4',
  stripe_test:
    'pk_test_51HDvk2Dttw7LoH1W1emK6oIIpwOosIHHqQ1Z84atfG7zyeykAzZbrJJ71UpSSooQUhjnkMLxbePEMtnyHyW3Gtiv00v6s53Xi8',
  stripe_live:
    'pk_live_51HDvk2Dttw7LoH1WLmHznxw3bZxd7Vxq3pjta7evi7yFoPvDYhkvFOMv8RcxtmKSygBNcmKbei8OcAFnkSkXXLTJ00ec8sRxHV',
};

export const MINIMUM_PAYMENT = 1000;

const NAIRA_CURRENCY_ID = 1;
const DOLLAR_CURRENCY_ID = 2;
const EURO_CURRENCY_ID = 4;
const POUNDS_CURRENCY_ID = 5;

export const MAJOR_CURRENCIES_ID = [
  NAIRA_CURRENCY_ID,
  DOLLAR_CURRENCY_ID,
  EURO_CURRENCY_ID,
  POUNDS_CURRENCY_ID,
];

export const BUYER_APPLICATION = 'buyer';
export const SELLER_APPLICATION = 'seller';

export const TERA_LOGO_CDN = 'https://d17meyd56oy0jr.cloudfront.net';

export const VerificationStatus = {
  PENDING: 1,
  VERIFIED: 10,
  DISABLED: 0,
};

export const DISCOUNT_COOKIE_KEY = 'twk-discount';
export const BUYER_AFFILIATE_COOKIE_KEY = 'b_aff';
export const SELLER_AFFILIATE_COOKIE_KEY = 's_aff';
export const PREMIUM_COOKIE_KEY = 'isPremium';

export const MINIMUM_CHARACTERS = {
  onboarding_description: 250,
  onboarding_title: 20,
  seller_about: 150,
};

export const MAXIMUM_CHARACTERS = {
  onboarding_title: 70,
  onboarding_description: 1900,
  seller_about: 450,
};

export const CHAT_STATUS = {
  OPEN: 'Open',
  CLOSE: 'Close',
};

export const DEFAULT_PROFILE_IMAGE =
  'https://dl6lnggp44pnu.cloudfront.net/service-multimedia/default-admin';
