import React from 'react';
import { DesktopOnly } from '../../../../../styling/breakpoints';
import { Container } from '../../../../../styling/Layout';
import HeaderLeft from './left';
import HeaderRight from './right';
import { DesktopHeaderStyle } from './style';

function DesktopHeader(props) {
  return (
    <DesktopOnly>
      <Container>
        <DesktopHeaderStyle>
          <HeaderLeft {...props} />
          <HeaderRight {...props} />
        </DesktopHeaderStyle>
      </Container>
    </DesktopOnly>
  );
}

export default DesktopHeader;
