import { Image } from '@chakra-ui/image';
import {
  Circle,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Square,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { Link } from 'react-router-dom';
import { appEnv } from '../../../../../utils/appEnv';
import { LandingSection } from './LandingSection';

const features = [
  {
    title: 'Hire Freelancers',
    description: 'Tech & non-tech pros for quick, on-demand tasks',
    url: '/freelance',
    isExternal: false,
    bgColor: '#EBF5FF',
    iconBgColor: '#4299E1',
    icon: '/icons/hire-freelancer.svg',
  },
  {
    title: 'Hire Full-time Talents',
    description: 'Long-term pros for remote, hybrid, in-person jobs',
    url: `${appEnv.enterpriseAppHost}full-time`,
    isExternal: true,
    bgColor: '#F1EBFF',
    iconBgColor: '#563BDB',
    icon: '/icons/full-time.svg',
  },
  {
    title: 'Outsource & Contracts',
    description: 'Short-term engagements, assemble teams to deliver a project',
    url: `${appEnv.enterpriseAppHost}outsourcing`,
    isExternal: true,
    bgColor: '#FFDDDD',
    iconBgColor: '#F78197',
    icon: '/icons/outsource.svg',
  },
];

export const FeatureCard = ({
  title,
  description,
  icon,
  url,
  bgColor,
  iconBgColor,
  isExternal,
}) => {
  return (
    <LinkBox rounded="2xl" px="6" py="10" bg={bgColor} minH="300px">
      <Flex flexDir="column" justify="space-between" h="full">
        <VStack align="start" spacing="5">
          <Square rounded="xl" size="50px" bg={iconBgColor}>
            <Image src={icon} alt={title} h="25px" w="25px" />
          </Square>
          <Heading fontSize={['2xl']} as="h3">
            {title}
          </Heading>
          <Text fontSize="xl">{description}</Text>
        </VStack>

        <LinkOverlay
          {...(isExternal
            ? { href: url, isExternal: true }
            : { as: Link, to: url })}
        />
        <Flex justify="space-between" align="center">
          <Text fontWeight={700} fontSize="lg">
            Learn more
          </Text>

          <Circle size="30px" bg="#D9D9D9">
            <Icon as={ArrowRightIcon} />
          </Circle>
        </Flex>
      </Flex>
    </LinkBox>
  );
};

export const TalentYourWay = () => {
  return (
    <LandingSection bg="#f9fafb" mt="8">
      <VStack align="stretch" w="full" spacing="4">
        <Heading size="2xl" maxW={['250px', 'full']}>
          Recruit Talents How You Want
        </Heading>

        <Text fontSize="2xl" maxW="350px">
          Explore recruitment options that resonate with your goals
        </Text>
      </VStack>

      <SimpleGrid mt="8" columns={[1, null, 3]} gap="6">
        {features.map((feature) => (
          <FeatureCard key={feature.title} {...feature} />
        ))}
      </SimpleGrid>
    </LandingSection>
  );
};
