import moment from 'moment';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { Button } from '@chakra-ui/button';
import Input from '../../library/Input';
import Textarea from '../../library/Textarea';
import { postTalkToUs } from './service';
import { FormBody, FormTop, TalkFormContainer } from './style';
import { Heading, Text, VStack } from '@chakra-ui/layout';

function TalkForm({ close }) {
  const [data, setData] = useState({
    name: '',
    email: '',
    phone_no: '',
    custom_request: '',
    start_date: moment().format(),
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const errors = {};

      if (!data.email) {
        errors.email = 'Email address is required';
        errors.hasError = true;
      }

      if (!data.custom_request) {
        errors.custom_request =
          'Please provide a detailed description of your request';
        errors.hasError = true;
      }

      if (errors.hasError) {
        return setErrors(errors);
      }

      setLoading(true);
      setErrors({});
      const [err] = await postTalkToUs({ body: data });
      setLoading(false);
      if (err) {
        return setErrors(err);
      }

      close();
      return;
    },
    [data, close]
  );

  const handleChange = useCallback(({ target }) => {
    const { name, value } = target;

    setData((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <TalkFormContainer>
      <FormTop>
        <VStack spacing={[2, null, 4]}>
          <Heading size="md">
            Find the perfect talent for your business today
          </Heading>

          <Text fontSize="sm">
            Tell us about your talent needs and we will reach out to you
            instantly
          </Text>
        </VStack>
      </FormTop>
      <FormBody onSubmit={handleSubmit}>
        <VStack spacing="1rem">
          <Input
            value={data.name}
            onChange={handleChange}
            placeholder="Name"
            name="name"
            error={errors.name}
          />

          <Input
            value={data.email}
            onChange={handleChange}
            placeholder="Email"
            name="email"
            error={errors.email}
          />
          <Input
            value={data.phone_no}
            onChange={handleChange}
            placeholder="Phone Number"
            name="phone_no"
            error={errors.phone_no}
          />
          <Textarea
            value={data.custom_request}
            name="custom_request"
            placeholder="Your Message"
            onChange={handleChange}
            error={errors.custom_request}
            size="md"
          />
          <Button
            w="full"
            type="submit"
            loadingText="Loading"
            isLoading={loading}
          >
            Submit
          </Button>
        </VStack>
      </FormBody>
    </TalkFormContainer>
  );
}

export default TalkForm;
