import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { API_SUCCESS } from '../../../../../constants/constants';
import { requestApi } from '../../../../../utils/api';
import HomeJobs from '../components/HomeJobs';
import { HomeSection } from '../components/HomeSection';

function HomeWorkStore() {
  const [jobs, setJobs] = useState(null);
  useEffect(() => {
    if (!jobs) {
      (async function () {
        const { data, type } = await requestApi(
          'get',
          '/workstores?stage=Open'
        );

        if (type === API_SUCCESS && data) {
          setJobs(data.workstores);
        }
      })();
    }
  }, [jobs]);

  return (
    <HomeSection>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        gap={{ base: '12', md: '6' }}
        alignItems="center"
      >
        <VStack spacing="4" align="stretch">
          <Heading size="xl">A Cooler Way to Hire Freelancer</Heading>
          <Text maxW="lg">
            With a single post, receive multiple bids from top-rated freelance
            professionals ready to complete your project
          </Text>
          <ButtonGroup size="lg" spacing="4" pt="4">
            <Button as={Link} size="lg" to="/workstore/create">
              Post a job
            </Button>
            <Button variant="link" as={Link} to="/workstore">
              See available jobs
            </Button>
          </ButtonGroup>
        </VStack>
        <Box minW="0" w="full">
          {jobs ? (
            <HomeJobs jobs={jobs.hits} />
          ) : (
            <Skeleton
              height="250px"
              width="100%"
              style={{ backgroundColor: '#ddd' }}
            />
          )}
        </Box>
      </SimpleGrid>
    </HomeSection>
  );
}

export default HomeWorkStore;
