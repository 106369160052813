import React from "react";
import styled from "styled-components";
import breakpoints from "../../constants/breakpoints";
import { PlainButton } from "../library/Button/style";

const BannerContainer = styled.div`
  /* background: #5624d0; */
  background: ${(props) => props.bg};
  padding: 1rem 1.5rem;
  width: 100%;
  position: relative;

  .banner-text {
    font-size: 1rem;
    line-height: 18px;
    width: calc(100% - 2rem);
  }

  @media screen and (min-width: ${breakpoints.md + 1}px) {
    text-align: center;
    width: 100%;
  }
`;

const CloseButton = styled(PlainButton)`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  font-size: 1.4rem;
`;

export const Banner = ({
  children,
  onClose,
  bg = "#eceb98",
  showCloseButton = true,
  ...rest
}) => {
  return (
    <BannerContainer bg={bg} {...rest}>
      <div className="banner-text">{children}</div>
      {showCloseButton && (
        <CloseButton onClick={() => onClose && onClose()}>&#215;</CloseButton>
      )}
    </BannerContainer>
  );
};
