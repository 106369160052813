import {
  API_SUCCESS,
  BUYER_AFFILIATE_COOKIE_KEY,
  SELLER_AFFILIATE_COOKIE_KEY,
  PREMIUM_COOKIE_KEY,
} from '../../constants/constants';
import { api, requestApi } from '../../utils/api';
import Auth from '../../utils/Auth';
import { cookies } from '../../utils/cookies';
import { getBuyer } from '../buyer/service';
import * as actions from './store';

export function setApplicationReq(val) {
  return (dispatch) => dispatch(actions.setApplication(val));
}

export function setAuthReq(val) {
  return (dispatch) => dispatch(actions.setAuth(val));
}

export function setSignUp(payload) {
  return (dispatch) => dispatch(actions.authSignup(payload));
}

export function setSignUpDetailsReq(payload) {
  return (dispatch) => dispatch(actions.setSignUp(payload));
}

export async function userSignUp({
  email,
  first_name,
  last_name,
  password,
  username,
  type,
  telephone_no,
}) {
  const signupUrl = type === 'Seller' ? '/seller/signup' : '/buyer-signup';

  const payload = {
    email,
    first_name,
    last_name,
    password,
    username,
    telephone_no,
    send_verification: 'backend', //Ensures that email verification is handled on the API instead of cognito
    channel: 'WEB', // Track where the user signed up from
  };

  //Get affiliate codes
  const b_affiliate_code = cookies.getItem(BUYER_AFFILIATE_COOKIE_KEY);
  const s_affiliate_code = cookies.getItem(SELLER_AFFILIATE_COOKIE_KEY);
  const isPremium = cookies.getItem(PREMIUM_COOKIE_KEY);

  if (b_affiliate_code) {
    payload.b_affiliate_code = b_affiliate_code;
  }

  if (s_affiliate_code) {
    payload.s_affiliate_code = s_affiliate_code;
  }

  if (isPremium) {
    payload.is_premium = true;
  }

  const { type: status, data } = await requestApi('post', signupUrl, payload);

  if (status === API_SUCCESS) {
    //Remove affiliate cookies
    cookies.removeItem(BUYER_AFFILIATE_COOKIE_KEY);
    cookies.removeItem(SELLER_AFFILIATE_COOKIE_KEY);
    cookies.removeItem(PREMIUM_COOKIE_KEY);
    return [null, data];
  } else {
    return [data];
  }
}

export function loginHandler() {
  return async (dispatch) => {
    const auth = new Auth();
    api.defaults.headers.common['Authorization'] = `Bearer ${auth.getUserToken(
      'idToken'
    )}`;
    dispatch(setAuthReq(true));
    dispatch(setApplicationReq('buyer'));
    await dispatch(getBuyer());
  };
}

export async function userConfirmation({ code, email, onSuccess, onError }) {
  const { type, data } = await requestApi('post', '/user-confirmation', {
    verification_code: code,
    email,
  });

  if (type === API_SUCCESS) {
    onSuccess && onSuccess();
    return [null, data];
  } else {
    onError && onError();
    return [data];
  }
}
export const resendConfirmationCode = async ({ email }) => {
  const { type, data } = await requestApi('post', '/resend-confirmation', {
    email,
  });

  if (type === API_SUCCESS) {
    return [null, data];
  } else {
    return [data];
  }
};
