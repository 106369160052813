import React, { useEffect } from "react";

const useScript = (url, widgetCode) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
    const zohoChatButton = document.querySelector(".zsiq_custommain");

    if (zohoChatButton) {
      zohoChatButton.style.display = "unset";
    }

    return () => {
      if (zohoChatButton) {
        zohoChatButton.style.display = "none";
      }
    };
  }, [url, widgetCode]);
};

export default function SalesIQ() {
  return (
    <React.Fragment>
      {useScript(
        "https://salesiq.zoho.com/widget",
        "451a905ddbcf00f0077bdae026c600497fc31776ad1f1073e1410694dfd4affc"
      )}
    </React.Fragment>
  );
}
