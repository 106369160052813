import React from "react";
import { JobCard, SellerCard } from "./cards";
import { ImageContainer, ImageStyle } from "./style";
import Book from "../../../../../../assets/img/Hero/book.webp";

function BookImage() {
  return (
    <ImageContainer>
      <JobCard
        title="I need a Ghostwriter"
        time="7 days ago"
        jobKey="book"
        href="/category/service/ghost-writing"
      />
      <ImageStyle $size="80%" src={Book} alt="website design" />
      <SellerCard
        avatar="https://d17meyd56oy0jr.cloudfront.net/seller-logo/330000551"
        title="Ghostwriter"
        name="Kristilolola"
        rating="4.9"
        reviewsCount="60"
        href="/category/service/web-content-creation"
      />
    </ImageContainer>
  );
}

export default BookImage;
