import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import breakpoints from "../constants/breakpoints";
import makeResponsiveComponent from "./responsive";
import styled from "styled-components";

const rules = [
  {
    constraint: "min",
    width: breakpoints.start,
    rules: `
      max-width: 520px;
      margin: 0 auto;
      padding: 0 1.5rem;
    `,
  },
  {
    constraint: { min: breakpoints.lg, max: breakpoints.xl },
    rules: `
      max-width: 100%;
      padding: 0 4rem;
    `,
  },
  {
    constraint: "min",
    width: breakpoints.xl + 1,
    rules: `
      max-width: 1300px;
    `,
  },
];

export const Container = makeResponsiveComponent(rules);

const PageStyle = styled.main`
  min-height: 90vh;
  width: 100%;
  position: relative;
  font: 400 16px "Inter", sans-serif;
  line-height: 26px;
  color: #18191f;
`;

export const PageMain = (props) => {
  const {
    title = "TERAWORK | Hire Top Quality Freelancers, Professionals and Agencies On-Demand.",
    description = "TERAWORK provides businesses and entrepreneurs on-demand access to hire competent and proven freelancers, independent professionals and agencies offering quality services on-demand to grow their businesses. Scale your workforce dynamically as business needs change.",
    children,
    className,
  } = props;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <PageStyle className={className}>{children}</PageStyle>
    </>
  );
};

PageMain.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
