import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { MainAppLayout } from "../components/layout/MainAppLayout";
import { VerifyModal } from "../components/VerifyModal/VerifyModal";
import useAuthentication from "../hooks/useAuthetication";
import Auth from "../utils/Auth";
import { redirectHome } from "../utils/redirect";

const PrivateRoutes = ({
	component: Component,
	role,
	application,
	seller,
	layout: Layout = MainAppLayout,
	...rest
}) => {
	const location = useLocation();
	const auth = new Auth();
	const { authenticated, buyer } = useAuthentication();

	function checkGroup() {
		let roles = role || "";
		if (roles.length < 1) {
			return true;
		}

		const userGroup = auth.getDataFromToken("cognito:groups");

		if (!userGroup) {
			window.location.reload();
			return;
		}
		return userGroup.includes(roles) || !!buyer.seller_id;
	}

	return authenticated ? (
		<Route
			{...rest}
			render={(props) =>
				checkGroup() ? (
					<>
						{buyer.status === 1 ? (
							<VerifyModal />
						) : (
							<Layout>
								<Component {...props} />
							</Layout>
						)}
					</>
				) : (
					redirectHome(props.history)
				)
			}
		/>
	) : (
		<Redirect
			to={{
				pathname: "/login",
				state: { from: location },
			}}
		/>
	);
};

export default PrivateRoutes;
