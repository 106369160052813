import { LIST_JOBS, ADD_JOB } from './actions';

const initialState = {
  jobs: null,
  jobList: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_JOBS:
      return { ...state, jobs: payload };
    case ADD_JOB:
      return {
        ...state,
        jobList: { ...state.jobList, [payload.employmentId]: payload },
      };
    default:
      return state;
  }
};

export default reducer;
