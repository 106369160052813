import * as actionTypes from './actionTypes';

import {
  CATEGORY,
  CATEGORIES,
  RANDOM_SELLERS,
  COMPARE_HEADER,
  COMPARE_BODY,
  COUNTRIES,
  BANKS,
  SET_DETAILS,
  SELLER_HOME,
  WORKSTORE,
  JOBPOST,
  SET_ONGOING_PROMOS,
  SET_PENDING_PROMOS,
  SET_PROMO,
  SET_PROMO_PROFILE,
  SET_EMPLOY_JOB,
  SET_PROMO_BANNER,
  SET_ATTACHABLE_PROMO,
  SET_TOP_RATED_SERVICES,
  JOBS_COMBO,
} from './actionTypes';

export const selectedCurrency = (currency) => {
  return {
    type: actionTypes.SELECTED_CURRENCY,
    currency,
  };
};

export const setCurrencyRates = (payload) => {
  return {
    type: actionTypes.SET_CURRENCY_RATES,
    payload,
  };
};

export const setCompare = (value) => {
  return {
    type: actionTypes.SET_COMPARE,
    value,
  };
};

export const setDependency = (payload) => {
  return {
    type: actionTypes.SET_DEPENDECY,
    payload,
  };
};

export const setCategory = (value) => {
  return {
    type: CATEGORY,
    value,
  };
};

export const setCategories = (value) => {
  return {
    type: CATEGORIES,
    value,
  };
};

export const setRandomSellers = (value) => {
  return {
    type: RANDOM_SELLERS,
    value,
  };
};

export const setCompareBody = (payload) => {
  return {
    type: COMPARE_BODY,
    payload,
  };
};

export const setCompareHeader = (payload) => {
  return {
    type: COMPARE_HEADER,
    payload,
  };
};

export const setCountries = (payload) => {
  return {
    type: COUNTRIES,
    payload,
  };
};

export const setBanks = (payload) => {
  return {
    type: BANKS,
    payload,
  };
};

export const setDetails = (payload, value) => {
  return {
    type: SET_DETAILS,
    payload,
    value,
  };
};

export const setSellerHome = (payload) => {
  return {
    type: SELLER_HOME,
    payload,
  };
};

export const setWorkstore = (payload) => {
  return {
    type: WORKSTORE,
    payload,
  };
};

export const setJobsCombo = (payload) => {
  return {
    type: JOBS_COMBO,
    payload,
  };
};

export const setJobPost = (payload) => {
  return {
    type: JOBPOST,
    payload,
  };
};

export const setPendingPromos = (payload) => {
  return {
    type: SET_PENDING_PROMOS,
    payload,
  };
};

export const setAttachabalePromos = (payload) => {
  return {
    type: SET_ATTACHABLE_PROMO,
    payload,
  };
};

export const setOngoingPromos = (payload) => {
  return {
    type: SET_ONGOING_PROMOS,
    payload,
  };
};

export const getPromo = (payload) => {
  return {
    type: SET_PROMO,
    payload,
  };
};

export const setPromoProfile = (payload) => {
  return {
    type: SET_PROMO_PROFILE,
    payload,
  };
};

export const setPromoBanner = (payload) => {
  return {
    type: SET_PROMO_BANNER,
    payload,
  };
};

export const setEmployJob = (payload) => {
  return {
    type: SET_EMPLOY_JOB,
    payload,
  };
};

export const setSellerGallery = (payload) => {
  return {
    type: actionTypes.SET_SELLER_GALLERY,
    payload,
  };
};

export const setTopServices = (payload) => {
  return {
    type: SET_TOP_RATED_SERVICES,
    payload,
  };
};
