export const SET_SELLERDATA = 'SET_SELLERDATA';
export const SET_WORKINFO = 'SET_WORKINFO';
export const SET_WORK_INFO_ADMIN = 'SET_WORK_INFO_ADMIN';
export const SET_WORK_PROFILE = 'SET_WORK_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_ONGOING_WORK = 'SET_ONGOING_WORK';
export const SET_WORK_HISTORY = 'SET_WORK_HISTORY';
export const ADD_PROMO_PROFILE = 'ADD_PROMO_PROFILE';
export const SET_WALLET = 'SET_WALLET';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_PROFILE_PREVIEW = 'SET_PROFILE_PREVIEW';
export const SET_QUALIFICATION = 'SET_QUALIFICATION';

export const SET_SERVICE_INFO_ADMIN = 'SET_SERVICE_INFO_ADMIN';
export const SET_JOB_TITLES = 'SET_JOB_TITLES';

export const ADD_SELLER_PROFILE = 'ADD_SELLER_PROFILE';
