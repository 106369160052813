import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../../constants/breakpoints';
import { ElementColor } from '../../../../constants/Colors';
import { Small } from '../../../../styling/Text';
import { HStack } from '../../../library/layout/Stack';
import DesktopFooter from './desktop';
import MobileFooter from './mobile';
import { Image } from '@chakra-ui/image';

const socials = [
  {
    title: 'Twitter',
    icon: (
      <Image
        src="/images/brands/x-logo.svg"
        alt="x.com logo"
        w="15px"
        h="15px"
      />
    ),
    href: 'https://www.x.com/teraworkng',
  },
  {
    title: 'Facebook',
    icon: <FontAwesomeIcon icon={faFacebookF} />,
    href: 'https://facebook.com/TeraworkNg',
  },
  {
    title: 'LinkedIn',
    icon: <FontAwesomeIcon icon={faLinkedinIn} />,
    href: 'https://www.linkedin.com/company/terawork-nigeria',
  },
  {
    title: 'Instagram',
    icon: <FontAwesomeIcon icon={faInstagram} />,
    href: 'https://www.instagram.com/teraworkng',
  },
  {
    title: 'Youtube',
    icon: <FontAwesomeIcon icon={faYoutube} />,
    href: 'https://www.youtube.com/channel/UCyzWTz_-1TS7KVsTVG1akUg',
  },
];

const FooterBottom = styled.div`
  padding: 3rem 2rem;
  border-top: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.lg}px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    padding: 2rem 4rem;
  }
`;

const SocialLink = styled.a`
  border-radius: 50%;
  background: #ffffff20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
`;

const FooterStyle = styled.footer`
  background: ${ElementColor.dark};
  color: white;
`;

const Socials = styled(HStack)`
  margin-top: 2rem;

  @media screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const footerLinks = [
  {
    title: 'Company',
    links: [
      {
        title: 'About Us',
        href: '/about',
      },
      {
        title: 'Privacy Policy',
        href: '/privacy-policy',
      },
      {
        title: 'Terms of Service',
        href: '/terms-of-service',
      },
      {
        title: 'Sitemap',
        href: '/sitemap',
      },
    ],
  },

  {
    title: 'Support',
    links: [
      {
        title: 'Guide',
        href: 'https://guide.terawork.com/',
        external: true,
      },
      {
        title: 'Help Center',
        href: 'https://help.terawork.com/',
        external: true,
      },
      {
        title: 'How it Works',
        href: '/how-it-works',
      },
      {
        title: 'FAQs',
        href: '/faq',
      },
      {
        title: 'Contact Us',
        href: '/contact',
      },
    ],
  },
  {
    title: 'Community',
    links: [
      {
        title: 'Blog',
        href: 'https://www.terawork.com/blog/',
        external: true,
      },
      {
        title: 'Press & News',
        href: 'https://www.terawork.com/blog/c/news-press/',
        external: true,
      },
      {
        title: 'Helpful Tips',
        href: 'https://www.terawork.com/blog/c/blog/',
        external: true,
      },
      {
        title: 'Customer Testimonies',
        href: 'https://www.terawork.com/blog/testimonials/',
        external: true,
      },
    ],
  },
];

function Footer() {
  return (
    <FooterStyle className="app-footer">
      <DesktopFooter />
      <MobileFooter />
      <FooterBottom>
        <Small color="inherit">
          © {new Date().getFullYear()} TERAWORK Inc. All rights reserved
        </Small>
        <Socials isFullWidth={false} spacing="1.5rem">
          {socials.map(({ href, icon, title }) => (
            <SocialLink
              href={href}
              rel="noopener noreferrer"
              target="_blank"
              key={title}
            >
              {icon}
            </SocialLink>
          ))}
        </Socials>
      </FooterBottom>
    </FooterStyle>
  );
}

export default Footer;
