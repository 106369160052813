import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginHandler } from '../../../../modules/auth/service';
import { GreenText } from '../../../../styling/Text';
import Auth from '../../../../utils/Auth';
import Button from '../../../library/Button';
import { PlainButton } from '../../../library/Button/style';
import Input from '../../../library/Input';
import { VStack } from '../../../library/layout/Stack';

const auth = new Auth();

function Form({ toggle, authDetails }) {
  const [data, setData] = useState({
    username: authDetails ? authDetails.email : '',
    password: '',
    loading: false,
  });
  const [errors, setErrors] = useState({ username: '', password: '' });

  const dispatch = useDispatch();

  const history = useHistory();

  const toggleLoading = () =>
    setData((prev) => ({ ...prev, loading: !prev.loading }));

  const handleChange = ({ target }) => {
    const { name, value } = target;

    if (value) {
      setData((prev) => ({ ...prev, [name]: value }));
      return;
    }

    setErrors((prev) => ({ ...prev, [name]: `${name} cannot be empty!` }));
  };

  const forgotPassword = () => {
    toggle();
    history.push('/reset-password');
  };

  const loginSuccess = async () => {
    toggleLoading();
    toggle();
    await dispatch(loginHandler());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleLoading();
    auth.Login(data.username, data.password, loginSuccess, toggleLoading);
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing="1rem">
        <Input
          value={data.username}
          label="Email or Userame"
          name="username"
          error={errors.username}
          onChange={handleChange}
        />
        <VStack>
          <Input
            type="password"
            value={data.password}
            label="Password"
            name="password"
            error={errors.password}
            onChange={handleChange}
          />
          <PlainButton type="button" onClick={forgotPassword}>
            <GreenText>Forgot Password?</GreenText>{' '}
          </PlainButton>
        </VStack>
        <Button isLoading={data.loading} isFullWidth type="submit" size="lg">
          Log In
        </Button>
      </VStack>
    </form>
  );
}

export default Form;
