import {
  ADD_SERVICE,
  ADD_FAQ_TO_SERVICE,
  ADD_SERVICE_PROFILE,
  EXTRACT_PROFILE,
  ADD_SEARCH_RESULT,
  SET_CATEGORIES,
  SET_SELLER_PROFILE,
  SET_PROFILE_COLLECTION,
  SET_FAQS,
} from "./action-types";

const initalState = {
  profiles: {},
  serviceProfiles: {},
  services: {},
  searchResult: {},
  randomSellers: {},
  categories: null,
  sellers: {},
  profileCollections: {},
  faqs: {},
};

const addService = (state, payload) => {
  return {
    ...state,
    services: { ...state.services, [payload.serviceString]: payload },
  };
};
const addFaqToService = (state, payload) => {
  if (payload.faq.length > 0) {
    return {
      ...state,
      services: {
        ...state.services,
        [payload.serviceString]: {
          ...state.services[payload.serviceString],
          faq: payload.faq,
        },
      },
    };
  }
  return state;
};

const addServiceProfiles = (state, payload) => {
  const previousProfiles =
    state.serviceProfiles[payload.serviceString]?.hits || [];

  const profiles = payload.add
    ? [...previousProfiles, ...payload.hits]
    : payload.hits;

  const data = { ...payload, hits: profiles };

  return {
    ...state,
    serviceProfiles: {
      ...state.serviceProfiles,
      [payload.serviceString]: data,
    },
  };
};

const addSearchResult = (state, payload) => {
  const previousSearch = state.searchResult.hits || [];

  const profiles = payload.add
    ? [...previousSearch, ...payload.hits]
    : payload.hits;

  const data = { ...payload, hits: profiles };

  return {
    ...state,
    searchResult: data,
  };
};

const extractProfiles = (state, payload = []) => {
  const profiles = {};

  payload.forEach(({ source }) => {
    profiles[source.profileId] = source;
  });

  return {
    ...state,
    profiles: { ...state.profiles, ...profiles },
  };
};

const setCategories = (state, payload) => {
  const validCategories = payload.filter((category) => category.id !== 0);

  return { ...state, categories: validCategories };
};

const setSellerProfile = (state, payload) => {
  return {
    ...state,
    profiles: { ...state.profiles, [payload.profileId]: payload },
  };
};

const setProfileCollection = (state, payload) => {
  return {
    ...state,
    profileCollections: {
      ...state.profileCollections,
      [payload.slug]: payload,
    },
  };
};

const setFaqs = (state, payload) => {
  return {
    ...state,
    faqs: { ...state.faqs, [payload.slug]: payload },
  };
};

function reducer(state = initalState, { type, payload }) {
  switch (type) {
    case ADD_SERVICE:
      return addService(state, payload);
    case ADD_FAQ_TO_SERVICE:
      return addFaqToService(state, payload);
    case ADD_SERVICE_PROFILE:
      return addServiceProfiles(state, payload);
    case EXTRACT_PROFILE:
      return extractProfiles(state, payload);
    case ADD_SEARCH_RESULT:
      return addSearchResult(state, payload);
    case SET_CATEGORIES:
      return setCategories(state, payload);
    case SET_SELLER_PROFILE:
      return setSellerProfile(state, payload);
    case SET_PROFILE_COLLECTION:
      return setProfileCollection(state, payload);
    case SET_FAQS:
      return setFaqs(state, payload);
    default:
      return state;
  }
}

export default reducer;
