import { Box } from '@chakra-ui/react';

export const Pill = ({ children, theme }) => {
  return (
    <Box
      p="2"
      px="4"
      bg={`${theme}.200`}
      color={`${theme}.600`}
      fontWeight={560}
      w="max"
      rounded="md"
    >
      {children}
    </Box>
  );
};
