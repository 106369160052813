import { useCallback, useEffect } from 'react';

const ESC_KEYCODE = 27;

function useOverlayUtility(isOpen, toggleModal) {
  const closeOnEsc = useCallback(
    (e) => {
      if (e.keyCode === ESC_KEYCODE) {
        toggleModal();
      }
    },
    [toggleModal]
  );

  useEffect(() => {
    if (isOpen) {
      //Prevent body scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';

      //Add listener for "Esc" Key
      document.addEventListener('keydown', closeOnEsc);
    }

    return () => {
      //Return body scrolling
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0';

      //Remove listener
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [isOpen, closeOnEsc]);

  return null;
}

export default useOverlayUtility;
