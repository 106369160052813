import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

import {
  isPasswordValid,
  isEmailValid,
  checkExisting,
  isTextValid,
} from '../../../utils/validator';
import PhoneInput from '../../common/InputField/PhoneInput';

class MyFormGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      error: '',
      errorMsg: '',
      showError: false,
    };

    switch (props.id) {
      case 'email':
        this.validator = this.emailValidator;
        break;
      case 'password':
        this.validator = this.passwordValidator;
        break;
      case 'username':
        this.validator = this.usernameValidator;
        break;
      case 'first_name':
      case 'last_name':
        this.validator = this.nameValidator;
        break;
      case 'telephone_no':
        this.validator = this.phoneValidator;
        break;
      default:
        this.validator = this.textValidator;
    }
  }

  validator = () => {};

  handleChange = (e, fieldName, fieldValue) => {
    // const { value, name } = e.target;
    const name = fieldName ? fieldName : e.target.name;
    const value = fieldValue || fieldValue === '' ? fieldValue : e.target.value;
    this.setState(
      {
        value: value,
      },
      () => this.validator(value)
    );

    this.props.handleChange(name, value);
  };

  handleBlur = (e, fieldValue) => {
    const value = fieldValue || fieldValue === '' ? fieldValue : e.target.value;

    this.setState({ showError: true });
    this.validator(value);
  };

  validationAction = (result, errorMsg, optional) => {
    const { id, setEntry, removeEntry } = this.props;

    this.setState(
      {
        error: result,
        errorMsg,
      },
      () => {
        if (result === false) {
          !optional && setEntry && setEntry(id);
        } else {
          !optional && removeEntry && removeEntry(id);
        }
      }
    );
  };

  emailValidator = async (value) => {
    let errorMessage;
    const emailValid = isEmailValid(value);

    if (emailValid && this.props.login) {
      return this.validationAction(false, '');
    }

    if (!emailValid) {
      errorMessage = 'Please enter a valid email address!';
      this.validationAction(true, errorMessage);
    } else {
      const exist = await checkExisting('email', value);
      if (exist) {
        this.validationAction(true, 'Email already exist');
      } else {
        this.validationAction(false, '');
      }
    }
  };

  passwordValidator = (value) => {
    const { valid, msg } = isPasswordValid(value);
    this.validationAction(!valid, msg || '');
  };

  nameValidator = (value) => {
    if (!isTextValid(value)) {
      return this.validationAction(true, 'Required field');
    }

    if (value.match(/[`£!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/)) {
      return this.validationAction(
        true,
        'Name cannot contain special character'
      );
    }

    return this.validationAction(false, '');
  };

  usernameValidator = async (value = '') => {
    if (isTextValid(value)) {
      if (this.props.login) {
        return this.validationAction(false, '');
      }

      if (value.match(/[` £!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/)) {
        return this.validationAction(
          true,
          'Display Name cannot contain space or special characters'
        );
      }

      const exist = await checkExisting('username', value);
      if (exist) {
        this.validationAction(true, 'Display Name already exist');
      } else {
        this.validationAction(false, '');
      }
    } else {
      this.validationAction(true, 'Display Name cannot be empty!');
    }
  };

  textValidator = (value) => {
    const isValid = isTextValid(value);
    this.validationAction(
      !isValid,
      isValid ? '' : `${this.props.label} cannot be empty!`
    );
  };

  phoneValidator = (value) => {
    const isValid = true;
    this.validationAction(
      !isValid,
      isValid ? '' : `${this.props.label} cannot be empty!`,
      true
    );
  };

  render() {
    const {
      id,
      type,
      inputClassName,
      leftIcon: LeftIcon,
      placeholder,
      phoneInput,
      ...props
    } = this.props;
    const { error, errorMsg, value, showError } = this.state;

    return (
      <div className="form-group" style={{ position: 'relative' }}>
        {LeftIcon && !phoneInput && (
          <Box
            className="leftIcon"
            pos="absolute"
            left="12px"
            bottom={`${error ? '32px' : '16px'}`}
            color="gray.400"
          >
            <LeftIcon size="20px" />
          </Box>
        )}
        {value !== '' && (
          <Text
            fontSize="11px"
            color="gray.400"
            pos="absolute"
            top="7px"
            left="43px"
            transition="5s ease-in"
          >
            {placeholder}
          </Text>
        )}
        {/* <label htmlFor={id} className="label">
					{" "}
					{label}{" "}
				</label> */}
        {phoneInput ? (
          <PhoneInput
            country={'ng'}
            value={value}
            onChange={(value) => this.handleChange(null, 'telephone_no', value)}
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              height: '61px',
              border: '1.48px solid #8692A6',
              borderRadius: '6px',
            }}
            inputStyle={{ width: '100%', height: '100%', border: 'none' }}
            buttonStyle={{
              border: 'none',
              borderRight: '1.48px solid #8692A6',
            }}
          />
        ) : (
          <input
            type={type}
            className={`${inputClassName} ${error ? 'red' : ''}`}
            id={id}
            name={id}
            value={value}
            onChange={this.handleChange}
            placeholder={placeholder}
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              padding: '20px',
              paddingTop: '33px',
              paddingBottom: '25px',
              paddingLeft: `${LeftIcon ? '40px' : ''}`,
              border: '1.48px solid #8692A6',
              outline: '1.48px solid #8692A6',
              borderRadius: '6px',
            }}
            onBlur={this.handleBlur}
            {...props}
          />
        )}
        {showError && <p className="error-msg"> {errorMsg} </p>}
      </div>
    );
  }
}

MyFormGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inputClassName: PropTypes.string,
  setEntry: PropTypes.func,
  userCheck: PropTypes.func,
  leftIcon: PropTypes.any,
  login: PropTypes.bool,
};

export default MyFormGroup;
