import React from "react";
import { JobCard, SellerCard } from "./cards";
import { ImageContainer, ImageStyle } from "./style";
import Graphics from "../../../../../../assets/img/Hero/branding.webp";

function BrandingImage() {
  return (
    <ImageContainer>
      <JobCard
        title="I need corporate branding"
        time="a week ago"
        jobKey="brand"
        href="/category/service/corporate-branding-and-logo-design"
      />
      <ImageStyle $size="90%" src={Graphics} alt="corporate branding" />
      <SellerCard
        avatar="https://www.terawork.com/images/Whyconcept.jpg"
        title="Corporate Branding Expert"
        name="WhyConcept"
        rating="4.86"
        reviewsCount="69"
        href="/category/service/graphic-design"
      />
    </ImageContainer>
  );
}

export default BrandingImage;
