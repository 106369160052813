import { groupArr } from './transfomer';

const { default: store } = require('../store');

class Dependencies {
  static getCurrency = (id) => {
    const { web } = store.getState();

    const { currencies } = web.dependencies;
    return id ? currencies.find((curr) => +curr.id === +id) : currencies;
  };

  static getCountry = (id) => {
    const { web } = store.getState();

    const { countries } = web.dependencies;
    return id ? countries.find((country) => +country.id === +id) : countries;
  };

  static getNetConversion = (buyingCurrency = 1, sellingCurrency = 1) => {
    const { web } = store.getState();
    const { othersToCurrency } = web.netConversions;

    return othersToCurrency[buyingCurrency][sellingCurrency]['net_rate'];
  };

  static getMatrix = (currencyShortName = '') => {
    const { web } = store.getState();
    const { seller_commission_matrices: matrix } = web.dependencies;

    const groupedMatrix = groupArr(matrix, 'currency');

    return groupedMatrix[currencyShortName];
  };

  static getCommission = (matrix = [], amount) => {
    //If price is more than highest matrix amount, return lowest commission;
    const highestMatrice = matrix.reduce((prev, curr) =>
      parseInt(prev.upper_bound || 0, 10) < parseInt(curr.upper_bound || 0, 10)
        ? curr
        : prev
    );

    if (amount >= parseInt(highestMatrice.upper_bound, 10)) {
      return highestMatrice.commission_percentage;
    }

    //find the matrice amount falls into;
    const value = matrix.find((mat) => +amount < +mat.upper_bound);
    return value.commission_percentage;
  };

  static deductCommission = (amount, commission = 1) => {
    const reciprocalCommission = 100 - +commission;

    return (amount * reciprocalCommission) / 100;
  };
}

export default Dependencies;
