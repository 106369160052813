import React from 'react';
import { ElementColor } from '../../../constants/Colors';
import { Small } from '../../../styling/Text';
import { VStack } from '../layout/Stack';
import { TextareaLabel, TextAreaStyle } from './style';

function Textarea({
  value,
  onChange,
  label,
  className = '',
  isRequired = false,
  isOptional = false,
  size = 'md',
  error = '',
  ...rest
}) {
  return (
    <VStack>
      <VStack className={className}>
        {label && (
          <TextareaLabel $optional={isOptional} $required={isRequired}>
            {label}
          </TextareaLabel>
        )}
        <TextAreaStyle
          value={value}
          onChange={onChange}
          $hasError={!!error}
          $size={size}
          {...rest}
        />
      </VStack>
      <Small color={ElementColor.danger}>{error}</Small>
    </VStack>
  );
}

export default Textarea;
