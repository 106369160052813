import Model from "../../../utils/Model";

class Buyer extends Model {
  constructor(props) {
    super(props);
    this.initialize(props);
  }
}

export default Buyer;
