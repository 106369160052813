import React, { useState } from 'react';
import { PlainButton } from '../../../../library/Button/style';
import SearchModal from './search';

export const MagnifyingGlass = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6953 2.86048C12.8814 -0.953493 6.67383 -0.953493 2.85986 2.86048C-0.953286 6.67528 -0.953286 12.882 2.85986 16.6968C6.2563 20.0924 11.5461 20.4562 15.3576 17.8047C15.4378 18.1842 15.6213 18.5463 15.9165 18.8415L21.4709 24.3958C22.2803 25.2036 23.5883 25.2036 24.3936 24.3958C25.2022 23.5872 25.2022 22.2792 24.3936 21.4731L18.8392 15.9171C18.5457 15.6244 18.1827 15.4401 17.8032 15.3599C20.4564 11.5475 20.0926 6.25858 16.6953 2.86048ZM14.9417 14.9432C12.0943 17.7906 7.46011 17.7906 4.61348 14.9432C1.76767 12.0957 1.76767 7.46238 4.61348 4.61492C7.46011 1.76829 12.0943 1.76829 14.9417 4.61492C17.7892 7.46238 17.7892 12.0957 14.9417 14.9432Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  const [showSearch, setShow] = useState(false);

  const toggleSearch = () => {
    setShow((prev) => !prev);
  };
  return (
    <>
      <PlainButton onClick={toggleSearch}>
        <MagnifyingGlass />
      </PlainButton>
      <SearchModal show={showSearch} toggleSearch={toggleSearch} />
    </>
  );
};
