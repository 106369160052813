import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { ElementColor, TextColor } from '../../../constants/Colors';
import useClickOutside from '../../../hooks/useClickOutside';
import { PlainButton } from '../../library/Button/style';

const SuggestStyle = styled.div`
  background: #fff;
  box-shadow: 0px 15px 22px rgba(137, 135, 135, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  white-space: nowrap;
  position: absolute;
  width: 300px;
  ${PlainButton} {
    border-bottom: 1px solid ${ElementColor.lineLight};
    justify-content: flex-start;
    padding: 1rem 1.5rem;
    opacity: 0.85;
    font-size: 1rem;
    transition: 0.3s ease all;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      color: ${TextColor.green};
    }
  }
`;

export const getSuggestIcon = (type) => {
  switch (type) {
    case 'user':
      return faUser;
    case 'key':
      return faSearch;
    default:
      return faSearch;
  }
};

function Suggestions({ suggestions, show, toggle, onClick, className }) {
  const clickRef = useRef(null);
  useClickOutside(clickRef, toggle, show);

  return show ? (
    <SuggestStyle className={className} ref={clickRef}>
      {suggestions.map((sugg) => (
        <PlainButton
          key={shortid.generate()}
          $isFullWidth
          onClick={() => onClick(sugg.text)}
        >
          <FontAwesomeIcon
            icon={getSuggestIcon(sugg.type)}
            color={ElementColor.line}
            style={{ marginRight: '1rem' }}
          />
          {sugg.text || sugg}
        </PlainButton>
      ))}
    </SuggestStyle>
  ) : null;
}

export default Suggestions;
