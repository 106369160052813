import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { lazy } from 'react';

const Saved = lazy(() => import('./pages/Saved/Saved'));

const PendingOrder = lazy(() => import('./pages/PendingOrder/PendingOrder'));
const BuyerWorkManagement = lazy(() =>
  import('./pages/WorkManagement/WorkManagement')
);
const OngoingWork = lazy(() => import('./pages/WorkManagement/OngoingJob'));
const WorkHistory = lazy(() => import('./pages/WorkManagement/WorkHistory'));

const PostWork = lazy(() => import('./pages/Postwork'));
const MyJobPost = lazy(() => import('./pages/MyJobPost'));
const JobPost = lazy(() => import('./pages/MyJobPost/post'));
const EditJobPost = lazy(() => import('./pages/MyJobPost/post/edit'));
const PersonalInfo = lazy(() => import('./pages/PersonalInfo/PersonalInfo'));
const Recent = lazy(() => import('./pages/Saved/Recent'));

const Chat = lazy(() => import('./pages/Chat/Chat'));
const Transactions = lazy(() => import('./pages/Transactions'));

const Reviews = lazy(() => import('./pages/Reviews'));

const EmployJobs = lazy(() => import('./pages/Employ/jobs'));
const EmployJob = lazy(() => import('./pages/Employ/jobs/job'));
const EditEmployJobs = lazy(() => import('./pages/Employ/jobs/edit'));
const JobApplicants = lazy(() => import('./pages/Employ/jobs/applicants'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

const Payment = lazy(() => import('./pages/Payment'));

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    auth: true,
    role: 'Buyers',
    layout: DefaultLayout,
  },
  {
    path: '/saved',
    component: Saved,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/recent',
    component: Recent,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/peer/chats',
    component: Chat,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/work',
    component: BuyerWorkManagement,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/work/ongoing',
    component: OngoingWork,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/work/history',
    component: WorkHistory,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/pending-orders',
    component: PendingOrder,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/workstore/create',
    component: PostWork,
    exact: true,
    auth: false,
    role: 'Buyers',
  },
  {
    path: '/job-posts',
    component: MyJobPost,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/job-posts/:id',
    component: JobPost,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/job-posts/edit/:id',
    component: EditJobPost,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/personal-info',
    component: PersonalInfo,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/transactions',
    component: Transactions,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/pwr',
    component: Reviews,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  // {
  //   path: '/employ/create',
  //   component: CreateEmploy,
  //   exact: true,
  //   auth: false,
  //   role: 'Buyers',
  // },
  {
    path: '/employ',
    component: EmployJobs,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/employ/edit/:id',
    component: EditEmployJobs,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/employ/applicants/:id',
    component: JobApplicants,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/employ/:id',
    component: EmployJob,
    exact: true,
    auth: true,
    role: 'Buyers',
  },
  {
    path: '/top-up',
    component: Payment,
    exact: true,
    auth: true,
    role: 'Buyers',
    layout: DefaultLayout,
  },
];

export default routes;
