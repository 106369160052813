import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/button";

export const SimpleAlertDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  buttonProps = {},
  title,
  description,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  const cancelRef = React.useRef();

  const confirmAction = async () => {
    onConfirm();
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant="ghost">
              {cancelText}
            </Button>
            <Button
              isLoading={isLoading}
              onClick={confirmAction}
              ml={3}
              {...buttonProps}
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
