import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrencyByIp } from '../../../utils/currency';
import SimpleSpinner from '../../common/Spinner/SimpleSpinner';
import { requestApi } from '../../../utils/api';
import { API_SUCCESS } from '../../../constants/constants';
import Button from '../../library/Button';
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

function CurrencyModal({
  show,
  onHide,
  changeSuccess,
  resetBalance,
  currencies,
  oldCurrency = {},
}) {
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      if (!selectedCurrency.id) {
        const currency = await getCurrencyByIp();

        setSelectedCurrency(currency);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCurrency = async (e) => {
    e.preventDefault();

    //If user chose Naira, no need settig currency, just move on
    if (+selectedCurrency.id === 1) {
      onHide();
      changeSuccess && changeSuccess();
    }

    setLoading(true);
    const reset = resetBalance && (await resetBalance());

    if (resetBalance && !reset) {
      setLoading(false);
      return;
    }

    const { type } = await requestApi(
      'post',
      '/buyer/wallets/set-currency',
      { currency_id: selectedCurrency.id },
      true
    );
    setLoading(false);

    if (type === API_SUCCESS) {
      onHide();
      changeSuccess && changeSuccess();
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    const chosen = currencies.find((curr) => +curr.id === +value);

    setSelectedCurrency(chosen);
  };

  return (
    <Modal
      isOpen={show}
      onClose={onHide}
      isCentered
      backdrop={oldCurrency.id ? true : 'static'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader closeButton={!!oldCurrency}>
          <ModalCloseButton />
          <Heading size="lg">Select Currency</Heading>
        </ModalHeader>

        <ModalBody className="currency-modal" py="4">
          <form onSubmit={saveCurrency}>
            <div className="form-group">
              {selectedCurrency.id ? (
                <select
                  value={selectedCurrency.id}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value={undefined} disabled>
                    Select Currency
                  </option>
                  {currencies.map((curr) => {
                    if (curr.id !== oldCurrency.id) {
                      return (
                        <option value={curr.id} key={curr.id}>
                          {`${curr.name} (${curr.symbol})`}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              ) : (
                <SimpleSpinner />
              )}
            </div>

            <div className="u-center">
              <Button isFullWidth isLoading={loading} type="submit">
                Save
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

CurrencyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  changeSuccess: PropTypes.func,
  resetBalance: PropTypes.func,
  currencies: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currencies: state.web.dependencies?.currencies,
  };
};

export default connect(mapStateToProps)(CurrencyModal);
