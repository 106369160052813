import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import { ButtonIcon, ButtonStyle } from './style';
import { ElementColor } from '../../../constants/Colors';

function Button(props) {
  const {
    isDisabled = false,
    isLoading = false,
    isFullWidth = false,
    type = 'button',
    leftIcon,
    rightIcon,
    colorScheme = 'primary',
    loadingText,
    as = 'button',
    children,
    ref,
    className,
    size = 'md',
    ...rest
  } = props;

  return (
    <ButtonStyle
      type={type}
      as={as}
      disabled={isDisabled || isLoading}
      ref={ref}
      className={className}
      $isFullWidth={isFullWidth}
      size={size}
      $colorScheme={colorScheme}
      {...rest}
    >
      {leftIcon && !isLoading && (
        <ButtonIcon $spacingPosition="right">{leftIcon}</ButtonIcon>
      )}

      {isLoading
        ? loadingText || <span opacity={0}>{children}</span>
        : children}

      {isLoading && (
        <ButtonIcon $spacingPosition="left">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </ButtonIcon>
      )}

      {rightIcon && !isLoading && (
        <ButtonIcon $spacingPosition="left">{rightIcon}</ButtonIcon>
      )}
    </ButtonStyle>
  );
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  colorScheme: PropTypes.oneOf(Object.keys(ElementColor)),
  variant: PropTypes.oneOf(['outline', 'regular']),
  loadingText: PropTypes.string,
  as: PropTypes.elementType,
  className: PropTypes.string,
  size: PropTypes.string,
  rounded: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
