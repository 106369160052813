import { parse } from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cookies } from '../utils/cookies';
import {
  BUYER_AFFILIATE_COOKIE_KEY,
  SELLER_AFFILIATE_COOKIE_KEY,
} from '../constants/constants';

export const useAffiliateInterceptor = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { b_aff, s_aff } = parse(search);

    const discountExpiry = new Date();
    discountExpiry.setTime(discountExpiry.getTime() + 30 * 24 * 60 * 60 * 1000);

    if (b_aff) {
      cookies.setItem(BUYER_AFFILIATE_COOKIE_KEY, b_aff, discountExpiry);
    }

    if (s_aff) {
      cookies.setItem(SELLER_AFFILIATE_COOKIE_KEY, s_aff, discountExpiry);
    }
  }, [search]);
};
