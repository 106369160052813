import l1 from '../assets/img/emblems/1.png';
import l2 from '../assets/img/emblems/2.png';
import l3 from '../assets/img/emblems/3.png';
import l4 from '../assets/img/emblems/4.png';
import l5 from '../assets/img/emblems/5.png';

const emblem = {
  L1: l1,
  L2: l2,
  L3: l3,
  L4: l4,
  L5: l5,
};

const text = {
  L1: 'Level 1',
  L2: 'Level 2',
  L3: 'Level 3',
  L4: 'Level 4',
  L5: 'Level 5',
};

const name = {
  L1: 'Aspiring',
  L2: 'Advance',
  L3: 'Elite',
  L4: 'Master',
  L5: 'Legend',
};

const caption = {
  L1: 'A promising freelancer with passion and right skillset to serve the world',
  L2: "A freelancer who’s advancing beyond mere promising to one with track records to show the world that they can deliver on promise. Completed at least 13 jobs successfully (or equivalent in projects' costs) with over 11 reviews and an average rating of 4.8.",
  L3: "An uncommon freelancer possessing expertise and strong qualities that are quite rare to find. Trusting them with project give peace of mind and assurance that nothing will go wrong.  Completed at least 35 jobs successfully (or equivalent in projects' costs) with over 30 reviews and an average of 4.8 rating.",
  L4: "A top-notch talent who has demonstrated complete mastery of the art of freelancing and make remote work seem so interesting even to the most skeptical clients wherever. Completed at least 70 jobs (or equivalent in projects' costs) with over 60 reviews and an average rating of 4.8.",
  L5: "A Legend in their space and time who has probably seen it all and can be trusted with virtually any ‘VVIP typed projects’ in their area of core skills. Their involvement in your project means a lot and gives failure no chance.  Completed at least 120 jobs (or equivalent in projects' costs) with over 100 reviews and an average rating of 4.8.",
};

const figure = {
  L1: 1,
  L2: 2,
  L3: 3,
  L4: 4,
  L5: 5,
};

export const levelObject = (level) => {
  return {
    level: text[level],
    emblem: emblem[level],
    name: name[level],
    caption: caption[level],
    figure: figure[level],
  };
};

export const levelEmblem = (level) => {
  return emblem[level];
};

export const levelText = (level) => {
  return text[level];
};

export const levelName = (level) => {
  return name[level];
};

export const levelFigure = (level) => {
  return figure[level];
};
