import { requestPublicApi, requestThirdPartyData } from "./api";

import BuyerAlert from "../modules/buyer/classes/Alert";
import Dependencies from "./Dependencies";
import store from "../store";
import { saveLocalIP } from "./ip";

//Save currency data to localStorage
export const saveCurrencyToLocal = (currency) => {
  if (currency && typeof currency === "object") {
    localStorage.setItem("selectedCurrency", JSON.stringify(currency));
  }
};

//Get Currency from localStorage
export const getStoredCurrency = () => {
  const savedCurrency = localStorage.getItem("selectedCurrency");
  if (savedCurrency === "undefined") {
    localStorage.removeItem("selectedCurrency");
    return false;
  }

  return JSON.parse(savedCurrency);
};

//Get currency short from country data
const getCurrencyShort = (countryCode, continentCode) => {
  switch (countryCode) {
    case "NG":
      return "NGN";
    case "GB":
      return "GBP";
    case "BR":
      return "BRL";
    case "CA":
      return "CAD";
    case "IN":
      return "INR";
    case "AE":
      return "AED";
    case "AU":
      return "AUD";
    case "ZA":
      return "ZAR";
    case "NZ":
      return "NZD";
    default:
      switch (continentCode) {
        case "EU":
          return "EUR";
        default:
          return "USD";
      }
  }
};

//Get full currency from country data
const getCurrencyByCountry = (country) => {
  const currencies = Dependencies.getCurrency();

  const currencyShort = getCurrencyShort(
    country?.country?.code || "",
    country?.ip_stack?.continent_code
  );

  return currencies.find((curr) => curr.short === currencyShort);
};

//Get user IP addresss from ipStack
export const getUserIpAddress = async () => {
  const ipEndpoint = `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IPSTACK_ACCESS}`;
  const ipData = await requestThirdPartyData("get", ipEndpoint);

  saveLocalIP(ipData);

  if (ipData.hasOwnProperty("ip")) {
    return ipData.ip;
  }
};

export const getCountryByIp = async () => {
  const currencyEndpoint = `/currencies/ip/${await getUserIpAddress()}`;
  const { data: country } = await requestPublicApi("get", currencyEndpoint);

  return country;
};

//Fetch currency based on user's IP address
export const getCurrencyByIp = async () => {
  const country = await getCountryByIp();

  const currency = getCurrencyByCountry(country);

  //Sets currency to storage after getting it
  return currency;
};

export const getCurrency = async () => {
  const storedCurrency = getStoredCurrency();
  if (storedCurrency && storedCurrency.flag_url) {
    return storedCurrency;
  }

  const ipCurrency = await getCurrencyByIp();
  saveCurrencyToLocal(ipCurrency);

  return ipCurrency;
};

export const getCurrencyUnit = () => {
  const currency = getStoredCurrency();
  return currency.symbol;
};

export const convertToSelectedCurrency = (price, decimal = true) => {
  const currency = getStoredCurrency();

  const convertedPrice = parseFloat(price, 10) / currency.divider;

  return convertedPrice;
};

export const resetPrice = (price, decimal = true) => {
  const currency = getStoredCurrency();

  const convertedPrice = price * currency.divider;

  return decimal ? convertedPrice.toFixed(2) : Math.ceil(convertedPrice);
};

export const convertSellerToBuyerCurrency = (sellerCurrId, amount) => {
  const { buyer, web } = store.getState();
  const rate =
    web.netConversions.othersToCurrency[buyer.currency.id][sellerCurrId];

  return (amount * rate.net_rate).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const promptCurrencyChange = async (wallet, firstName) => {
  // const NIGERIA_CODE = 402;
  // const MINIMUM_CURRENCY_CHANGE_DAYS = 90;

  if (wallet.currency_updated_at) {
    return;
  }

  const ipCurrency = await getCurrencyByIp();
  const walletCurrency = wallet.currency_id;

  if (+walletCurrency !== +ipCurrency.id) {
    const prompt = await BuyerAlert.promptCurrencyChange(firstName);

    if (prompt) {
      window.location.href = "/transactions";
    }

    return;
  }

  // const { currency_updated_at: lastUpdate } = wallet;

  // const lastCurrencyChange = moment(lastUpdate);
  // const now = moment();
  // const dateOfSignUp = moment.unix(signupDate);

  // const lastCurrencyUpdateInDays = now.diff(lastCurrencyChange, 'days');
  // const signupDateInDays = now.diff(dateOfSignUp, 'days');

  // if (
  //   lastCurrencyUpdateInDays > MINIMUM_CURRENCY_CHANGE_DAYS ||
  //   signupDateInDays < MINIMUM_CURRENCY_CHANGE_DAYS ||
  //   lastUpdate
  // ) {
  //   const { country } = await getCountryByIp();
  //   if (country.id !== NIGERIA_CODE) {
  //     const prompt = await BuyerAlert.promptCurrencyChange(firstName);

  //     if (prompt) {
  //       window.location.href = '/transactions';
  //     }
  //   }
  // }
};

// Currency unit with two decikaml places for display
export const currencyUnit = (amount, format) => {
  let num = Number(amount).toFixed(2);
  return format === "string"
    ? num
    : new Intl.NumberFormat("en", {
        minimumFractionDigits: 2,
      }).format(num);
};
