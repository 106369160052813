export const CHAT_WS = "CHAT_WS";
export const CHAT_CONVERSATIONS = "CHAT_CONVERSATIONS";
export const CHAT_MESSAGES = "CHAT_MESSAGES";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const CHAT_ONLINE_USERS = "CHAT_ONLINE_USERS";
export const CHAT_SOCKET = "CHAT_SOCKET";
export const CHAT_TYPE = "CHAT_TYPE";
export const CHAT_USER = "CHAT_USER";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ACTIVE_CHAT = "ACTIVE_CHAT";
export const UPDATE_CONVERSATIONS = "UPDATE_CONVERSATIONS";
export const UPDATE_CONVERSATION_PARTIAL = "UPDATE_CONVERSATION_PARTIAL";
export const UPDATE_LAST_MESSAGE = "UPDATE_LAST_MESSAGE";
export const UPDATE_ADMIN_LAST_MESSAGE = "UPDATE_ADMIN_LAST_MESSAGE";
export const SET_ADMIN_CONV = "SET_ADMIN_CONV";
