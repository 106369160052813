import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ElementColor } from '../../../../../constants/Colors';
import { HStack, VStack } from '../../../../library/layout/Stack';
import { Link, useLocation } from 'react-router-dom';
import { useEffectAfterMount } from '../../../../../hooks/useMountEffects';
import DropDown from '../../../../library/dropdown';
import { linksList } from '../desktop/left';
import { PlainButton } from '../../../../library/Button/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchModal from './search';
import { Bold, GreenText, Small } from '../../../../../styling/Text';
import { NavDropItem, navLinks } from '../desktop/right';
import { Logout, NavAvatar, NavProfile } from '../desktop/style';
import {
  BUYER_APPLICATION,
  SELLER_APPLICATION,
} from '../../../../../constants/constants';
import Auth from '../../../../../utils/Auth';
import { Center, Flex, Link as ChakraLink } from '@chakra-ui/layout';
import { InstallApp } from '../../Footer/InstallApp';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/modal';
import { Switcher } from './switcher';

const auth = new Auth();

const FindLinks = styled(VStack)`
  font-size: 1rem;
  margin: 0.5rem 1rem 1rem;
`;

const FindDropStyle = styled.div`
  margin-left: -0.5rem;
`;

const FindDrop = ({ title, items = [] }) => {
  return (
    <FindDropStyle>
      <DropDown
        title={title}
        dropItemStyles={{ position: 'relative' }}
        showCaret={false}
        isFullWidth
      >
        <FindLinks>
          {items.map(({ title: name, url, isExternal }) =>
            isExternal ? (
              <ChakraLink href={url} isExternal key={url}>
                {name}
              </ChakraLink>
            ) : (
              <Link to={url} key={url}>
                {name}
              </Link>
            )
          )}
        </FindLinks>
      </DropDown>
    </FindDropStyle>
  );
};

const SearchButton = styled(PlainButton)`
  border-bottom: 1px solid ${ElementColor.line};
  padding: 1.5rem 0;
`;

const SidebarSwitch = styled(VStack)`
  border-bottom: 1px solid ${ElementColor.line};
  padding-bottom: 1rem;
`;

const Private = ({ application, buyer, seller }) => {
  const activeLink = navLinks[application];

  return (
    <VStack spacing="1rem">
      <NavProfile
        to={
          application === SELLER_APPLICATION
            ? '/personal-info'
            : '/services/personal-info'
        }
      >
        <HStack spacing="1.5rem">
          <NavAvatar
            src={
              application === SELLER_APPLICATION
                ? seller?.avatar
                : buyer?.logo_or_profile_pic
            }
            alt={buyer?.username}
          />
          <VStack>
            <Bold>{buyer?.username}</Bold>
            <Small>{`${buyer?.first_name} ${buyer?.last_name}`}</Small>
          </VStack>
        </HStack>
      </NavProfile>
      <SidebarSwitch spacing="0.5rem">
        <Switcher />
        {application === BUYER_APPLICATION && (
          <FindDrop title="Find Talent" items={linksList.talent} />
        )}
        {application === SELLER_APPLICATION && (
          <FindDrop title="Find Jobs" items={linksList.jobs} />
        )}
        {application === BUYER_APPLICATION && (
          <FindDrop title="Enterprise" items={linksList.enterprise} />
        )}
      </SidebarSwitch>

      {activeLink.map((link) => (
        <NavDropItem {...link} />
      ))}
      <Logout onClick={auth.Logout}>Logout</Logout>
    </VStack>
  );
};

const Public = () => {
  const [showSearch, setShow] = useState(false);
  const location = useLocation();

  const toggleSearch = () => {
    setShow((prev) => !prev);
  };

  return (
    <Flex flexDir="column" h="full">
      <VStack spacing="1rem">
        <SearchButton $isFullWidth onClick={toggleSearch}>
          <HStack spacing="1rem">
            <FontAwesomeIcon icon={faSearch} color={ElementColor.darkGrey} />
            <span>Search</span>
          </HStack>
        </SearchButton>
        <FindDrop title="Find Talent" items={linksList.talent} />
        <FindDrop title="Find Jobs" items={linksList.jobs} />
        <FindDrop title="Enterprise" items={linksList.enterprise} />
        <VStack spacing="1.5rem">
          <Link
            to={{
              pathname: '/login',
              state: {
                from: location,
              },
            }}
          >
            Log In
          </Link>
          <Link to="/signup">
            <GreenText>Sign Up</GreenText>
          </Link>
        </VStack>
      </VStack>
      <Center mt="auto" w="full" ml="-2" pb="16">
        <InstallApp showTitle={false} />
      </Center>
      <SearchModal show={showSearch} toggleSearch={toggleSearch} />
    </Flex>
  );
};

const Sidebar = ({ show, toggleSideBar, isAuthenticated, ...props }) => {
  const { pathname, search } = useLocation();

  useEffectAfterMount(() => {
    toggleSideBar();
  }, [pathname, search]);

  return (
    <>
      <Drawer isOpen={show} onClose={toggleSideBar} placement="left" size>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {isAuthenticated ? <Private {...props} /> : <Public />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleSideBar: PropTypes.func.isRequired,
};

export default Sidebar;
