import { parse } from "query-string";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DISCOUNT_COOKIE_KEY } from "../constants/constants";
import { applyDiscount } from "../modules/Order/Routes/CartItems/service";
import { cookies } from "../utils/cookies";
import { useDependencies } from "./useDependencies";

export const useDiscountInterceptor = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { discount, coupon, offer } = parse(search);
    const discountFromUrl = discount || coupon || offer;
    const existingDiscount = cookies.getItem(DISCOUNT_COOKIE_KEY);

    if (!!discountFromUrl && discountFromUrl !== existingDiscount) {
      const discountExpiry = new Date();
      discountExpiry.setTime(
        discountExpiry.getTime() + 30 * 24 * 60 * 60 * 1000
      );

      cookies.setItem(DISCOUNT_COOKIE_KEY, discountFromUrl, discountExpiry);
    }
  }, [search]);
};

export const useDiscount = () => {
  const orderDetails = useSelector((state) => state.order.orderDetails);
  const buyerCurrency = useSelector(
    (state) => state.buyer.user.wallet.currency_id
  );
  const voucher = useSelector((state) => state?.order?.voucher);
  const dispatch = useDispatch();
  const { getNetConversion } = useDependencies();

  const getStoredDiscount = useCallback(() => {
    return cookies.getItem(DISCOUNT_COOKIE_KEY);
  }, []);

  const updateDiscount = useCallback(
    async (subTotal) => {
      const netConversion = getNetConversion(
        buyerCurrency,
        orderDetails?.currency //Selling Currency
      );

      if (subTotal && voucher) {
        const [err] = await dispatch(
          applyDiscount({
            amount: subTotal * netConversion,
            currency_id: buyerCurrency,
            code: voucher,
          })
        );

        if (err) {
          console.log(err);
        }
      }
    },
    [buyerCurrency, dispatch, orderDetails, getNetConversion, voucher]
  );

  const removeSavedDiscount = useCallback(() => {
    const savedDiscount = getStoredDiscount();

    if (voucher && savedDiscount === voucher) {
      cookies.removeItem(DISCOUNT_COOKIE_KEY);
    }
  }, [voucher, getStoredDiscount]);

  return {
    removeSavedDiscount,
    getStoredDiscount,
    updateDiscount,
  };
};
