import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import styled from "styled-components";
import { ElementColor } from "../../../../../constants/Colors";
import { getSuggestions } from "../../../../../utils/search";
import { replaceSpaces } from "../../../../../utils/transfomer";
import { getSuggestIcon } from "../../../../common/Suggestions";
import { PlainButton } from "../../../../library/Button/style";
import Input from "../../../../library/Input";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/modal";

const CloseButton = styled(PlainButton)`
  margin-left: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
`;

const Suggestions = styled.div``;

const SuggestItem = styled(PlainButton)`
  padding: 1rem;
  justify-content: flex-start;
  border-bottom: 1px solid ${ElementColor.lineLight};
`;

function SearchModal({ show, toggleSearch }) {
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();

  const onSearch = (e) => {
    e.preventDefault();
    const query = replaceSpaces(text, "+");

    toggleSearch();
    history.push(`/profile-search?query=${query}`);
  };

  useEffect(() => {
    const handleSuggest = async () => {
      const data = await getSuggestions(text);
      setSuggestions(data);
    };

    if (text) {
      const debounce = setTimeout(handleSuggest, 1000);

      return () => clearTimeout(debounce);
    }
  }, [text]);

  const clickSuggest = (sugg = "") => {
    const query = replaceSpaces(sugg, "+");

    toggleSearch();
    history.push(`/profile-search?query=${query}`);
  };

  return (
    <>
      <Drawer isOpen={show} onClose={toggleSearch} placement="top" size>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <SearchForm onSubmit={onSearch}>
              <Input
                autoFocus
                value={text}
                placeholder="Search for anything"
                onChange={(e) => setText(e.target.value)}
              />
              <CloseButton type="button" onClick={toggleSearch}>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color={ElementColor.line}
                />
              </CloseButton>
            </SearchForm>
            <Suggestions>
              {suggestions.map((sugg) => (
                <SuggestItem
                  $isFullWidth
                  onClick={() => clickSuggest(sugg.text)}
                  key={shortid.generate()}
                >
                  <FontAwesomeIcon
                    icon={getSuggestIcon(sugg.type)}
                    color={ElementColor.line}
                    style={{ marginRight: "1rem" }}
                  />
                  {sugg.text}
                </SuggestItem>
              ))}
            </Suggestions>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SearchModal;
