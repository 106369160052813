import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { toggleAuthModal } from '../../../../modules/auth/store/actions';
import Modal from '../../../library/Modal';
import { VerifyText } from './style';
import Form from './form';

function VerifyModal({ show, dispatch }) {
  const toggleVerifyModal = useCallback(() => {
    dispatch(toggleAuthModal('verify'));
  }, [dispatch]);

  const onVerify = useCallback(() => {
    dispatch(toggleAuthModal('verify'));
  }, [dispatch]);

  return (
    <>
      <Modal isOpen={show} toggleModal={toggleVerifyModal}>
        <Modal.Content closeOnClickOutside={false}>
          <Modal.Header>Verify Mail</Modal.Header>
          <Modal.Body>
            <VerifyText>
              <p>
                Please check your email. A verification code has been sent to
                you.
              </p>
            </VerifyText>
            <Form onVerify={onVerify} />
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.auth.modals.verify,
  };
};

export default connect(mapStateToProps)(VerifyModal);
