import styled, { css } from 'styled-components';
import { ElementColor, TextColor } from '../../../constants/Colors';

const requiredLabel = css`
  &:after {
    content: '*';
    color: ${TextColor.red};
    margin-left: 0.3rem;
  }
`;

const optionalLabel = css`
  &:after {
    content: '(optional)';
    color: ${TextColor.label};
    margin-left: 0.3rem;
  }
`;

export const TextareaLabel = styled.label`
  ${(props) => props.$required && requiredLabel}
  ${(props) => props.$optional && optionalLabel}
`;

const areaHeights = {
  xs: 60,
  sm: 80,
  md: 100,
  lg: 120,
};

export const TextAreaStyle = styled.textarea`
  border: 1px solid
    ${(props) =>
      props.$hasError ? ElementColor.danger : ElementColor.lineLight};
  padding: 1rem;
  border-radius: 5px;
  height: ${(props) => areaHeights[props.$size]}px;
  width: 100%;
  appearance: none;
`;
