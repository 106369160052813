import { faComment, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DesktopAndTablet, MobileOnly } from '../../../styling/breakpoints';
import TalkForm from './form';
import { TalkButton, TalkToUsContainer } from './style';
import { useLocation } from 'react-router-dom';
import { getPath } from '../../../utils/UrlParameter';

const excludedPages = [
  'login',
  'signup',
  'signup-buyer',
  'signup-seller',
  'verify-code',
  'signup-talent',
  'forgot-password',
  'contact',
  'talk-to-us',
  'project-request',
  'seller',
  'home',
];

const MODAL_DELAY = 1000 * 60; // 1 minutes, 30 secs
const BUTTON_DELAY = 1000 * 4; // 15 seconds

const TalkToUsStorage = {
  save() {
    sessionStorage.setItem('talk-to-us', true);
  },

  check() {
    return sessionStorage.getItem('talk-to-us');
  },

  remove() {
    sessionStorage.removeItem('talk-to-us');
  },
};

function TalkToUs({ delay = BUTTON_DELAY }) {
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();

  const toggleActive = () => setActive((prev) => !prev);

  useEffect(() => {
    const path = getPath(location.pathname, 1);
    if (excludedPages.includes(path)) return;

    const previouslyShownModal = TalkToUsStorage.check();

    let modalTimeout;

    if (!previouslyShownModal) {
      modalTimeout = setTimeout(() => setActive(true), MODAL_DELAY);
    }
    const buttonTimeout = setTimeout(() => setShow(true), delay);
    TalkToUsStorage.save();

    return () => {
      clearTimeout(buttonTimeout);
      if (!modalTimeout) {
        clearTimeout(modalTimeout);
      }
    };
  }, [delay, location.pathname]);

  return (
    <>
      {active && (
        <MobileOnly>
          <TalkForm close={toggleActive} />
        </MobileOnly>
      )}

      <TalkToUsContainer>
        {active && (
          <DesktopAndTablet>
            <TalkForm close={toggleActive} />
          </DesktopAndTablet>
        )}

        {show && (
          <TalkButton onClick={toggleActive}>
            <FontAwesomeIcon icon={active ? faTimes : faComment} />
          </TalkButton>
        )}
      </TalkToUsContainer>
    </>
  );
}

export default TalkToUs;
