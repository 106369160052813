import { Box } from '@chakra-ui/react';
import React from 'react';
import { Container } from '../../../../../styling/Layout';

export const HomeSection = ({ children, ...props }) => {
  return (
    <Box as="section" py={{ base: '10', md: '20' }} pos="relative" {...props}>
      <Container>{children}</Container>
    </Box>
  );
};
