import { Box, Container, Flex, HStack, Spacer, Text } from '@chakra-ui/layout';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Button } from '@chakra-ui/button';
import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';

import { Logo } from '../../../../../components/layout/new/Header/mobile/styles';
import useAuthentication from '../../../../../hooks/useAuthetication';
import DropDown from '../../../../../components/library/dropdown';
import {
  LinkList,
  LinksMenu,
} from '../../../../../components/layout/new/Header/desktop/left';
import { Sidebar } from './Sidebar';
import { navLinks } from './navLinks';

export const primaryHeight = 80;

const landingHeaderProps = {
  alignItems: 'center',
  boxShadow: 'none',
  minH: `${primaryHeight}px`,
  h: `${primaryHeight}px`,
  mx: 'auto',
  w: '100%',
  zIndex: 100,
  gridGap: '4',
};

export const AuthLinks = ({ isDark }) => {
  return (
    <HStack spacing="6" borderLeftWidth="1.5px" color="text.header">
      <Button
        px="1rem"
        color={isDark ? 'white' : 'text.headerGray'}
        variant="link"
        as={RouterLink}
        to="/login"
      >
        Login
      </Button>
      <Button
        as={RouterLink}
        to="/signup"
        colorScheme="primary"
        size="md"
        boxShadow="0px 8px 20px 0px rgba(131, 188, 35, 0.25)"
      >
        Sign up
      </Button>
    </HStack>
  );
};

const UserProfile = () => {
  const { buyer, fetchingAuth: isLoading } = useAuthentication();

  const history = useHistory();

  const logout = () => {
    history.push('/');
    window.location.reload();
  };

  return (
    <>
      {isLoading && !buyer?.id ? (
        <HStack p="2" borderWidth="1px" rounded="lg">
          <SkeletonCircle size="30px" />
          <Skeleton height="18px" w="100px" />
        </HStack>
      ) : (
        <Menu>
          <MenuButton
            as={RouterLink}
            to="/dashboard"
            gap="4"
            alignItems="center"
            size="lg"
          >
            <HStack spacing="4" bg="primary.200" py="2" px="4" rounded="xl">
              <Avatar
                name={`${buyer.firstName} ${buyer.lastName}`}
                src={buyer.logo_or_profile_pic}
                size="md"
              />
              <Text as="span" fontSize="xl" fontWeight={600} color="text.brand">
                <Text as="span" display={['none', 'inline']}>
                  Go to{' '}
                </Text>
                Dashboard
              </Text>
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem color="red.500" fontWeight={600} onClick={logout}>
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export const HeaderRight = () => {
  const { authenticated } = useAuthentication();

  return (
    <>
      <HStack spacing="3">
        {authenticated ? <UserProfile /> : <AuthLinks />}
      </HStack>
    </>
  );
};

export const Header = () => {
  return (
    <Box
      as="header"
      w="100vw"
      pos="fixed"
      top="0"
      left="0"
      bg={'white'}
      zIndex={33}
      borderBottom="1px solid"
      borderColor={'gray.200'}
    >
      <Container maxW="container.xl">
        <Flex {...landingHeaderProps}>
          <HStack spacing="12">
            <Flex align="center" gap="4">
              <Sidebar />
              <RouterLink to="/">
                <Logo
                  w="258px"
                  src="/images/logo_black.svg"
                  alt="terawork logo"
                />
              </RouterLink>
            </Flex>

            <HStack spacing="8" display={['none', null, 'flex']}>
              <DropDown title="Products" triggerType="hover">
                <LinkList links={navLinks.products} columns="1fr" />
              </DropDown>

              <DropDown title="Find Talents" triggerType="hover">
                <LinksMenu links={navLinks.talent} columns="1fr" />
              </DropDown>

              <DropDown title="Find Jobs" triggerType="hover">
                <LinksMenu links={navLinks.job} columns="1fr" />
              </DropDown>
            </HStack>
          </HStack>
          <Spacer />
          <HeaderRight />
        </Flex>
      </Container>
    </Box>
  );
};
