import socketIOClient from 'socket.io-client';

class Socket {
  constructor(
    username,
    socket = socketIOClient(`chat.terawork.com:8080?username=${username}`)
  ) {
    this.socket = socket;
    this.username = username;
    socket.on('connect', this.connect);
    socket.on('disconnect', this.disconnect);
    socket.on('error', this.error);
  }

  on = (type, callback) => {
    this.socket.on(type, callback);
  };

  emit = (type, data) => {
    this.socket.emit(type, data);
  };

  open = () => {
    const msgStruct = {
      action: 'getConversations',
      senderName: this.username,
      status: 'ok',
    };
    this.socket.send(JSON.stringify(msgStruct));
  };

  connect = () => {
    const msgStruct = {
      action: 'getConversations',
      senderName: this.username,
      status: 'ok',
    };

    this.socket.emit('getConversations', msgStruct);
    // this.socket.emit('getUserTeraConversations');
  };

  disconnect = () => {};

  error = (err) => {
    console.log('error', err);
  };
}

export default Socket;
