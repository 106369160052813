import localStore from 'localforage';

localStore.config({
  driver: localStore.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'TERAWORK',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
});

export const STORE_PREFIX = 'TWK';
export const STORE_NAME = 'TWK';

export default localStore;
