import { Image } from '@chakra-ui/image';
import { Box, Container, Grid, Text, VStack } from '@chakra-ui/layout';
import Marquee from 'react-fast-marquee';

const LogoImage = (props) => {
  return <Image {...props} filter="grayscale(100%)" />;
};

const TrusteeMarquee = () => {
  return (
    <Marquee>
      <LogoImage
        mr="12"
        src="/images/logos/helium-health.png"
        alt="helium heath"
        w="auto"
        h={['4.5rem', '6rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/deloitte.png"
        alt="deloitte"
        w="auto"
        h={['2.5rem', '4rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/zenith.png"
        alt="zenith bank"
        w="auto"
        h={['2rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/giz.webp"
        alt="GIZ"
        w="auto"
        h={['2rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/rccg.webp"
        alt="rccg"
        w="auto"
        h={['2.5rem', '3.5rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/coral-pay.png"
        alt="coral pay"
        w="auto"
        h={['2.5rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/nexford.png"
        alt="nextford university"
        w="auto"
        h={['2.5rem', '3.5rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/village-capital.png"
        alt="village capital"
        w="auto"
        h={['3.5rem', '5rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/fiber-one.png"
        alt="fiber one"
        w="auto"
        h={['2.5rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/lekki-gardens.png"
        alt="lekki gardens"
        w="auto"
        h={['2.5rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/first-bank.png"
        alt="first bank"
        w="auto"
        h={['3.5rem', '5rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/inspire.png"
        alt="inspire"
        w="auto"
        h={['2.5rem', '3rem']}
      />
      <LogoImage
        mr="12"
        src="/images/logos/google.png"
        alt="google"
        w="auto"
        h={['2.5rem', '3rem']}
      />
    </Marquee>
  );
};

export const Trustees = () => {
  return (
    <Container maxW="container.xl" overflowX="hidden" py={[4]}>
      <Grid
        templateColumns={['1fr', null, '12rem 1fr']}
        gap={['0.75rem', '0']}
        zIndex={1}
        pos="relative"
        alignItems={{ base: 'start', sm: 'center' }}
      >
        <Box w={['full', '17rem']} maxW="100vw" pr="2rem">
          <Text color="text">Trusted by:</Text>
        </Box>
        <VStack
          h="full"
          justifyContent="center"
          w="full"
          css={`
            .initial-child-container {
              align-items: center;
            }
          `}
        >
          <TrusteeMarquee />
        </VStack>
      </Grid>
    </Container>
  );
};
