import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  VStack,
} from '@chakra-ui/layout';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/button';

export const LandingCTA = () => {
  return (
    <Box w="full" bg="white" pb={[12, null, '32']}>
      <Container maxW="container.xl">
        <Box
          border="1px solid"
          borderColor="borderColor"
          rounded="3xl"
          p={[4, 8, 12]}
          py={[8, 16, 20]}
          bg="bg.black"
          color="white"
        >
          <VStack maxW="800px" mx="auto" alignItems="center" spacing="12">
            <Heading
              as="h2"
              color="text.header"
              size="4xl"
              fontWeight={500}
              textAlign="center"
            >
              Connect with Our Team
            </Heading>

            <Center>
              <Flex flexDir={['column', 'row']} gap="4" spacing="4">
                <Button
                  as={RouterLink}
                  to="/project-request"
                  colorScheme="primary"
                  size="xl"
                  minW="200px"
                >
                  Talk to Us
                </Button>
                <Button
                  as={Link}
                  href={
                    'https://calendly.com/abimbola-5n4/talk-to-a-success-manager'
                  }
                  isExternal
                  variant="outline"
                  colorScheme="primary"
                  size="xl"
                  rounded="full"
                  bg="white"
                  _hover={{
                    color: 'text.body',
                  }}
                >
                  Schedule a Call
                </Button>
              </Flex>
            </Center>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};
