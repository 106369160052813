import * as actions from '../../store/';

import { requestPublicApi } from '../../../../utils/api';
import { compare } from '../../../../utils/compare';
import {
  extractCompareProfile,
  extractCompareHeader,
} from '../../../../utils/transfomer';

export const handleCompare = () => {
  return async (dispatch) => {
    //get all comparison sellections from storage
    const compareSelection = compare.get();

    const compareProfiles = await Promise.all(
      compareSelection.map((item) => requestPublicApi('get', `sellers/${item}`))
    );

    //Extract the data needed for comparison
    const compareItems = compareProfiles
      .filter((profile) => profile.data)
      .map((profile) => extractCompareProfile(profile?.data?.seller_service));

    //Extract the data needed for comparison header
    const compareHeader = compareItems.map((item) =>
      extractCompareHeader(item)
    );

    dispatch(actions.setCompareHeader(compareHeader));
    dispatch(actions.setCompareBody(compareItems));
  };
};

export function setComparableReq(val) {
  return (dispatch) => dispatch(actions.setCompare(val));
}
