import React from 'react';
import { Link } from 'react-router-dom';

import { MobileAndTablet } from '../../../../../styling/breakpoints';
import { HStack } from '../../../../library/layout/Stack';
import { MobileHeaderStyle, Logo } from './styles';
import Sidebar from './sidebar';
import MobileHeaderLeft from './right';
import { SELLER_APPLICATION } from '../../../../../constants/constants';
import { Icon } from '@chakra-ui/icon';
import MenuIcon from 'mdi-react/MenuIcon';
import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';

export function MobileHeaderMenu(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div>
      <IconButton
        variant="unstyled"
        icon={<Icon as={MenuIcon} boxSize="8" />}
        aria-label="open menu"
        onClick={onOpen}
      />
      <Sidebar {...props} show={isOpen} toggleSideBar={onClose} />
    </div>
  );
}

function MobileHeader(props) {
  const { application, logoUrl = '/' } = props;
  return (
    <MobileAndTablet>
      <MobileHeaderStyle>
        <HStack spacing="1rem">
          <MobileHeaderMenu {...props} />
          <Link to={application === SELLER_APPLICATION ? '/services' : logoUrl}>
            <Logo
              src="https://www.terawork.com/images/logo_black.svg"
              alt="terawork logo"
            />
          </Link>
        </HStack>
        <MobileHeaderLeft {...props} />
      </MobileHeaderStyle>
    </MobileAndTablet>
  );
}

MobileHeader.propTypes = {};

export default MobileHeader;
