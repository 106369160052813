import React from 'react';
import PropTypes from 'prop-types';

function Lens({ width = 20, height = 20, fill = 'none' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2 16.4C13.1764 16.4 16.4 13.1764 16.4 9.2C16.4 5.22355 13.1764 2 9.2 2C5.22355 2 2 5.22355 2 9.2C2 13.1764 5.22355 16.4 9.2 16.4Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18L14.8 14.8"
        stroke="white"
        strokeWidth="2"
        // strokeWinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LensDark() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2 16.4C13.1764 16.4 16.4 13.1765 16.4 9.20002C16.4 5.22357 13.1764 2.00002 9.2 2.00002C5.22355 2.00002 2 5.22357 2 9.20002C2 13.1765 5.22355 16.4 9.2 16.4Z"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18L14.8 14.8"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Lens.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default Lens;
