import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';

export const Enterprise = () => {
  return (
    <Box py={[20, 16]} px="4">
      <Container
        maxW="container.xl"
        bg="#563BDB"
        color="white"
        rounded="xl"
        p="8"
        py={[8, null, '12']}
      >
        <Flex
          flexDir={['column', null, 'row']}
          align={['start', null, 'center']}
          justify="space-between"
          gap="6"
        >
          <Box>
            <Heading size="lg" color="white" mb="2">
              Assemble Talents How You Want It - Try TERAWORK Enterprise
            </Heading>

            <Text>Checkout other recruitment options suited to your needs</Text>
          </Box>
          <Button
            as={Link}
            isExternal
            href={`https://enterprise.terawork.com/`}
            bg="white"
            size="xl"
            minW="200px"
            color="text.body"
            colorScheme="whiteAlpha"
            variant="solid"
          >
            Hire now
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};
