import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuthModal } from "../modules/auth/store/actions";
import localStore, { STORE_NAME, STORE_PREFIX } from "../utils/localStore";

export const buyerStore = localStore.createInstance({
  storeName: `${STORE_PREFIX}-buyer`,
  name: STORE_NAME,
});

export const sellerStore = localStore.createInstance({
  storeName: `${STORE_PREFIX}-seller`,
  name: STORE_NAME,
});

const useAuthentication = () => {
  const auth = useSelector((state) => state.auth);
  const buyer = useSelector((state) => state.buyer.user);
  const seller = useSelector((state) => state.seller.user);
  const fetchingAuth = useSelector((state) => state.buyer.fetchingBuyerData);

  const dispatch = useDispatch();

  const showModal = useCallback(
    (type) => dispatch(toggleAuthModal(type)),
    [dispatch]
  );

  const getBuyerFromLocal = useCallback(async () => {
    const buyerData = await buyerStore.getItem(`data-${buyer?.id}`);
    return buyerData;
  }, [buyer]);

  const getSellerFromLocal = useCallback(async () => {
    const sellerData = await sellerStore.getItem(`data-${seller?.id}`);
    return sellerData;
  }, [seller]);

  return {
    showModal,
    authDetails: auth.authDetails,
    authenticated: auth.authenticated,
    application: auth.application,
    buyer,
    seller,
    getSellerFromLocal,
    getBuyerFromLocal,
    fetchingAuth,
  };
};

export default useAuthentication;
