import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const DropDownStyle = styled.div`
  position: relative;
`;

export const DropItems = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 0.5rem;
`;
