import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../../library/Button";
import { MessageIcon } from "../desktop/icon";
import { SearchIcon } from "./icon";
import { IconGroup } from "./styles";

const AuthenticatedNav = (props) => {
  return (
    <IconGroup spacing="1.5rem" alignItems="center">
      <SearchIcon />
      <MessageIcon />
    </IconGroup>
  );
};

function MobileHeaderLeft(props) {
  const { isAuthenticated } = props;

  return isAuthenticated ? (
    <AuthenticatedNav {...props} />
  ) : (
    <Button as={Link} to="/workstore/create" size="sm">
      Post a Job
    </Button>
  );
}

export default MobileHeaderLeft;
