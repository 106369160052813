import React from 'react';
import { connect } from 'react-redux';
import { PageMain } from '../../../../styling/Layout';
import HomeSeo from './components/HomeSeo';
import Page from './Page';

function HomePage(props) {
  return (
    <PageMain>
      <HomeSeo />
      <Page {...props} />
    </PageMain>
  );
}

const mapStateToProps = (state) => {
  return {
    isauthenticated: state.auth.authenticated,
  };
};

export default connect(mapStateToProps)(HomePage);
