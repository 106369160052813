import { API_FAIL } from '../constants/constants';
import { buyerStore, sellerStore } from '../hooks/useAuthetication';
import {
  getUserConversionRates,
  setBuyerDataReq,
} from '../modules/buyer/service';
import { setUserCurrency } from '../modules/buyer/store/actions';
import { setSellerDataReq } from '../modules/seller/service';
import { requestApi } from '../utils/api';
import Auth from '../utils/Auth';
import Dependencies from '../utils/Dependencies';

const auth = new Auth();

const getLoggedUserProfile = async (url) => {
  const { type, data, statusCode } = await requestApi('get', url, false);

  if (type === API_FAIL) {
    if (statusCode === 401) {
      auth.refreshSession(() => {
        window.location.reload();
      });
    }

    return { data: {} };
  }

  return { data };
};

export function getUserProfile(isSeller) {
  return async (dispatch) => {
    let data = {};
    if (isSeller) {
      const [{ data: seller }, { data: buyer }] = await Promise.all([
        getLoggedUserProfile('seller/profile'),
        getLoggedUserProfile('profile'),
      ]);

      //If user is deactvated, always logout
      if (buyer?.user?.status === 0 || seller?.user?.status === 0) {
        auth.Logout();
      } else {
        data.buyer = buyer?.user ?? {};
        data.seller = seller?.user ?? {};

        //Save details to local
        buyerStore.setItem(`data-${data.buyer?.id}`, data.buyer);
        sellerStore.setItem(`data-${data.buyer?.id}`, data.seller);

        //Dispatch to redux store
        dispatch(setBuyerDataReq(data.buyer));
        dispatch(setSellerDataReq(data.seller));
        dispatch(setPrivateCurrency(data.buyer?.wallet?.currency_id));
      }
    } else {
      const { data: buyer } = await getLoggedUserProfile('profile');

      if (buyer?.user?.status === 0) {
        auth.Logout();
      } else {
        data.buyer = buyer?.user ?? {};
        buyerStore.setItem(`data-${data.buyer?.id}`, data.buyer);

        //Dispatch to redux store
        dispatch(setBuyerDataReq(data.buyer));
        dispatch(setPrivateCurrency(data.buyer?.wallet?.currency_id));
      }
    }
  };
}

export const authChecks = () => {
  const isAuthenticated = auth.isAuthenticated();

  if (isAuthenticated) {
    // api.defaults.headers.common['Authorization'] = auth.getUserToken('idToken');
    auth.TokenRefreshInterval();

    return true;
  }
  return false;
};

//Set the currency data based on user's wallet
export function setPrivateCurrency(currencyId) {
  return (dispatch) => {
    const currency = Dependencies.getCurrency(currencyId);
    dispatch(setUserCurrency(currency));

    dispatch(getUserConversionRates(currencyId));
  };
}
