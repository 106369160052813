export const SELECTED_CURRENCY = 'SELECTED_CURRENCY';
export const SET_COMPARE = 'SET_COMPARE';

export const CATEGORIES = 'CATEGORIES';
export const CATEGORY = 'CATEGORY';
export const RANDOM_SELLERS = 'RANDOM_SELLERS';
export const SET_DEPENDECY = 'SET_DEPENDECY';

export const COMPARE_HEADER = 'COMPARE_HEADER';
export const COMPARE_BODY = 'COMPARE_BODY';

export const COUNTRIES = 'COUNTRIES';
export const BANKS = 'BANKS';

export const SET_DETAILS = 'SET_DETAILS';
export const SELLER_HOME = 'SELLER_HOME';

export const WORKSTORE = 'WORKSTORE';
export const JOBPOST = 'JOBPOST';
export const JOBS_COMBO = 'JOBS_COMBO';

export const SET_ONGOING_PROMOS = 'SET_ONGOING_PROMOS';
export const SET_PENDING_PROMOS = 'SET_PENDING_PROMOS';
export const SET_PROMO = 'SET_PROMO';
export const SET_PROMO_PROFILE = 'SET_PROMO_PROFILE';
export const SET_PROMO_BANNER = 'SET_PROMO_BANNER';
export const SET_ATTACHABLE_PROMO = 'SET_ATTACHABLE_PROMO';

export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';

export const SET_EMPLOY_JOB = 'SET_EMPLOY_JOB';

export const SET_SELLER_GALLERY = 'SET_SELLER_GALLERY';
export const SET_TOP_RATED_SERVICES = 'SET_TOP_RATED_SERVICES';

const actionTypes = {
  CATEGORY,
  CATEGORIES,
  RANDOM_SELLERS,
  COMPARE_HEADER,
  COMPARE_BODY,
  COUNTRIES,
  BANKS,
  SELLER_HOME,
  WORKSTORE,
  SET_DEPENDECY,
  SET_PROMO_PROFILE,
  SET_CURRENCY_RATES,
  SET_EMPLOY_JOB,
};

export default actionTypes;
