const OrderState = {
  activeMenu: 0,
  selectedPackages: [],
  packages: [],
  seller_id: "",
  profile_id: "",
  orderDetails: {},
  deliveryMethod: { delivery_method_id: 1, name: "Online" },
  deliveryLocation: {},
  voucher: "",
  startDate: Date.now(),
  source: "",
  discountAmount: 0,
  paymentCharge: 0,
  subTotal: 0,
  convertedSubTotal: 0,
};

export default OrderState;
