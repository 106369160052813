import styled, { keyframes } from "styled-components";
import { VStack } from "../../../../../../components/library/layout/Stack";
import breakpoints from "../../../../../../constants/breakpoints";
import { TextColor } from "../../../../../../constants/Colors";
import { fontSize, fontWeight } from "../../../../../../constants/sizes";

export const ImageContainer = styled.div`
  position: relative;
`;

export const ImageStyle = styled.img`
  width: ${(props) => props.$size || "100%"};
`;

const popOut = keyframes`
  0% {
    transform: scale(0);
  }

  70% {
    transform: scale(1.2)
  }

  90% {
    transform: scale(0.9)
  }

  100% {
    transform: scale(1);
  }
`;

const popIn = keyframes`
  0% {
    transform: scale(1.4);
    opacity: 0;
  }

  90% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
`;

const certify = keyframes`
  0% {
    transform: scale(3);
    opacity: 0;
  }

  90% {
    transform: scale(0.8);
    opacity: 1;
  }

  95% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);

  }
`;

export const JobCardStyle = styled.div`
  position: absolute;
  top: -40px;
  left: -10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(197, 197, 197, 0.3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  animation: ${popOut} 0.5s linear 3s;
  animation-fill-mode: backwards;
  backface-visibility: hidden;
  width: fit-content;

  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    top: -20px;
    right: -10px;
  }
`;

export const JobIcon = styled.div`
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-radius: 5px;
`;

export const JobCardText = styled(VStack)`
  .main {
    font-size: 1.1rem;
  }
`;

export const JobCardPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;

  .price {
    color: ${TextColor.label};
    font-size: 1rem;
  }
`;

export const SellerCardStyle = styled.div`
  position: absolute;
  bottom: -20px;
  right: -10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(197, 197, 197, 0.3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  animation: ${popIn} 0.3s linear 5s;
  animation-fill-mode: backwards;

  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    bottom: 40px;
    right: -10px;
  }
`;

export const SellerCardImage = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  object-fit: cover;

  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    width: 50px;
    height: 50px;
  }
`;

export const SellerDetails = styled.div`
  h4 {
    @media screen and (min-width: ${breakpoints.lg + 1}px) {
      font-size: 16px;
      font-weight: ${fontWeight.bold};
    }
  }

  span {
    font-size: ${fontSize.default.sm}px;
    color: ${TextColor.label};
  }
`;

export const CheckIcon = styled.div`
  animation: ${certify} 0.2s linear ${(props) => props.$delay || "6.5s"};
  animation-fill-mode: backwards;
`;
