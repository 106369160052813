import styled from 'styled-components';
import { animated } from 'react-spring';
import { fontWeight } from '../../../constants/sizes';
import { PlainButton } from '../Button/style';

export const ModalStyle = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => (props.$showBackDrop ? '#00000090' : 'transparent')};
  // backdrop-filter: ${(props) =>
    props.$showBackDrop ? 'blur(2px)' : 'uinset'};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
`;

const modalSizes = {
  md: '448px',
  lg: '628px',
  big: '650px',
  xl: '100%',
};

export const ModalContent = styled.section`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  z-index: 101;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  ${(props) => `max-width: ${modalSizes[props.$size]}`};
  background: white;
  min-height: 50vh;
  position: relative;
`;

export const ModalCloseButton = styled(PlainButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
`;

export const ModalHeader = styled.header`
  flex: 0 1 0%;
  font-size: 1.5rem;
  font-weight: ${fontWeight.bold};
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalBody = styled.div`
  flex: 1 1 0%;
  padding: 1rem 1.5rem;
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1.5rem 1.5rem;
`;
