import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/input';
import { Icon } from '@chakra-ui/icon';
import LockOpenOutlineIcon from 'mdi-react/LockOpenOutlineIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { IconButton } from '@chakra-ui/button';
import { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/layout';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import { getPasswordStatus } from '../../../utils/validator';

const passwordConditions = [
  {
    title: '8 - 20 Characters',
    value: 'passLength',
  },
  {
    title: 'At least one lowercase letter',
    value: 'lowercase',
  },
  {
    title: 'At leat one uppercase letter',
    value: 'uppercase',
  },
  {
    title: 'At least one Number',
    value: 'number',
  },
];

export const PasswordInput = ({ onChange, value, setEntry, removeEntry }) => {
  const [show, setShow] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  const handleChange = (e) => {
    onChange('password', e.target.value);
  };

  const validationStatus = getPasswordStatus(value);

  useEffect(() => {
    const isInvalid = Object.values(validationStatus).some(
      (item) => item === false
    );

    if (isInvalid) {
      removeEntry && removeEntry('password');
    } else {
      setEntry && setEntry('password');
    }
  }, [validationStatus, setEntry, removeEntry]);

  return (
    <Box mb="6">
      <InputGroup size="xl">
        <InputLeftElement>
          <Icon as={LockOpenOutlineIcon} color="gray.500" boxSize="1.5rem" />
        </InputLeftElement>
        <Input
          type={show ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
          name="password"
          placeholder="Password"
          maxLength={20}
          onFocus={() => setisFocused(true)}
          onBlur={() => setisFocused(false)}
        />
        <InputRightElement>
          <IconButton
            aria-label={`${show ? 'Hide' : 'Show'} password`}
            icon={
              <Icon
                as={show ? EyeOffIcon : EyeIcon}
                boxSize="icon.md"
                color="gray.400"
              />
            }
            onClick={() => setShow((prev) => !prev)}
            variant="ghost"
          />
        </InputRightElement>
      </InputGroup>

      {isFocused && (
        <VStack as={UnorderedList} mt="4" align="start">
          {passwordConditions.map((item) => (
            <HStack as={ListItem} align="center">
              <Icon
                as={CheckCircleIcon}
                boxSize="1.5rem"
                color={validationStatus[item.value] ? 'text.brand' : 'gray.300'}
              />

              <Text>{item.title}</Text>
            </HStack>
          ))}
        </VStack>
      )}
    </Box>
  );
};
