import React, { useContext, useRef } from 'react';
import { ModalContext } from '.';
import useClickOutside from '../../../hooks/useClickOutside';
import { Inter } from '../../../styling/Text';
import { ModalCloseButton, ModalContent } from './style';

export const CloseButton = () => {
  const { toggleModal } = useContext(ModalContext);

  return <ModalCloseButton onClick={toggleModal}>Close</ModalCloseButton>;
};

function Content({ children, closeOnClickOutside = true, size = 'md' }) {
  const { isOpen, toggleModal } = useContext(ModalContext);
  const clickRef = useRef(null);

  useClickOutside(clickRef, toggleModal, isOpen && closeOnClickOutside);

  return (
    <ModalContent $size={size} ref={clickRef}>
      <Inter>{children}</Inter>
    </ModalContent>
  );
}

export default Content;
