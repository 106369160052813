import * as actions from './store/actions';

import { saveCurrencyToLocal } from '../../utils/currency';
import { requestApi, requestHandler } from '../../utils/api';
import { API_SUCCESS } from '../../constants/constants';
import { dependencyStore } from '../../hooks/useDependencies';

export function setDependency() {
  return async (dispatch) => {
    const { data, type } = await requestApi('get', '/resources');

    if (type === API_SUCCESS) {
      dispatch(actions.setDependency(data));
      dependencyStore.setItem('data', data);
    }
  };
}

export function selectedCurrencyReq(cur) {
  saveCurrencyToLocal(cur);
  return (dispatch) => dispatch(actions.selectedCurrency(cur));
}

export function setCountriesReq(val) {
  return (dispatch) => dispatch(actions.setCountries(val));
}

export function setBanksReq(val) {
  return (dispatch) => dispatch(actions.setBanks(val));
}

export function setDetailsReq(payload, val) {
  return (dispatch) => dispatch(actions.setDetails(payload, val));
}

export function getConversionRates(id) {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'get',
      `/currencies/net-conversions/get-rates/${id}`
    );

    if (type === API_SUCCESS && data) {
      dispatch(actions.setCurrencyRates(data.net_conversion_rates));
    }
  };
}

export function getTopRatedServices() {
  return async (dispatch) => {
    const { data, type } = await requestHandler({
      path: '/services/top-rated',
    });

    if (type === API_SUCCESS && data) {
      dispatch(actions.setTopServices(data.top_services));
    }
  };
}
