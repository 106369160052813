import { Center, Heading, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HStack, VStack } from '../../../../../components/library/layout/Stack';
import breakpoints from '../../../../../constants/breakpoints';
import { ElementColor, TextColor } from '../../../../../constants/Colors';
import { DesktopOnly } from '../../../../../styling/breakpoints';
import makeResponsiveComponent from '../../../../../styling/responsive';
import { Bold } from '../../../../../styling/Text';
import { HomeSection } from '../components/HomeSection';

const processText = [
  {
    id: 1,
    title: 'Find the Right Freelance Professional',
    content:
      'Post your task or search and chat real-time with your preferred talent',
  },
  {
    id: 2,
    title: 'Hire the Right Freelance Professional',
    content:
      'Receive quotes from interested freelancers, compare and hire the best fit',
  },
  {
    id: 3,
    title: 'Work Efficiently with Your Freelancer',
    content:
      'Provide inputs, chat real-time, track progress with our work management tools',
  },
  {
    id: 4,
    title: 'Pay with Ease and Assurance',
    content:
      'Review and approve freelancer’s payment only when you are 100% satisfied',
  },
];

const ruleStyle = `
  color: ${TextColor.green};
`;

const ResponsiveText = makeResponsiveComponent(
  [
    {
      constraint: 'max',
      width: breakpoints.lg,
      rules: ruleStyle,
    },
  ],
  'span'
);

const ProcessTextGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${breakpoints.lg}px) {
    grid-template-columns: 1fr 1fr;
    gap: 4rem 7rem;
  }
`;

const TextNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  background: ${ElementColor.primaryTrns};
  color: ${TextColor.green};
`;

const ProcessText = ({ text }) => {
  return (
    <HStack spacing="1rem" alignItems="flex-start">
      <TextNumber>
        <Bold>{text.id}</Bold>
      </TextNumber>
      <VStack spacing="0.5rem" alignItems="flex-start">
        <Bold>{text.title}</Bold>
        <p>{text.content}</p>
      </VStack>
    </HStack>
  );
};

function Process() {
  return (
    <HomeSection>
      <Heading size="xl" mb="8">
        Get your Freelance Work Done{' '}
        <DesktopOnly as="span">
          {' '}
          on
          <Text color="text.brand" as="span">
            {' '}
            TERAWORK
          </Text>
        </DesktopOnly>
        <br />
        <ResponsiveText>with Ease</ResponsiveText>
      </Heading>
      <ProcessTextGrid>
        {processText.map((text) => (
          <ProcessText text={text} key={text.id} />
        ))}
      </ProcessTextGrid>
      <Center display={{ base: 'flex', lg: 'block' }}>
        <Button as={Link} variant="outline" to="/how-it-works">
          Learn More &rarr;
        </Button>
      </Center>
    </HomeSection>
  );
}

export default Process;
