import shortid from "shortid";
import { v4 as uuid } from "uuid";
import { readMessageHandler } from "../modules/buyer/pages/Chat/service";
import { setMessage, setActiveChats } from "../modules/buyer/pages/Chat/store";
import { setApplication } from "../modules/auth/store";
import store from "../store";
import { setAdminConv } from "../modules/buyer/pages/Chat/store/actions";

export const generateConversationPayload = ({
  sellerId,
  sellerName,
  userDetails,
  avatar,
}) => {
  const tempId = uuid(); //temporary conversation Id

  return {
    conversationId: tempId[0], //Return only one letter so backend will create new convo
    PK: `CONV#${tempId}`,
    userType: "Buyer",
    projects: 0,
    userId: sellerId,
    avatarId: sellerId,
    senderName: userDetails.username,
    senderType: "Buyer",
    senderId: userDetails.id,
    receiverName: sellerName,
    receiverType: "Seller",
    receiverId: sellerId,
    members: [sellerName, userDetails.username],
    status: "Open",
    name: sellerName,
    userName: userDetails.username,
    createdAt: Date.now(),
    title: sellerName,
    timestamp: Date.now(),
    lastMessageTime: Date.now(),
    newMessage: false,
    profileImage: avatar,
    newUser: true,
  };
};

/**
 * Initiates conversation with a seller
 * checks if a conversation exists or not and returns a payload
 */
export const contactSeller = (seller, userDetails, id, sellerName) => {
  const sellerId = id || seller.packages[0]?.seller_id;
  const username = sellerName || seller.seller.display_name;
  const { chat } = store.getState();
  const avatar = seller.logo_or_profile_pic || seller.avatar;

  const sellerConvo = chat.conversations
    .filter((conv) => conv.userType.toLowerCase() === "buyer")
    .find((conv) => conv.members.includes(username) && !conv.workstoreId);

  //if seller exist in conversations lists, return the conversation and payload
  if (sellerConvo) {
    return {
      newMessage: false,
      params: {
        ...sellerConvo,
        name: username,
        profileImage: avatar,
        avatarId: sellerId,
      },
      messages: chat.messages,
      conversations: chat.conversations,
      socket: chat.socket,
      activeChat: chat.activeChat,
    };
  }

  const conversationPayload = generateConversationPayload({
    sellerId,
    sellerName: username,
    userDetails,
    avatar,
  });

  return {
    newMessage: true,
    params: conversationPayload,
    messages: chat.messages,
    conversations: chat.conversations,
  };
};

export const checkConversation = (
  receiverName = "",
  senderType = "buyer" || "seller",
  chatDetails = {}
) => {
  const { chat } = store.getState();
  const { workstoreId, jobOrder, ...rest } = chatDetails;

  //check if a conversation exist that isn't a workstore convo
  const existingChat = chat.conversations.find(
    (conv) =>
      conv.members.includes(receiverName) &&
      !conv.workstoreId &&
      senderType.toLowerCase() === conv.userType.toLowerCase()
  );

  if (existingChat) {
    return {
      ...existingChat,
      name: chatDetails.name,
      profileImage: chatDetails.profileImage,
    };
  }

  const tempConvoId = shortid.generate();

  return {
    ...rest,
    projects: 0,
    status: "Open",
    name: receiverName,
    title: receiverName,
    createdAt: Date.now(),
    conversationId: tempConvoId[0],
    PK: `CONV#${tempConvoId}`,
    timestamp: Date.now(),
    lastMessageTime: Date.now(),
    newMessage: false,
    newUser: true,
    newConvo: true,
    messages: chat.messages,
    conversations: chat.conversations,
  };
};

export const setActiveChat = (chatData) => {
  return (dispatch) => {
    const state = store.getState();
    let { activeChat, conversations, messages, socket, adminConv } = state.chat;

    if (activeChat.isAdminReceiver || chatData.isAdminReceiver) {
      dispatch(
        setAdminConv({
          ...adminConv,
          newMessage: false,
        })
      );

      socket.emit("adminChatUpdateLastSeen", {
        conversationId: adminConv.id,
      });
    }

    if (activeChat.conversationId) {
      //Leave the current chat on the socket
      socket.emit("leaveChat", {
        conversationId: activeChat.id || activeChat.conversationId,
      });
    }

    dispatch(readMessageHandler(activeChat?.conversationId, conversations)); // set existing chat as read

    let message = messages?.[chatData.conversationId];
    dispatch(setActiveChats(chatData));
    dispatch(setMessage(message));

    const msgStruct = {
      action: chatData?.isAdminReceiver ? "getChatMessages" : "getMessages",
      conversationId: chatData?.conversationId,
    };

    dispatch(readMessageHandler(chatData?.conversationId, conversations)); //Set new chat as read

    //Don't fetch messages for new conversations
    if (chatData?.conversationId.length > 1) {
      socket.emit(msgStruct.action, msgStruct);
    }
  };
};

export const clearActiveChat = () => {
  return (dispatch) => {
    dispatch(setActiveChats({}));
    dispatch(setMessage([]));
  };
};

export const changeChatType = (type) => {
  return (dispatch) => {
    if (type) {
      dispatch(setApplication(type));
    }
  };
};

export const replyWorkstore = (conversations, buyerName, workstoreId) => {
  return conversations.find((conv) => {
    return (
      conv.members.includes(buyerName) &&
      conv.userType === "Seller" &&
      conv.workstoreId === workstoreId
    );
  });
};

export const getConversationById = (id, workstoreId) => {
  const { chat } = store.getState();
  const { conversations = [] } = chat;

  return conversations.find(
    (conv) => conv.id === id && +conv.workstoreId === +workstoreId
  );
};

export const renderTooltipText = ({ status, senderName, username }) => {
  if (status === "disapproved") {
    return senderName === username
      ? "Message not delivered"
      : "Message declined";
  } else {
    return "Message under review";
  }
};
