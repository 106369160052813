import { lazy } from 'react';
import { DefaultLayout } from '../../../../components/layout/DefaultLayout';
import OnboardingCompletion from './Completion';

const SellerAccount = lazy(() => import('./SellerAccount'));
const BasicInfo = lazy(() => import('./SellerAccount/BasicInfo'));
const WorkSettings = lazy(() => import('./WorkSettings'));
const PersonalDetails = lazy(() => import('./PersonalDetails'));
const ProfileBuilding = lazy(() => import('./ProfileBuilding'));
const SelectPayment = lazy(() => import('./SellerAccount/SelectPayment'));
const PaymentInfo = lazy(() => import('./SellerAccount/PaymentInfo'));
const AccountType = lazy(() => import('./SellerAccount/RegistrationType'));
const ContactBeforeOrder = lazy(() =>
  import('./WorkSettings/ContactBeforeOrder')
);
const NoticePeriod = lazy(() => import('./WorkSettings/NoticePeriod'));
const QuoteValidity = lazy(() => import('./WorkSettings/QuoteValidity'));
const DeliveryTiming = lazy(() => import('./WorkSettings/DeliveryTime'));
const WorkCancellation = lazy(() => import('./WorkSettings/WorkCancellation'));
const WorkNotInterested = lazy(() => import('./WorkSettings/NotInterested'));

const ProfilePicture = lazy(() => import('./PersonalDetails/ProfilePicture'));
const About = lazy(() => import('./PersonalDetails/About'));
const Education = lazy(() => import('./PersonalDetails/Education'));
const SocialLinks = lazy(() => import('./PersonalDetails/SocialLinks'));

const SelectCategory = lazy(() => import('./ProfileBuilding/Category'));
const SelectService = lazy(() => import('./ProfileBuilding/SelectService'));
const JobTitle = lazy(() => import('./ProfileBuilding/JobTitle'));
const Title = lazy(() => import('./ProfileBuilding/Title'));
const Description = lazy(() => import('./ProfileBuilding/Description'));
const CreatePackages = lazy(() => import('./ProfileBuilding/CreatePackages'));
const WorkDelivery = lazy(() => import('./ProfileBuilding/Delivery'));
const Skills = lazy(() => import('./ProfileBuilding/Skills'));
const Media = lazy(() => import('./ProfileBuilding/Media'));

const OnboardingIndex = lazy(() => import('./index.jsx'));

export const onboardingRoutes = [
  {
    path: '/services/onboarding',
    component: OnboardingIndex,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/seller-account',
    component: SellerAccount,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/select-payment',
    component: SelectPayment,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/payment-info',
    component: PaymentInfo,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/account-type',
    component: AccountType,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/work-settings',
    component: WorkSettings,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/contact-before-order',
    component: ContactBeforeOrder,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/notice-period',
    component: NoticePeriod,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/quote-validity',
    component: QuoteValidity,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/delivery-time',
    component: DeliveryTiming,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/work-cancellation',
    component: WorkCancellation,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/work-not-interested',
    component: WorkNotInterested,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/personal-details',
    component: PersonalDetails,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/profile-picture',
    component: ProfilePicture,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/about',
    component: About,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/education',
    component: Education,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/social-links',
    component: SocialLinks,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/selling-profile',
    component: ProfileBuilding,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/select-category',
    component: SelectCategory,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/select-service/:id',
    component: SelectService,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/job-title',
    component: JobTitle,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/title',
    component: Title,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/description',
    component: Description,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/packages',
    component: CreatePackages,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/delivery',
    component: WorkDelivery,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/skills',
    component: Skills,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/media',
    component: Media,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/basic-info',
    component: BasicInfo,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
  {
    path: '/services/onboarding/complete',
    component: OnboardingCompletion,
    exact: true,
    auth: true,
    role: 'Sellers',
    layout: DefaultLayout,
  },
];
