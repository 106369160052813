import React from 'react';
import SalesIQ from '../../../../ZohoChatWidget';

import { DesktopOnly } from '../../../../styling/breakpoints';
import { Enterprise } from './components/Enterprise';
import Partner from './components/Partners';
import { Businesses } from './sections/Businesses';
import Categories from './sections/Categories';
import Hero from './sections/Hero';
import { HomeServices } from './sections/HomeServices';
import MobileApp from './sections/MobileApp';
import Process from './sections/Process';
import Testimonials from './sections/Testimonials';
import { TopServices } from './sections/TopServices';
import HomeWorkStore from './sections/WorkStore';

export default function Page() {
  return (
    <div className="inter">
      <Hero />
      <DesktopOnly>
        <Partner />
      </DesktopOnly>
      <HomeServices />
      <Businesses />
      <HomeWorkStore />
      <Testimonials />
      <Categories />
      <Process />
      <Enterprise />
      <MobileApp />
      <TopServices title="Top Services" />
      <SalesIQ />
    </div>
  );
}
