import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import breakpoints from "../../../../constants/breakpoints";
import { ElementColor, TextColor } from "../../../../constants/Colors";

const TopCategories = [
  {
    title: "Tech",
    slug: "technology-and-programming",
    id: 6,
  },
  {
    title: "Writing",
    slug: "writing-and-communications",
    id: 5,
  },
  {
    title: "Creative Designs",
    slug: "art-and-creative-designs",
    id: 1,
  },
  {
    title: "Audio",
    slug: "audio-services",
    id: 9,
  },
  {
    title: "Video",
    slug: "video-and-animation",
    id: 2,
  },
  {
    title: "Digital Marketing",
    slug: "web-design-and-digital-marketing",
    id: 7,
  },
  {
    title: "Business",
    slug: "business-consulting-and-professional-services",
    id: 8,
  },
  {
    title: "Events",
    slug: "events-planning-and-vendors",
    id: 3,
  },
  {
    title: "Lifestyle",
    slug: "fashion-and-lifestyle",
    id: 10,
  },
];

const StackStyle = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0.5rem 1.5rem;
  /* border-top: 1px solid ${ElementColor.darkGrey}; */
  border-bottom: 1px solid ${ElementColor.darkGrey};

  & > * + * {
    margin-left: 50px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 0.5rem 6rem;
  }
`;

const HeaderLinks = styled(Link)`
  white-space: nowrap;
  font-size: 1rem;
  transition: 0.3s all ease;

  &:hover {
    color: ${TextColor.green};
  }
`;

function HeaderCategories() {
  return (
    <StackStyle className="no-scrollbar">
      {TopCategories.map((cat) => (
        <HeaderLinks to={`/category/${cat.slug}`} key={cat.id}>
          {cat.title}
        </HeaderLinks>
      ))}
    </StackStyle>
  );
}

export default HeaderCategories;
