import styled from 'styled-components';
import breakpoints from '../../../constants/breakpoints';
import { ElementColor, TextColor } from '../../../constants/Colors';
import { fontSize, fontWeight } from '../../../constants/sizes';

export const JobTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: ${fontWeight.medium};
  transition: 0.3s ease all;

  @media screen and (min-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.default.normal}px;
  }
`;

export const MiniJobCard = styled.div`
  border-radius: 10px;
  min-height: 200px;
  border: 1px solid #dddddd;

  @media screen and (min-width: ${breakpoints.lg}px) {
    box-shadow: 0px 15px 22px rgba(137, 135, 135, 0.2);
    border: none;
  }

  &:hover {
    ${JobTitle} {
      color: ${TextColor.green};
    }
  }
`;

export const CardTop = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 1.5rem;
  min-height: 80%;
  display: flex;
`;

export const CardBottom = styled.div`
  padding: 1rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const JobIcon = styled.div`
  margin-right: 1rem;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  color: ${ElementColor.darkGrey};
`;

export const JobLinkStyle = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 2rem;
  }
`;

export const JobDescription = styled.div`
  max-width: 80%;
  min-height: 80px;
  font-size: 1rem;
  line-height: 1.5;
`;
