import { requestApi } from "./api";

export const isPasswordValid = (value) => {
  let errorMessage = "";

  if (value.length < 8) {
    errorMessage = "Password must be at least 8 characters";
    return { valid: false, msg: errorMessage };
  } else if (value.search(/[a-z]/) < 0) {
    errorMessage = "Password must contain at least one lowercase letter";
    return { valid: false, msg: errorMessage };
  } else if (value.search(/[A-Z]/) < 0) {
    errorMessage = "Password must contain at least one uppercase letter";
    return { valid: false, msg: errorMessage };
  } else if (value.search(/[0-9]/) < 0) {
    errorMessage = "Password must contain at least one digit";
    return { valid: false, msg: errorMessage };
  }

  return { valid: true };
};

export const getPasswordStatus = (password = "") => {
  const result = {
    uppercase: false,
    passLength: false,
    lowercase: false,
    number: false,
  };

  if (password.length > 7 && password.length < 21) {
    result.passLength = true;
  }
  if (password.match(/[A-Z]/)) {
    result.uppercase = true;
  }
  if (password.match(/[a-z]/)) {
    result.lowercase = true;
  }
  if (password.match(/[0-9]/)) {
    result.number = true;
  }

  return result;
};

export const isEmailValid = (value) => {
  const regXp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regXp.test(value.toLowerCase());
};

export const checkExisting = async (item, value) => {
  const postBody = {
    [item]: value.toLowerCase(),
  };

  const { type } = await requestApi("post", "check", postBody);

  if (type === "success") {
    return false;
  } else if (type === "error") {
    return true;
  }
};

export const isTextValid = (value) => {
  return value.length > 1;
};

export const isEmpty = (item) => {
  if (!item) {
    return true;
  }
  return item.length < 1;
};

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
}
