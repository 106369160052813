import React, { useState } from "react";
import { footerLinks } from "..";
import { MobileAndTablet } from "../../../../../styling/breakpoints";
import { InstallApp } from "../InstallApp";
import LinkGroup from "../LinkGroup";
import { MobileFooterWrapper } from "./style";

function MobileFooter() {
  const [activeGroup, setActiveGroup] = useState("");

  const makeActive = (index) => {
    if (index === activeGroup) {
      setActiveGroup("");
      return;
    }
    setActiveGroup(index);
  };

  return (
    <MobileAndTablet>
      <MobileFooterWrapper spacing="2rem">
        {footerLinks.map((item, index) => (
          <LinkGroup
            {...item}
            key={item.title}
            index={index}
            makeActive={makeActive}
            activeGroup={activeGroup}
          />
        ))}
        <InstallApp />
      </MobileFooterWrapper>
    </MobileAndTablet>
  );
}

export default MobileFooter;
