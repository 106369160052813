import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";
import { SearchForm, SearchWrapper } from "./style";
import MobileSearch from "./mobile";
import DesktopSearch from "./desktop";
import { HStack, VStack } from "../../library/layout/Stack";
import { DesktopOnly } from "../../../styling/breakpoints";
import Pill from "../../library/Pill";
import { replaceSpaces } from "../../../utils/transfomer";
import { GreyText } from "../../../styling/Text";
import shortid from "shortid";
import Suggestions from "../Suggestions";
import { getSuggestions } from "../../../utils/search";
import { useLocation } from "react-router-dom";

const commonSearchQuery = [
  "Writing",
  "Product Design",
  "Web Design",
  "Business",
];

function Search({
  defaultQuery = "",
  onSearch,
  onChange,
  showPopularServices = false,
  ...rest
}) {
  const { autoFocus = false } = rest;
  const [query, setQuery] = useState(defaultQuery);
  const hasMounted = useRef(false);
  const hasSearched = useRef(true);
  const [suggestions, setSuggestions] = useState({ data: null, show: false });
  const { search } = useLocation();
  const { query: urlQuery } = parse(search);

  const toggleSuggest = () => {
    setSuggestions((prev) => ({ ...prev, show: !prev.show }));
  };

  const handleSuggest = useCallback(async (text) => {
    const data = await getSuggestions(text);

    //only suggest if user is still typing or hasn't searched
    if (!hasSearched.current) {
      setSuggestions({ show: true, data });
    }
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      onChange && onChange(query);
      if (query) {
        const debounce = setTimeout(() => handleSuggest(query), 1000);

        return () => clearTimeout(debounce);
      } else {
        setSuggestions({ show: false, data: null });
      }
    }

    hasMounted.current = true;
  }, [query, onChange, handleSuggest]);

  useEffect(() => {
    setQuery(urlQuery);
  }, [urlQuery]);

  const handleChange = (e) => {
    hasSearched.current = false;
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    hasSearched.current = true;
    onSearch && onSearch(query);
    setSuggestions({ show: false, data: null });
  };

  const onSuggest = (text) => {
    onSearch(text);

    hasSearched.current = true;
    setSuggestions({ show: false, data: null });
    setQuery(text);
  };

  const onBlur = (e) => {
    hasSearched.current = true;
  };

  return (
    <SearchWrapper>
      <VStack isFullWidth spacing="2rem">
        <SearchForm onSubmit={handleSubmit}>
          <MobileSearch
            autoFocus={autoFocus}
            text={query || ""}
            onChange={handleChange}
            onBlur={onBlur}
          />
          <DesktopSearch text={query} onChange={handleChange} onBlur={onBlur} />
        </SearchForm>
        <DesktopOnly>
          {showPopularServices && (
            <HStack spacing="1rem">
              <GreyText>Popular Services:</GreyText>
              <HStack>
                {commonSearchQuery.map((query) => (
                  <Pill
                    link={`/profile-search?query=${replaceSpaces(query, "+")}`}
                    key={shortid.generate()}
                  >
                    {query}
                  </Pill>
                ))}
              </HStack>
            </HStack>
          )}
        </DesktopOnly>
      </VStack>

      <Suggestions
        suggestions={suggestions.data}
        show={suggestions.show}
        toggle={toggleSuggest}
        className="search-suggest"
        onClick={onSuggest}
      />
    </SearchWrapper>
  );
}

Search.propTypes = {
  defaultQuery: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default Search;
