import swal from "@sweetalert/with-react";
import { toast } from "react-toastify";
import Auth from "./Auth";
import { requestApi } from "./api";
import { API_SUCCESS } from "../constants/constants";

export const isSeller = () => {
  const auth = new Auth();
  const userGroup = auth.getDataFromToken("cognito:groups");

  return userGroup.includes("Sellers");
};

export const sellerIsPrivate = (seller) => {
  return !seller?.public_profile_status;
};

export const becomeSellerPrompt = async () => {
  const becomeSeller = await swal({
    title: "",
    text: "Become a Seller! Sure you want to do this? Please click OK to start seller registration.",
    buttons: {
      confirm: {
        text: "Ok",
        value: true,
        className: "butn butn--green",
      },
      cancel: {
        text: "Cancel",
        className: "butn butn--blue",
        visible: true,
        value: false,
      },
    },
  });

  return becomeSeller;
};

export const createSeller = async () => {
  const auth = new Auth();

  toast("Creating Seller Account...", {
    hideProgressBar: true,
  });

  //Create Seller Account for BUyer
  let { type } = await requestApi("post", "buyer/become-a-seller", {}, true);

  //Add Buyer to Seller Group on Cognito
  if (type === API_SUCCESS) {
    const userEmail = auth.getDataFromToken("email");
    let { type } = await requestApi(
      "post",
      "sellersauth",
      { username: userEmail },
      true
    );

    if (type === API_SUCCESS) {
      auth.refreshSession(() => (window.location = "/services/onboarding"));
    }
  }
};

export const initiateBecomeASeller = async () => {
  const becomeSeller = await becomeSellerPrompt();
  becomeSeller && createSeller();
};

export const sellerApp = async () => {
  if (!isSeller()) {
    initiateBecomeASeller();
  } else {
    const win = window.open("/services", "_blank");
    win.focus();
  }
};

export const checkOnboardingComplete = (sellerInfo, stage) => {
  if (sellerInfo[stage]) {
    return true;
  }
};

export const getCompletionStage = (sellerInfo) => {
  if (
    sellerInfo.onboarding_complete &&
    sellerInfo.onboarding_complete2 &&
    sellerInfo.onboarding_complete3 &&
    sellerInfo.onboarding_complete4
  ) {
    return "portfolio";
  } else if (
    sellerInfo.onboarding_complete &&
    sellerInfo.onboarding_complete2 &&
    sellerInfo.onboarding_complete3 &&
    !sellerInfo.onboarding_complete4
  ) {
    return "onboarding/title";
  } else if (
    sellerInfo.onboarding_complete &&
    sellerInfo.onboarding_complete2 &&
    !sellerInfo.onboarding_complete3
  ) {
    return "onboarding/personal-details";
  } else if (
    sellerInfo.onboarding_complete &&
    !sellerInfo.onboarding_complete2
  ) {
    return "onboarding/contact-before-order";
  } else {
    return "onboarding";
  }
};
