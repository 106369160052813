import { API_SUCCESS } from '../../../../constants/constants';
import { requestApi } from '../../../../utils/api';
import { setDiscountamout, setVoucher } from '../../store/action';

export const applyDiscount = ({ code, amount, currency_id }) => {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      'post',
      '/buyer/cart/get-discount',
      {
        sub_total: amount,
        voucher_code: code,
        currency_id,
        platform_type: 'WEB',
      }
    );

    if (type === API_SUCCESS) {
      dispatch(setDiscountamout(data.discount_amount));
      dispatch(setVoucher(data.discount_code));
      return [null, data.discount_amount];
    }

    return [data];
  };
};
