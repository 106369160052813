import {
  Box,
  Button,
  Center,
  Link as ChakraLink,
  Flex,
  Heading,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { HomeSection } from '../components/HomeSection';

const topServices = [
  {
    title: 'Corporate Branding and Logo Design',
    href: '/category/service/corporate-branding-and-logo-design',
  },
  {
    title: 'Flyers, Posters and Publicity Material',
    href: '/category/service/flyers-posters-and-publicity-material',
  },
  {
    title: 'Illustrations and Cartoons',
    href: '/category/service/illustrations-and-cartoons',
  },
  {
    title: 'Graphic Design',
    href: '/category/service/graphic-design',
  },
  {
    title: 'Business Plans and Strategy Documents',
    href: '/category/service/business-plans-and-strategy-documents',
  },
  {
    title: 'Market Research and Analysis',
    href: '/category/service/market-research-and-analysis',
  },
  {
    title: 'Legal Services',
    href: '/category/service/legal-services',
  },
  {
    title: 'Virtual Assistance',
    href: '/category/service/virtual-assistance',
  },
  {
    title: 'Accounting, Bookkeeping and Audit Service',
    href: '/category/service/accounting-bookkeeping-and-audit-service',
  },
  {
    title: 'Data Analysis and Interpretation',
    href: '/category/service/data-analysis-and-interpretation',
  },
  {
    title: 'Computer Software Development',
    href: '/category/service/computer-software-development',
  },
  {
    title: 'Mobile App Development',
    href: '/category/service/mobile-app-development',
  },
  {
    title: 'Translation and Transcription',
    href: '/category/service/translation-and-transcription',
  },
  {
    title: 'Database Management',
    href: '/category/service/database-management',
  },
  {
    title: 'Cloud Computing Services',
    href: '/category/service/cloud-computing-services',
  },
  {
    title: 'Remote Technical Support',
    href: '/category/service/remote-technical-support',
  },
  {
    title: '2D and 3D Animation and Character Modeling',
    href: '/category/service/2d-and-3d-animation-and-character-modeling',
  },
  {
    title: 'Promotional Videos and Short Ads',
    href: '/category/service/promotional-videos-and-short-ads',
  },
  {
    title: 'Whiteboard Illustrations and Explainer Videos',
    href: '/category/service/whiteboard-illustrations-and-explainer-videos',
  },
  {
    title: 'Website Design and Development',
    href: '/category/service/website-design-and-development',
  },
  {
    title: 'User Interface and User Experience Designs',
    href: '/category/service/user-interface-and-user-experience-designs',
  },
  {
    title: 'Digital Marketing',
    href: '/category/service/digital-marketing',
  },
  {
    title: 'Social Media Marketing',
    href: '/category/service/social-media-marketing',
  },
  {
    title: 'Web Content Creation',
    href: '/category/service/web-content-creation',
  },
  {
    title: 'Profile, CV and Cover Letter Writing',
    href: '/category/service/profile-cv-and-cover-letter-writing',
  },
  {
    title: 'Ghost Writing',
    href: '/category/service/ghost-writing',
  },
  {
    title: 'Research and Project Writing',
    href: '/category/service/research-and-project-writing',
  },
  {
    title: 'Copy Editing and Proofreading',
    href: '/category/service/copy-editing-and-proofreading',
  },
  {
    title: 'Creative Writing (Poem, Script, Songs, etc)',
    href: '/category/service/creative-writing-poem-script-songs-etc',
  },
];

export const TopServices = ({ title }) => {
  const divider = useBreakpointValue({
    base: '1',
    lg: '3',
  });

  const { isOpen: isExpanded, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <HomeSection mt={{ base: '-10', md: '-20' }}>
      <Heading size="xl" mb={{ base: '4', md: '12' }}>
        {title}
      </Heading>

      <Flex
        flexWrap="wrap"
        ml="-30px"
        maxH={{
          base: isExpanded ? 'auto' : '500px',
          md: 'full',
        }}
        transition="600ms all"
        overflow="hidden"
        pos="relative"
      >
        {topServices.map((serv) => (
          <ChakraLink
            variant="link"
            flex="0 0 100%"
            flexBasis={`${100 / divider}%`}
            as={Link}
            to={serv.href}
            key={serv.title}
            padding="5px 0 5px 30px"
            color="brand.dark"
            isTruncated
          >
            {serv.title}
          </ChakraLink>
        ))}

        <Box
          pos="absolute"
          h={{
            base: 'calc(35px + 20%)',
            md: 'calc(100px + 30%)',
          }}
          d={{ base: 'unset', md: 'none' }}
          bottom={0}
          left={0}
          w="100%"
          opacity={isExpanded ? 0 : 1}
          transition="250ms opacity"
          background="linear-gradient(180deg, rgba(11,11,11,0) 0%, #fff 66%)"
        />
      </Flex>

      <Center pt="4" display={{ base: 'flex', md: 'none' }}>
        <Button variant="outline" onClick={onToggle}>
          See {isExpanded ? 'less' : 'more'} services
        </Button>
      </Center>
    </HomeSection>
  );
};
