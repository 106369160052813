import * as actionTypes from './actionTypes';

export const setAuth = (payload) => {
  return {
    type: actionTypes.SET_AUTH,
    payload,
  };
};

export const setApplication = (payload) => {
  return {
    type: actionTypes.SET_APLLICATION,
    payload,
  };
};

export const authLogin = (payload) => {
  return {
    type: actionTypes.AUTH_LOGIN,
    payload,
  };
};

export const authSignup = (payload) => {
  return {
    type: actionTypes.AUTH_SIGNUP,
    payload,
  };
};

export const setSignUp = (payload) => {
  return {
    type: actionTypes.SET_SIGN_UP,
    payload,
  };
};

export const setSignupUrl = (payload) => {
  return {
    type: actionTypes.SET_SIGN_UP_URL,
    payload,
  };
};

export const toggleAuthModal = (payload) => {
  return {
    type: actionTypes.TOGGLE_AUTH_MODAL,
    payload,
  };
};

export const setAuthDetails = (payload) => {
  return {
    type: actionTypes.SAVE_AUTH_DETAILS,
    payload,
  };
};
