import { parse } from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cookies } from '../utils/cookies';
import { PREMIUM_COOKIE_KEY } from '../constants/constants';

export const usePremiumInterceptor = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { isPremium } = parse(search);

    const discountExpiry = new Date();
    discountExpiry.setTime(discountExpiry.getTime() + 30 * 24 * 60 * 60 * 1000);

    if (!!isPremium) {
      cookies.setItem(PREMIUM_COOKIE_KEY, true, discountExpiry);
    }
  }, [search]);
};
