import React from "react";
import styled from "styled-components";
import { ElementColor } from "../../../../../constants/Colors";

import AppMockupMobile from "../../../../../assets/img/AppMockup.webp";
import breakpoints from "../../../../../constants/breakpoints";

import { InstallApp } from "../../../../../components/layout/new/Footer/InstallApp";
import { Box, Center, Heading, Image, VStack } from "@chakra-ui/react";
import { HomeSection } from "../components/HomeSection";

const SectionBlock = styled.div`
  background: ${ElementColor.primaryTrns};
  position: relative;
  padding: 2rem;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 30px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    flex-direction: row-reverse;
    padding: 2rem 5rem;
  }
`;

function MobileApp() {
  return (
    <HomeSection mt={{ base: "12", lg: 0 }}>
      <SectionBlock>
        <Box pos="relative" maxW="50%">
          <Center
            pos="relative"
            h={{ base: "auto", md: "500px" }}
            mt={{ base: "-100px", lg: "0" }}
          >
            <Image src={AppMockupMobile} alt="app" maxW="full" maxH="full" />
          </Center>
        </Box>

        <VStack spacing="1rem" align="start" maxW="lg" flex={1}>
          <Heading size="3xl">Hire & manage talent on the go</Heading>
          <p>Download the app and connect with talent globally.</p>
          <Box ml="-2" pt="4" w="full">
            <InstallApp
              showTitle={false}
              flexDir="row"
              // flexWrap="wrap"
              justifyContent={{ sm: "center", lg: "flex-start" }}
            />
          </Box>
        </VStack>
      </SectionBlock>
    </HomeSection>
  );
}

export default MobileApp;
