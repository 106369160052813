import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountDetailsIcon from 'mdi-react/AccountDetailsIcon';
import AtIcon from 'mdi-react/AtIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import { Center, Link as ChakraLink } from '@chakra-ui/layout';
import { connect } from 'react-redux';
import { Button } from '@chakra-ui/button';

import MyFormGroup from './MyFormGroup';
import { reCaptchaSiteKey } from '../../../constants/constants';
import Auth from '../../../utils/Auth';
import Notify from '../../../utils/notify';
import {
  setSignUp,
  setSignUpDetailsReq,
  userSignUp,
} from '../../../modules/auth/service';
import { PasswordInput } from './PasswordInput';

const auth = new Auth();

const SignupBody = ({ dispatch, type, endpoint, onSignup }) => {
  const history = useHistory();

  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone_no: '',
    password: '',
    username: '',
    successfulEntries: [],
    submitting: false,
  });

  const updateData = useCallback((newData = {}) => {
    return setData((prevState) => ({ ...prevState, ...newData }));
  }, []);

  const setEntry = useCallback((input) => {
    setData((prevState) => {
      const { successfulEntries } = prevState;

      if (!successfulEntries.includes(input)) {
        const newArr = [...successfulEntries, input];
        return {
          ...prevState,
          successfulEntries: newArr,
        };
      }
      return prevState;
    });
  }, []);

  const removeEntry = useCallback((input) => {
    setData((prevState) => {
      const { successfulEntries } = prevState;
      if (successfulEntries.includes(input)) {
        const filtered = successfulEntries.filter((ent) => ent !== input);

        return {
          ...prevState,
          successfulEntries: filtered,
        };
      }
      return prevState;
    });
  }, []);

  const handleChange = useCallback((name, value) => {
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  }, []);

  const onCheck = useCallback(
    (e) => {
      e.target.checked ? setEntry('terms') : removeEntry('terms');
    },
    [setEntry, removeEntry]
  );

  const captchaChange = useCallback(
    (value) => {
      value ? setEntry('captcha') : removeEntry('captcha');
    },
    [setEntry, removeEntry]
  );

  const signUpSuccess = () => {
    Notify.success('Registration Successful!');
    dispatch(setSignUpDetailsReq({ ...data, type }));
    dispatch(setSignUp(true));

    onSignup
      ? onSignup({ ...data, type })
      : history.replace({
          pathname: '/verify-code',
          state: { ...data, type },
        });
  };

  const cognitoErrorFunction = useCallback(
    (err) => {
      updateData({ submitting: false });
      err && Notify.success(err.message);
      return err;
    },
    [updateData]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateData({ submitting: true });

    let { email, first_name, last_name, password, username } = data;

    const [err] = await userSignUp({ ...data, type });

    if (err) {
      updateData({ submitting: false });

      return;
    } else {
      const lowEmail = email.toLowerCase();
      auth.Signup(
        lowEmail,
        password,
        first_name,
        last_name,
        username,
        type,
        signUpSuccess,
        cognitoErrorFunction
      );
    }
  };

  const { successfulEntries, submitting } = data;
  const numberOfEntriesForSubmission = 7;

  let disabled = successfulEntries.length !== numberOfEntriesForSubmission;

  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <div className="row" style={{ justifyContent: 'center' }}>
        <div className="col-sm-6">
          <MyFormGroup
            type="text"
            label="First Name"
            placeholder="First Name"
            id="first_name"
            inputClassName="form-control"
            handleChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
            leftIcon={AccountCircleIcon}
            maxLength="20"
          />
        </div>
        <div className="col-sm-6">
          <MyFormGroup
            type="text"
            label="Last Name"
            placeholder="Last Name"
            id="last_name"
            inputClassName="form-control"
            handleChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
            leftIcon={AccountCircleIcon}
            maxLength="20"
          />
        </div>
        <div className="col-md-12">
          <MyFormGroup
            type="email"
            label="Email"
            placeholder="Email address"
            id="email"
            inputClassName="form-control"
            handleChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
            leftIcon={AtIcon}
          />
        </div>
        <div className="col-md-12">
          <MyFormGroup
            type="username"
            label="Display Name"
            placeholder="Display Name"
            id="username"
            inputClassName="form-control"
            handleChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
            leftIcon={AccountDetailsIcon}
          />
        </div>
        <div className="col-md-12">
          <MyFormGroup
            type="tel"
            label="Mobile Number"
            placeholder="Mobile Number"
            id="telephone_no"
            inputClassName="form-control"
            handleChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
            leftIcon={PhoneIcon}
            phoneInput
          />
        </div>
        <div className="col-md-12">
          <PasswordInput
            value={data.password}
            onChange={handleChange}
            setEntry={setEntry}
            removeEntry={removeEntry}
          />
        </div>

        <Center mb={6}>
          <div className="recaptcha col-md-12">
            <ReCAPTCHA sitekey={reCaptchaSiteKey} onChange={captchaChange} />
          </div>
        </Center>
        <div
          className="terms-and-policy container text-align-center"
          style={{ textAlign: 'center' }}
        >
          <label style={{ fontSize: '14px' }}>
            <input type="checkbox" name="terms" id="terms" onChange={onCheck} />
            I agree to the{' '}
            <ChakraLink href="/privacy-policy" isExternal>
              Privacy Policy
            </ChakraLink>{' '}
            and{' '}
            <ChakraLink href="/terms-of-service" isExternal>
              Terms of Service
            </ChakraLink>
          </label>
        </div>
        <div className="col-md-12 submit">
          <Button type="submit" isLoading={submitting} isDisabled={disabled}>
            Sign up
          </Button>
        </div>
      </div>
    </form>
  );
};

SignupBody.propTypes = {
  type: PropTypes.string,
  endpoint: PropTypes.string,
  onSignUp: PropTypes.func,
};

export default withRouter(connect()(SignupBody));
