import React from 'react';
import {
  Box,
  Button,
  Heading,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

export const TitledContent = ({
  title,
  description,
  href,
  ctaLabel,
  list,
  listType = OrderedList,
  listSpacing = '2',
}) => {
  return (
    <VStack maxW="lg" align="start" spacing="4">
      <Heading as="h4" size="md">
        {title}
      </Heading>
      <Text>{description}</Text>
      {list && (
        <Box as={listType}>
          {list.map((item) => (
            <ListItem
              key={shortid.generate()}
              listStyleType="inherit"
              listStylePos="inside"
              mb={listSpacing}
            >
              {typeof item === 'string' ? item : list.value}
            </ListItem>
          ))}
        </Box>
      )}
      {href && (
        <Button as={Link} to={href} variant="primary" size="lg" w="full">
          {ctaLabel}
        </Button>
      )}
    </VStack>
  );
};
