import { BrowserTracing } from "@sentry/tracing";
import * as SentryBrowser from "@sentry/browser";

export const sentryConfig = {
  dsn: "https://9a2495f37d3644ba8e0c85466e649828@o1215198.ingest.sentry.io/6356177",
  integrations: [new BrowserTracing(), new SentryBrowser.Replay()],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.5,
  enabled: process.env.NODE_ENV !== "development",
};

export const setSentryUser = (user) => {
  SentryBrowser.setUser(user);
};
