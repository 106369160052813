import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import PageMain from '../../../../../components/common/PageMain/PageMain';
import { YoutubeEmbedModal } from '../../../../../components/YoutubeEmbedModal';
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';

export const OnboardingCheckpoint = ({
  title,
  description,
  videoUrl,
  children,
}) => {
  return (
    <PageMain title={title} description={description}>
      <Flex h="100vh" alignItems="stretch">
        <Box
          flex={3}
          bg="whiteAlpha.900"
          px={{ base: '6', md: '24' }}
          pt={{ base: 10, md: '10' }}
          pb="24"
        >
          <Box h="26px">
            <Image
              src="https://www.terawork.com/images/logo_black.svg"
              alt="terawork logo"
              w="auto"
              h="full"
            />
          </Box>
          <Flex
            flexDir="column"
            justifyContent="space-between"
            h="full"
            pt="28"
          >
            <VStack align="start">
              <Heading as="h1" size="xl">
                {title}
              </Heading>
              <Text>{description}</Text>

              {videoUrl && (
                <YoutubeEmbedModal videoTitle={title} videoUrl={videoUrl}>
                  {({ onOpen }) => (
                    <Button
                      variant="unstyled"
                      display="flex"
                      alignItems="center"
                      textDecor="underline"
                      leftIcon={
                        <Icon
                          as={PlayCircleIcon}
                          color="primary.500"
                          boxSize="1.5rem"
                        />
                      }
                      onClick={onOpen}
                      size="sm"
                      // color="text.label"
                    >
                      Watch The Onboarding Guide
                    </Button>
                  )}
                </YoutubeEmbedModal>
              )}
            </VStack>

            <Box>{children}</Box>
          </Flex>
        </Box>
        <Box
          flex={1}
          background="brand.dark"
          color="whiteAlpha.900"
          p="6"
          pt={{ base: 10, md: '28' }}
          display={{ base: 'none', md: 'block' }}
        >
          <Heading size="md">Onboarding sections</Heading>
        </Box>
      </Flex>
    </PageMain>
  );
};
