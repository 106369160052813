import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Search from '../../../../../components/common/Search';
import { Container } from '../../../../../styling/Layout';
import { replaceSpaces } from '../../../../../utils/transfomer';
import breakpoints from '../../../../../constants/breakpoints';

import HeroImage from './HeroImages';
import { Box, Heading, VStack } from '@chakra-ui/react';

const HeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem 2rem;
  padding: 2rem 0 2rem;

  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    grid-template-columns: 1fr 1fr;
    gap: 0 2rem;
    align-items: center;
    padding: 4rem 0;
  }
`;

const HeroText = styled.h2`
  font-size: 18px;
  line-height: 32px;
`;

export default function Hero() {
  const history = useHistory();

  const onSearch = (text = '') => {
    if (text && text.length > 2) {
      const query = replaceSpaces(text, '+');
      history.push(`/profile-search?query=${query}`);
    }

    return false;
  };

  return (
    <section className="hero">
      <Container>
        <HeroGrid>
          <VStack spacing="4">
            <Heading as="h1" size="4xl" lineHeight="1.3">
              Hire Professional Freelancers for Your Business
            </Heading>
            <HeroText>
              Get proven talents to render services. Hire vetted professionals
              in minutes
            </HeroText>
            <Box pt="4" w="full">
              <Search onSearch={onSearch} />
            </Box>
          </VStack>
          <Box display={{ base: 'none', md: 'block' }}>
            <HeroImage />
          </Box>
        </HeroGrid>
      </Container>
    </section>
  );
}
