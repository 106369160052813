import { useDisclosure } from "@chakra-ui/hooks";
import { AspectRatio } from "@chakra-ui/layout";
import React from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";

export const YoutubeEmbedModal = ({ videoTitle, videoUrl, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent bg="transparent" shadow="none">
          <ModalBody>
            <AspectRatio ratio={16 / 9}>
              <iframe
                title={videoTitle}
                src={videoUrl}
                allowFullScreen
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                width="760px"
              />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
      {children({ onOpen })}
    </>
  );
};
