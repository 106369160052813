import React, { useMemo } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animated } from "@react-spring/web";

import { ElementColor } from "../../../../../constants/Colors";
import { LensDark } from "../../../../common/icons/Lens";
import { SearchIconStyle, RightIconStyle } from "./style";
import { Icon } from "@chakra-ui/icon";
import { useChat } from "../../../../../hooks/useChat";

export const MessageIcon = () => {
  const { getUnreadConversations } = useChat();

  const unreadConversations = useMemo(
    () => getUnreadConversations(),
    [getUnreadConversations]
  );

  return (
    <RightIconStyle $unread={unreadConversations?.length} to="/peer/chats">
      <svg
        width="30"
        height="22"
        viewBox="0 0 30 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.1875 0H2.81252C1.2616 0 0 1.21475 0 2.70808V18.9564C0 20.4498 1.2616 21.6645 2.81252 21.6645H27.1875C28.7384 21.6645 30 20.4498 30 18.9564V2.70808C30 1.21475 28.7384 0 27.1875 0ZM27.1875 1.80537C27.3149 1.80537 27.436 1.83081 27.5468 1.87525L15 12.3458L2.45313 1.87525C2.56391 1.83087 2.68508 1.80537 2.81246 1.80537H27.1875ZM27.1875 19.8591H2.81252C2.29525 19.8591 1.87499 19.4545 1.87499 18.9564V3.78264L14.3857 14.2226C14.5624 14.3698 14.7812 14.443 15 14.443C15.2188 14.443 15.4376 14.3698 15.6143 14.2226L28.125 3.78264V18.9564C28.1249 19.4545 27.7047 19.8591 27.1875 19.8591Z"
          fill="#999999"
        />
      </svg>
    </RightIconStyle>
  );
};

export const CartIcon = ({ count = 0, ...props }) => {
  return (
    <RightIconStyle $unread={count} to="/pending-orders">
      <Icon
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0)">
          <path
            d="M10.0508 20.0413H26.4592C26.8655 20.0413 27.222 19.7726 27.3319 19.3823L30.965 6.66725C31.043 6.39314 30.9896 6.09868 30.8176 5.87092C30.6455 5.64387 30.3777 5.50977 30.092 5.50977H7.95411L7.30489 2.58838C7.21265 2.17236 6.84369 1.87695 6.41797 1.87695H0.908203C0.406326 1.87695 0 2.28328 0 2.78516C0 3.28727 0.406326 3.69336 0.908203 3.69336H5.68904L8.96874 18.4517C8.00378 18.8712 7.32617 19.8317 7.32617 20.9495C7.32617 22.4518 8.54846 23.6741 10.0508 23.6741H26.4592C26.9613 23.6741 27.3674 23.268 27.3674 22.7659C27.3674 22.264 26.9613 21.8577 26.4592 21.8577H10.0508C9.55056 21.8577 9.14258 21.4506 9.14258 20.9495C9.14258 20.4483 9.55056 20.0413 10.0508 20.0413Z"
            fill="#AFAFAF"
          />
          <path
            d="M9.14246 26.3987C9.14246 27.9012 10.3647 29.1233 11.8673 29.1233C13.3696 29.1233 14.5919 27.9012 14.5919 26.3987C14.5919 24.8964 13.3696 23.6741 11.8673 23.6741C10.3647 23.6741 9.14246 24.8964 9.14246 26.3987Z"
            fill="#AFAFAF"
          />
          <path
            d="M21.9182 26.3987C21.9182 27.9012 23.1404 29.1233 24.6428 29.1233C26.1453 29.1233 27.3674 27.9012 27.3674 26.3987C27.3674 24.8964 26.1453 23.6741 24.6428 23.6741C23.1404 23.6741 21.9182 24.8964 21.9182 26.3987Z"
            fill="#AFAFAF"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </Icon>
    </RightIconStyle>
  );
};

function SearchIcon({ toggle, styles }) {
  return (
    <SearchIconStyle
      stackAs="button"
      type="button"
      spacing="0.2rem"
      onClick={toggle}
    >
      <LensDark />
      <animated.span style={styles}>
        <FontAwesomeIcon icon={faChevronDown} color={ElementColor.line} />
      </animated.span>
    </SearchIconStyle>
  );
}

export default SearchIcon;
