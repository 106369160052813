import styled from 'styled-components';
import { HStack } from '../../../../library/layout/Stack';

export const MobileHeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
`;

export const Logo = styled.img`
  width: auto;
  height: 26px;
`;

export const IconGroup = styled(HStack)`
  justify-content: flex-end;
  padding-right: 1rem;
`;
