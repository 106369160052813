import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Box, Flex, Heading, Link, Text, VStack } from '@chakra-ui/layout';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';

import { LandingSection } from './LandingSection';
import { appEnv } from '../../../../../utils/appEnv';

export const ExploreFulltime = () => {
  return (
    <Box bg="bg.purple" color="white" pos="relative">
      <LandingSection>
        <Flex
          flexDir={['column', null, null, 'row']}
          justify="space-between"
          align={['start', null, null, 'center']}
          gap={[12, null, null, 8]}
        >
          <VStack align="start" spacing="4">
            <Heading size="4xl">Explore Full-time Talents</Heading>
            <Text fontSize="xl">
              We have thousands of qualified candidates waiting for you!
            </Text>

            <Button
              variant="link"
              color="white"
              px="0"
              size="xl"
              rightIcon={<Icon as={ArrowRightUpLineIcon} />}
              as={Link}
              isExternal
              href={`${appEnv.enterpriseAppHost}talents`}
            >
              Explore talents
            </Button>
          </VStack>
          <Box
            w={['full', null, '400px', null, '500px']}
            pos={['static', null, null, 'absolute']}
            bottom="0"
            right={{ md: '40px', xl: '80px' }}
            mb={{ base: '-10', md: '-20', lg: '0' }}
          >
            <Image
              w="full"
              h="full"
              src="/images/landing/full-time-talents.webp"
              alt="Full-time talents"
            />
          </Box>
        </Flex>
      </LandingSection>
    </Box>
  );
};
