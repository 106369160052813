import styled from 'styled-components';
import breakpoints from '../constants/breakpoints';
import { DEFAULT_SIZE_UNIT, fontSize, fontWeight } from '../constants/sizes';

export const LargeHeading = styled.h1`
  font-size: ${fontSize.mobile.xl}${DEFAULT_SIZE_UNIT};
  font-weight: ${fontWeight.bold};
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: ${fontSize.desktop.xl}px;
    font-weight: ${fontWeight.thick};
  }
`;

export const BigHeading = styled.h2`
  font-size: ${fontSize.mobile.lg}${DEFAULT_SIZE_UNIT};
  font-weight: ${fontWeight.bold};
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: ${fontSize.desktop.lg}px;
  }
`;

export const Heading = styled.h1`
  font-size: ${(props) => props.size || fontSize.default.md}px;
  font-weight: ${fontWeight.bold};
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: ${(props) =>
      props.size ? +props.size + 2 : fontSize.desktop.md}px;
  }
`;
