import { groupArrayObj } from '../../../utils/transfomer';
import {
  SELECTED_CURRENCY,
  SET_COMPARE,
  SELLER_HOME,
  CATEGORY,
  CATEGORIES,
  RANDOM_SELLERS,
  COMPARE_HEADER,
  COMPARE_BODY,
  COUNTRIES,
  BANKS,
  SET_DETAILS,
  WORKSTORE,
  JOBS_COMBO,
  SET_DEPENDECY,
  JOBPOST,
  SET_ONGOING_PROMOS,
  SET_PENDING_PROMOS,
  SET_PROMO,
  SET_PROMO_PROFILE,
  SET_CURRENCY_RATES,
  SET_EMPLOY_JOB,
  SET_PROMO_BANNER,
  SET_ATTACHABLE_PROMO,
  SET_SELLER_GALLERY,
  SET_TOP_RATED_SERVICES,
} from './actionTypes';

const initialState = {
  dependencies: null,
  selectedCurrency: {},
  netConversions: { currencyToOthers: {}, othersToCurrency: {} },
  comparable: false,
  compareHeader: [],
  compareBody: [],
  category: {},
  categories: [],
  randomSellers: [],
  countries: [],
  banks: [],
  loading: true,
  sellerHome: {
    name: {},
  },
  jobPosts: {},
  promos: {
    pending: [],
    attachable: [],
    ongoing: {},
    promo: {},
    profiles: {},
    banner: { show: false, promo: null },
  },
  jobs: {},
  galleries: {},
  sellerHomes: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPENDECY:
      return setDependency(state, action.payload);
    case SELECTED_CURRENCY:
      return updatecurrency(state, action.currency);
    case SET_COMPARE:
      return setComparable(state, action.value);
    case CATEGORY:
      return setCategory(state, action.value);
    case CATEGORIES:
      return setCategories(state, action.value);
    case RANDOM_SELLERS:
      return setRandomSellers(state, action.value);
    case COMPARE_HEADER:
      return setCompareHeader(state, action.payload);
    case COMPARE_BODY:
      return setCompareBody(state, action.payload);
    case COUNTRIES:
      return setCountries(state, action.payload);
    case BANKS:
      return setBanks(state, action.payload);
    case SET_DETAILS:
      return setDetails(state, action.payload, action.value);
    case SELLER_HOME:
      return setSellerHome(state, action.payload, action.name);
    case WORKSTORE:
      return setWorkstore(state, action.payload);
    case JOBS_COMBO:
      return setJobsCombo(state, action.payload);
    case JOBPOST:
      return setJobPost(state, action.payload);
    case SET_ONGOING_PROMOS:
      return setOngoingPromos(state, action.payload);
    case SET_PENDING_PROMOS:
      return setPendingPromos(state, action.payload);
    case SET_ATTACHABLE_PROMO:
      return setAttachablePromos(state, action.payload);
    case SET_PROMO:
      return setPromo(state, action.payload);
    case SET_PROMO_PROFILE:
      return setPromoProfile(state, action.payload);
    case SET_PROMO_BANNER:
      return setPromoBanner(state, action.payload);
    case SET_CURRENCY_RATES:
      return updateCurrencyRate(state, action.payload);
    case SET_EMPLOY_JOB:
      return setEmployJob(state, action.payload);
    case SET_SELLER_GALLERY:
      return setSellerGalleries(state, action.payload);
    case SET_TOP_RATED_SERVICES:
      return setTopServices(state, action.payload);
    default:
      return state;
  }
};

const setDependency = (state, payload) => {
  const currencyToOthers = {};
  const othersToCurrency = {};
  const currArr = payload.net_conversions || [];

  /**
   * Groups currency into bidirectional objects of currency_id and buying_currency_id
   */

  for (let i = 0; i < currArr.length; i++) {
    const { buying_currency_id, currency_id } = currArr[i];

    //group Currency to others
    if (currencyToOthers[currency_id]) {
      currencyToOthers[currency_id] = {
        ...currencyToOthers[currency_id],
        [buying_currency_id]: currArr[i],
      };
    } else {
      currencyToOthers[currency_id] = { [buying_currency_id]: currArr[i] };
    }

    //group Others to Currency
    if (othersToCurrency[buying_currency_id]) {
      othersToCurrency[buying_currency_id] = {
        ...othersToCurrency[buying_currency_id],
        [currency_id]: currArr[i],
      };
    } else {
      othersToCurrency[buying_currency_id] = { [currency_id]: currArr[i] };
    }
  }

  return {
    ...state,
    dependencies: payload,
    netConversions: { othersToCurrency, currencyToOthers },
  };
};

function updatecurrency(state, currency) {
  return {
    ...state,
    selectedCurrency: { ...state.selectedCurrency, ...currency },
  };
}

function updateCurrencyRate(state, value = []) {
  const rateObj = groupArrayObj(value, 'buying_currency_id');

  return {
    ...state,
    selectedCurrency: { ...state.selectedCurrency, rates: rateObj },
  };
}

function setComparable(state, value) {
  return {
    ...state,
    comparable: value,
  };
}

function setCategory(state, value) {
  return {
    ...state,
    category: value,
    loading: false,
  };
}

function setCategories(state, value) {
  return {
    ...state,
    categories: value,
  };
}

function setRandomSellers(state, value) {
  return {
    ...state,
    randomSellers: value,
  };
}

function setCompareHeader(state, payload) {
  return {
    ...state,
    compareHeader: payload,
    loading: false,
  };
}

function setCompareBody(state, payload) {
  return {
    ...state,
    compareBody: payload,
    loading: false,
  };
}

function setCountries(state, payload) {
  return {
    ...state,
    countries: payload,
  };
}

function setBanks(state, payload) {
  return {
    ...state,
    banks: payload,
  };
}

function setDetails(state, payload, value) {
  return {
    ...state,
    [payload]: value,
  };
}

function setSellerHome(state, payload) {
  return {
    ...state,
    sellerHome: {
      ...state.sellerHome,
      [payload.name]: {
        ...payload,
      },
    },
  };
}

function setWorkstore(state, payload) {
  return {
    ...state,
    workstore: payload,
  };
}

function setJobsCombo(state, payload) {
  return {
    ...state,
    jobsCombo: payload,
  };
}

function setJobPost(state, payload) {
  return {
    ...state,
    jobPosts: { ...state.jobPosts, [`jobPost_${payload.message_id}`]: payload },
  };
}

function setOngoingPromos(state, payload) {
  return {
    ...state,
    promos: { ...state.promos, ongoing: payload },
  };
}

function setPendingPromos(state, payload) {
  return {
    ...state,
    promos: { ...state.promos, pending: payload },
  };
}

function setAttachablePromos(state, payload) {
  return {
    ...state,
    promos: { ...state.promos, attachable: payload },
  };
}

function setPromoBanner(state, payload) {
  return {
    ...state,
    promos: { ...state.promos, banner: payload },
  };
}

function setPromo(state, payload) {
  return {
    ...state,
    promos: {
      ...state.promos,
      promo: {
        ...state.promos.promo,
        [`promo_${payload?.promo?.id}`]: payload,
      },
    },
  };
}

function setPromoProfile(state, payload) {
  return {
    ...state,
    promos: {
      ...state.promos,
      profiles: {
        ...state.promos.profiles,
        [`profile_${payload.promo_profile_id}`]: payload,
      },
    },
  };
}

function setEmployJob(state, payload) {
  return {
    ...state,
    jobs: {
      ...state.jobs,
      [payload.employmentId]: payload,
    },
  };
}

function setSellerGalleries(state, payload) {
  return {
    ...state,
    galleries: {
      ...state.galleries,
      [payload.urlname]: payload,
    },
  };
}

function setTopServices(state, payload) {
  return {
    ...state,
    topServices: payload,
  };
}

export default reducer;
