import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Styles
import './PromoCard.scss';

function PromoCard({ promo, borderStyle }) {
  return (
    <Link to={`/promo/${promo.id}`} className={`promo-card  ${borderStyle}`}>
      <div className="card-left">
        <div className="card-left-text">
          <h4> {promo.title} </h4>
          <p> {promo.description} </p>
        </div>

        <div style={{ backgroundColor: promo.theme }} className="promo-price">
          <span>up to </span>
          <span className="discount large">
            {promo.highest_percentage
              ? `${Math.round(promo.highest_percentage)}%`
              : `${Math.floor(Math.random() * (65 - 30 + 1) + 30)}%`}
          </span>
          <span>discount</span>
        </div>
      </div>
      <img
        className="mini-banner"
        src={promo.mini_banner || promo.banner}
        alt={promo.title}
      />
    </Link>
  );
}

PromoCard.propTypes = {
  promo: PropTypes.object.isRequired,
  borderStyle: PropTypes.string,
};

PromoCard.defaultProps = {
  borderStyle: 'shadowed',
};

export default PromoCard;
