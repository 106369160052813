import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import swal from 'sweetalert';
import Notify from './notify';
import Axios from 'axios';
import moment from 'moment';
import { buyerStore, sellerStore } from '../hooks/useAuthetication';
import { replaceSpaces } from './transfomer';
import { setSentryUser } from './sentry';

// import authBroadcast from './broadcast';

const poolData = {
  UserPoolId: 'eu-west-1_XKbglrJiw',
  ClientId: '16ocha99e3tbtlq74acnsv6g',
};

const userPool = new CognitoUserPool(poolData);

class Auth {
  constructor() {
    this.poolData = poolData;
    this.userPool = userPool;
  }

  //Checks authentication status
  isAuthenticated = () => {
    const currentUser = this.userPool.getCurrentUser();

    return currentUser != null;
  };

  getTokenFromSession = (session) => {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    };
  };

  /**
   * @param {String} ["idToken" || "refreshToken" || 'accessToken']
   */

  getUserToken = (tokenType) => {
    const currentUser = this.userPool.getCurrentUser();

    if (currentUser != null) {
      let userToken;

      //Callback for checkTokenExpiration
      const setValidToken = (session) => {
        userToken = this.getTokenFromSession(session)[tokenType];
      };

      currentUser.getSession((err, session) => {
        if (err) {
          currentUser.signOut();
          this.redirectToLogin();
        } else {
          const tokens = this.getTokenFromSession(session);
          this.checkTokenExpiration(tokens, setValidToken);
        }
      });

      return userToken;
    } else {
      currentUser && currentUser.signOut();
      this.redirectToLogin();
    }
  };

  getDataFromToken = (data) => {
    const idToken = this.getUserToken('idToken');
    try {
      const decoded = jwt_decode(idToken);
      return data ? decoded[data] : decoded;
    } catch (error) {
      console.log(error);
    }
  };

  redirectToLogin = () => {
    window.location.href = '/login';
  };

  toCognitoUsernameFormat = (text = '') => {
    return text.trim().split(' ').join('|^|').toLowerCase();
  };

  Login = (email, password, success, failed) => {
    const username = email.trim().split(' ').join('|^|');
    const authenticationData = {
      Username: username.toLowerCase(),
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username.toLowerCase(),
      Pool: this.userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        setTimeout(success, 1000);
      },
      onFailure: (err) => {
        failed();
        const MIGRATION_FAIL_MESSAGE =
          'Username cannot be of email format, since user pool is configured for email alias.';
        const RESET_ERROR_CODE = 'PasswordResetRequiredException';
        if (err.message === MIGRATION_FAIL_MESSAGE) {
          Notify.error('Kindly login using your username');
          Axios.post(
            'https://8l4cyxccdh.execute-api.eu-west-1.amazonaws.com/Prod/tickets/talk-to-us',
            {
              email,
              custom_request: 'Unable to Login! User Migration failed',
              phone_no: 11111111111,
              start_date: moment().format(),
            }
          );
          return;
        } else if (err.code === RESET_ERROR_CODE) {
          swal({
            text: 'Password reset required!',
            title: 'Reset Password',
          }).then(() => (window.location.href = '/reset-password'));
          return;
        } else if (err.code === 'UserNotConfirmedException') {
          swal({
            title: '',
            text: 'Your Account has not been confirmed, please confirm now!',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#83bc23',
            confirmButtonClass: 'btn green',
            buttonsStyling: false,
          }).then(() => {
            localStorage.setItem('VerificationUsername', username);
            this.ResendVerificationCode(
              username,
              () => (window.location.href = '/verify-code')
            );
            // redirect to the verify-code page to enter his code that was sent to his mail
          });
          return;
        }
        Notify.error(err.message);
      },
    });
  };

  checkTokenExpiration(tokens, setValidSession) {
    const AccessToken = new CognitoAccessToken({
      AccessToken: tokens.accessToken,
    });
    const IdToken = new CognitoIdToken({ IdToken: tokens.idToken });
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: tokens.refreshToken,
    });

    const sessionData = {
      IdToken,
      AccessToken,
      RefreshToken,
    };

    const cachedSession = new CognitoUserSession(sessionData);

    if (cachedSession.isValid()) {
      setValidSession(cachedSession);
    } else {
      const currentUser = this.userPool.getCurrentUser();
      currentUser.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          currentUser.signOut();
          this.redirectToLogin();
        } else {
          console.log('refresshing');
          setValidSession(session);
        }
      });
    }
  }

  refreshSession(callback) {
    const refreshToken = this.getUserToken('refreshToken');
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    const currentUser = this.userPool.getCurrentUser();
    currentUser.refreshSession(token, function (err, session) {
      if (err) {
        this.Logout();
      }

      callback(session);
    });
  }

  Logout = async () => {
    //Remove all local data of user
    await buyerStore.clear();
    await sellerStore.clear();

    setSentryUser(null);

    //Signout of cognito
    const currentUser = this.userPool.getCurrentUser();
    currentUser && currentUser.signOut();

    window.location.reload();
  };

  setUserAttributes(email, firstName, lastName, type, displayName) {
    const userAttributes = [
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: 'given_name',
        Value: firstName,
      },
      {
        Name: 'family_name',
        Value: lastName,
      },
      {
        Name: 'custom:type',
        Value: type,
      },
      // {
      //   Name: "custom:displayName",
      //   Value: displayName,
      // },
    ];
    return userAttributes.map((attribute) => {
      return new CognitoUserAttribute(attribute);
    });
  }

  Signup = (
    email,
    password,
    firstName,
    lastName,
    displayName,
    type,
    callback,
    errorFunction
  ) => {
    let cognitoAttributes = this.setUserAttributes(
      email,
      firstName,
      lastName,
      type,
      displayName
    );
    let response = {};
    // const username = displayName.toLowerCase();
    const username = replaceSpaces(displayName, '|^|').toLowerCase();

    userPool.signUp(
      username,
      password,
      cognitoAttributes,
      null,
      (err, result) => {
        if (err) {
          response['err'] = err;
          errorFunction(err);
        } else {
          localStorage.setItem('VerificationUsername', username);
          localStorage.setItem('VerificationEmail', email);
          response['result'] = result;
          callback(response);
        }
      }
    );
    return response;
  };

  ChangePassword = (oldPassword, newPassword, callback) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          return;
        }
      });

      cognitoUser.changePassword(oldPassword, newPassword, callback);
    }
  };

  promptLogin = async () => {
    return swal({
      title: 'This action requires login',
      text: 'Proceed to login?',
      buttons: {
        confirm: {
          text: 'Login',
          value: true,
          className: 'butn butn--green',
        },
        cancel: {
          text: 'Cancel',
          value: false,
          className: 'butn butn--blue',
          visible: true,
        },
      },
    }).then(async (value) => {
      if (value) {
        window.location = `/login`;
      }
    });
  };

  VerifyUser = (code, success, fail) => {
    const username = localStorage.getItem('VerificationUsername');
    if (!username) {
      Notify.success('Invalid User');
      fail();
      return;
    }
    const userData = {
      Username: username.toLowerCase(),
      Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        if (err.code === 'InvalidLambdaResponseException') {
          localStorage.removeItem('VerificationUsername');

          success();
          return;
        }
        fail();
        Notify.success(err.message);
        return;
      }

      localStorage.removeItem('VerificationUsername');
      success();
    });
  };

  ResendVerificationCode = (username, success) => {
    username = username || localStorage.getItem('VerificationUsername');
    const userData = {
      Username: username.toLowerCase(),
      Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode(function (err) {
      if (err) {
        console.log(err);
        Notify.error(err.message);
        return;
      }

      success ? success() : Notify.success('Code sent, Please check your mail');
    });
  };

  TokenRefreshInterval = () => {
    return setInterval(
      () => this.refreshSession(() => console.log('refresh')),
      20000000 //Refresh every 5 hours
    );
  };

  ChangePassword = (oldPassword, newPassword, callback) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          return;
        }
      });

      cognitoUser.changePassword(oldPassword, newPassword, callback);
    }
  };

  forgotPassword(email, success, error) {
    var userData = {
      Username: this.toCognitoUsernameFormat(email),
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        success(result);
      },
      onFailure: function (err) {
        error(err);
      },
    });
  }

  confirmPassword(email, code, password, success, error) {
    var userData = {
      Username: this.toCognitoUsernameFormat(email),
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(code, password, {
      onSuccess: (result) => success(result),
      onFailure: (err) => error(err),
    });
  }
}

export default Auth;
