import webRoutes, { RedirectRoutes } from "../modules/web/routes";
import buyerRoutes from "../modules/buyer/routes";
import sellerRoutes from "../modules/seller/routes";
import orderRoutes from "../modules/Order/routes";

const routes = [
  ...RedirectRoutes,
  ...buyerRoutes,
  ...sellerRoutes,
  ...orderRoutes,
  ...webRoutes,
];

export default routes;
