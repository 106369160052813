import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Text } from '@chakra-ui/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated } from '@react-spring/web';
import React, { useRef } from 'react';
import { ElementColor } from '../../../constants/Colors';
import useBoop from '../../../hooks/animations/useBoop';
import useDrop from '../../../hooks/animations/useDrop';
import useClickOutside from '../../../hooks/useClickOutside';
import { PlainButton } from '../Button/style';
import { HStack } from '../layout/Stack';
import { Badge } from '@chakra-ui/layout';
import { DropDownStyle, DropItems } from './style';

function DropDown({
  showCaret = true,
  children,
  title = '',
  triggerType = 'click',
  dropItemStyles = {},
  caretSpacing = '1rem',
  isFullWidth = false,
  isNew = false,
}) {
  const { toggleDropped, menuStyle, caretStyle, isDropped } = useDrop({});
  const [style, trigger] = useBoop({ y: 2 });
  const clickRef = useRef();

  const onMouseeEnter = () => {
    trigger();
    triggerType === 'hover' && toggleDropped();
  };
  const onClick = () => triggerType === 'click' && toggleDropped();
  const onMouseLeave = () => {
    triggerType === 'hover' && toggleDropped();
  };

  //Close the dropdown
  useClickOutside(clickRef, onClick, isDropped);

  return (
    <DropDownStyle
      ref={clickRef}
      onMouseEnter={onMouseeEnter}
      onMouseLeave={onMouseLeave}
    >
      <PlainButton $isFullWidth={isFullWidth} onClick={onClick}>
        <HStack spacing={caretSpacing}>
          <Text whiteSpace="nowrap" fontWeight="500">
            {title}
            {isNew && (
              <Badge ml="1" size="sm" colorScheme="green">
                New
              </Badge>
            )}
          </Text>

          {showCaret && (
            <animated.span style={{ ...style, ...caretStyle }}>
              <FontAwesomeIcon
                icon={faChevronDown}
                color={ElementColor.line}
                size="sm"
              />
            </animated.span>
          )}
        </HStack>
      </PlainButton>
      {isDropped && (
        <DropItems style={{ ...menuStyle, ...dropItemStyles }}>
          {children}
        </DropItems>
      )}
    </DropDownStyle>
  );
}

export default DropDown;
