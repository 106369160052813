import {
  Box,
  Container,
  Flex,
  GridItem,
  HStack,
  Heading,
  Link as ChakraLink,
  SimpleGrid,
  Text,
  VStack,
  Divider,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../../../../components/layout/new/Header/mobile/styles';
import { appEnv } from '../../../../../utils/appEnv';

export const LandingFooter = () => {
  return (
    <Box bg="brand.dark">
      <Container maxW="container.xl" py={[10, 16]}>
        <Box mx="auto">
          <SimpleGrid justifyContent="space-between" columns={[1, 2]}>
            <GridItem>
              <Flex w="full" justify={['center', 'start']}>
                <Box w="full" maxW="400px">
                  <Logo src="/images/logo_white.svg" alt="terawork logo" />
                </Box>
              </Flex>

              <Text
                color="white"
                fontSize={['sm', 'md']}
                fontWeight={600}
                mt="0.5rem"
                lineHeight={1.7}
                maxW="417px"
                textAlign="left"
              >
                The future of work ...today
              </Text>
            </GridItem>
            <GridItem>
              <SimpleGrid
                maxW="650px"
                ml="auto"
                columns={[2, null, 3]}
                mt={['2rem', '0']}
                gap={[8, 8, 12]}
              >
                <GridItem>
                  <Heading
                    fontSize={['md', 'lg']}
                    fontWeight={700}
                    color="white"
                  >
                    Products
                  </Heading>
                  <VStack
                    alignItems="flex-start"
                    spacing="0.35rem"
                    color="white"
                    fontSize={['sm', 'md']}
                    fontWeight={400}
                  >
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      as={Link}
                      to="/freelance"
                      mx="auto"
                    >
                      Freelance
                    </ChakraLink>
                    <Text
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      as={ChakraLink}
                      isExternal
                      href={`${appEnv.enterpriseAppHost}outsourcing`}
                      to="/outsourcing"
                    >
                      Outsourcing
                    </Text>
                    <Text
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      as={ChakraLink}
                      isExternal
                      href={`${appEnv.enterpriseAppHost}full-time`}
                    >
                      Full-time Hiring
                    </Text>

                    <Text
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      as={Link}
                      to="/credits"
                    >
                      Hiring Voucher
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <Heading
                    fontSize={['md', 'lg']}
                    fontWeight={700}
                    color="white"
                  >
                    Company
                  </Heading>
                  <VStack
                    alignItems="flex-start"
                    spacing="0.35rem"
                    color="white"
                    fontSize={['sm', 'md']}
                    fontWeight={400}
                  >
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      as={Link}
                      to="/about"
                      mx="auto"
                    >
                      About Us
                    </ChakraLink>
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      as={Link}
                      to="/contact"
                      mx="auto"
                    >
                      Contact
                    </ChakraLink>
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      as={Link}
                      to="/terms-of-service"
                      mx="auto"
                    >
                      Terms
                    </ChakraLink>
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      as={Link}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </ChakraLink>
                  </VStack>
                </GridItem>

                <GridItem>
                  <Heading
                    fontSize={['md', 'lg']}
                    fontWeight={700}
                    color="white"
                  >
                    Community
                  </Heading>
                  <VStack
                    alignItems="flex-start"
                    spacing="0.35rem"
                    color="white"
                    fontSize={['sm', 'md']}
                    fontWeight={400}
                  >
                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      href="https://www.terawork.com/blog"
                      isExternal
                    >
                      Blog
                    </ChakraLink>

                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      href="https://www.terawork.com/blog/c/news-press/"
                      isExternal
                    >
                      Press and News
                    </ChakraLink>

                    <ChakraLink
                      mt="0.5rem"
                      lineHeight={1.7}
                      w="full"
                      mx="auto"
                      href="https://www.terawork.com/blog/c/blog/"
                      isExternal
                    >
                      Helpful Tips
                    </ChakraLink>
                  </VStack>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Divider my={[10, 12]} borderColor="borderColor" />

          <HStack
            flexWrap="wrap"
            justifyContent={['center', 'flex-end']}
            spacing={['2rem']}
            color="white"
            textAlign="center"
          >
            <Text
              fontSize="sm"
              fontWeight={400}
              lineHeight={1.7}
              maxW="417px"
              as={Link}
              to="mailto:info@terawork.com"
              mr="4rem"
            >
              info@terawork.com
            </Text>
            <Text
              color="text.footerLink"
              fontSize="sm"
              fontWeight={400}
              lineHeight={1.7}
              maxW="417px"
            >
              © {new Date().getFullYear()} TERAWORK Inc. All rights reserved
            </Text>
          </HStack>
        </Box>
      </Container>
    </Box>
  );
};
