import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getOngoingPromos,
  setPromoBanner,
} from '../modules/web/pages/PromoStore/service';
import { PromoStatus } from '../modules/web/pages/PromoStore/Page';

const filterOngoingPromo = (promos = []) => {
  return promos.filter((promo) => promo.status === PromoStatus.LIVE);
};

const pickRandom = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const usePromoBanner = () => {
  const dispatch = useDispatch();
  const { ongoing, banner } = useSelector((state) => state.web.promos);

  useEffect(() => {
    if (!ongoing.data) {
      dispatch(getOngoingPromos());
    }

    if (ongoing.data && !banner.show) {
      //Pick a promo to show at the top
      const randomPromo = pickRandom(filterOngoingPromo(ongoing.data));
      dispatch(setPromoBanner(randomPromo));
    }
  }, [dispatch, ongoing, banner]);

  return {
    banner,
  };
};
