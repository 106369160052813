import {
  ONGOING_WORK,
  RECENT,
  RECENTLY_VISITED,
  SAVED_PROFILE,
  SET_BUYER_DATA,
  SET_CURRENCY,
  SET_CURRENCY_RATES,
  SET_JOBPOSTS,
  SET_NOTIFICATIONS,
  SET_PENDING_ORDER,
  SET_POST,
  SET_REVIEWS,
  SET_TRANSACTIONS,
  SET_WALLET,
  WORK_HISTORY,
} from './actionsTypes';

import { groupArrayObj } from '../../../utils/transfomer';

const { default: Buyer } = require('../models/Buyer');

const initailState = {
  user: new Buyer({}),
  fetchingBuyerData: true,
  pendingOrders: null,
  savedProfiles: {},
  recentlyVisited: null,
  recent: null,
  loading: true,
  posts: {},
  notifications: null,
  currency: {
    rates: {},
  },
};

const reducer = (state = initailState, { type, payload = null }) => {
  switch (type) {
    case SET_PENDING_ORDER:
      return setPendingOrder(state, payload);
    case SET_BUYER_DATA:
      return setBuyerData(state, payload);
    case SAVED_PROFILE:
      return savedProfile(state, payload);
    case RECENTLY_VISITED:
      return recentlyVisited(state, payload);
    case RECENT:
      return recent(state, payload);
    case ONGOING_WORK:
      return setOngoingWork(state, payload);
    case WORK_HISTORY:
      return setBuyerWorkHistory(state, payload);
    case SET_JOBPOSTS:
      return setJobPosts(state, payload);
    case SET_POST:
      return setPost(state, payload);
    case SET_NOTIFICATIONS:
      return setNotifications(state, payload);
    case SET_TRANSACTIONS:
      return setTransactions(state, payload);
    case SET_WALLET:
      return setWallet(state, payload);
    case SET_CURRENCY:
      return setCurrency(state, payload);
    case SET_CURRENCY_RATES:
      return setCurrencyRates(state, payload);
    case SET_REVIEWS:
      return setBuyerReviews(state, payload);
    default:
      return state;
  }
};

function setBuyerWorkHistory(state, payload) {
  return {
    ...state,
    workHistory: payload,
  };
}

function setOngoingWork(state, payload) {
  return {
    ...state,
    ongoingWork: payload,
  };
}

function setPendingOrder(state, payload) {
  return {
    ...state,
    pendingOrders: payload,
  };
}

function setBuyerData(state, payload) {
  return {
    ...state,
    fetchingBuyerData: false,
    user: payload,
  };
}

function savedProfile(state, payload) {
  return {
    ...state,
    savedProfiles: payload,
    loading: false,
  };
}

function recentlyVisited(state, payload) {
  return {
    ...state,
    recentlyVisited: payload,
    loading: false,
  };
}

function recent(state, payload) {
  return {
    ...state,
    recent: payload,
    loading: false,
  };
}

function setJobPosts(state, payload) {
  return {
    ...state,
    jobPosts: payload,
  };
}

function setPost(state, payload) {
  return {
    ...state,
    posts: { ...state.posts, [`job_${payload.id}`]: payload },
  };
}

function setNotifications(state, payload) {
  return {
    ...state,
    notifications: payload,
  };
}

function setTransactions(state, payload) {
  return {
    ...state,
    transactions: payload,
  };
}

function setWallet(state, payload) {
  return {
    ...state,
    wallet: { ...payload.wallet, wallet_balance: payload.wallet_balance },
  };
}

function setCurrency(state, payload) {
  return {
    ...state,
    currency: { ...state.currency, ...payload },
  };
}

function setCurrencyRates(state, payload) {
  const rateObj = groupArrayObj(payload, 'buying_currency_id');

  return {
    ...state,
    currency: { ...state.currency, rates: rateObj },
  };
}

function setBuyerReviews(state, payload) {
  return {
    ...state,
    reviews: payload,
  };
}

export default reducer;
