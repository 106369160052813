import { animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ElementColor, TextColor } from '../../../../../constants/Colors';
import { fontWeight } from '../../../../../constants/sizes';
import { PlainButton } from '../../../../library/Button/style';
import { HStack, VStack } from '../../../../library/layout/Stack';

const spaceBetweenAndCenter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DesktopHeaderStyle = styled.header`
  ${spaceBetweenAndCenter};
  padding: 1rem 0;
  z-index: 40;
`;

export const LeftStyle = styled.div`
  ${spaceBetweenAndCenter};
`;

const searchStyle = css`
  border: 1px solid ${ElementColor.line};
  padding: 0.5rem 1rem;
  border-radius: 30px;
  background: white;
  // color: ${ElementColor.line};
  font-size: 0.9rem;
`;

export const SearchModeButton = styled.button`
  ${searchStyle};
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: text !important;
  outline: none;
  color: ${ElementColor.line};
`;

export const SearchInputWrapper = styled.form`
  ${searchStyle};
  position: relative;
  width: 250px;
  min-height: 30px;
  padding: 0.2rem 0.5rem;
  display: flex;

  .search-suggest {
    top: 100%;
    margin-top: 1.5rem;
  }
`;

const transparentButton = css`
  background: none;
  border: none;
  outline: none;
`;

export const Input = styled.input`
  border: none;
  outline: none;
`;

export const SearchIconStyle = styled(HStack)`
  ${transparentButton}
  display: flex;
  align-items: center;
`;

const hasUnread = css`
  content: '${(props) => props.$unread}';
  background: ${ElementColor.danger};
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.8rem;
  font-weight: ${fontWeight.bold};
  padding: 8px;
`;

export const RightIconStyle = styled(Link)`
  position: relative;

  &:after {
    ${(props) => props.$unread && hasUnread}
  }
`;

export const SearchOptionStyle = styled(animated.div)`
  position: absolute;
  top: 110%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px #00000020;
  width: 100%;
  border-radius: 5px;
  font-size: 0.9rem;
`;

export const OptionItem = styled.button`
  ${transparentButton};
  width: 100%;
  padding: 1rem;
  text-align: left;

  &:last-child {
    border-top: 1px solid ${ElementColor.lineLight};
  }
`;

export const NavAvatar = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid ${ElementColor.lineLight};
`;

export const NavDropContainer = styled.nav`
  padding: 2rem;
  width: 320px;
`;

export const NavDropStyle = styled(VStack)`
  background: white;
  padding: 2rem;
  border: 1px solid ${ElementColor.line};
  box-shadow: 0px 2px 10px #00000030;
`;

const navMixin = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all ease;
  width: 100%;
`;

export const NavProfile = styled(Link)`
  border-bottom: 1px solid ${ElementColor.line};
  padding-bottom: 1.5rem;
  width: 100%;
`;

export const NavDropLink = styled(Link)`
  ${navMixin};
  border-bottom: ${(props) =>
    props.$bordered && `1px solid ${ElementColor.line}`};
  padding-bottom: ${(props) => props.$bordered && '1.5rem'};

  &:hover {
    color: ${TextColor.green};
  }
`;

export const HeadlineStyle = styled(PlainButton)`
  ${navMixin};
`;

export const Logout = styled(PlainButton)`
  ${navMixin}
  color: ${ElementColor.danger};

  &:hover {
    color: darken(${ElementColor.danger}, 20%);
  }
`;

export const LeftLinkContainer = styled.div`
  padding: 1.5rem 0;
`;

export const LinkIcon = styled.span`
  width: 50px;
  height: 40px;
  background-color: ${(props) => props.color}20;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftLinksWrapper = styled.div`
  background: white;
  min-width: 450px;
  display: grid;
  grid-template-columns: ${(props) => props.$columns || '1fr'};
  padding: 2rem;
  gap: 2rem;
  box-shadow: 0px 2px 10px #00000030;
`;

export const LeftLink = styled(Link)`
  p {
    transition: 0.3s all ease;
  }

  &:hover {
    p {
      color: ${TextColor.green};
    }
  }
`;
