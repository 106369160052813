export const Heading = {
  sizes: {
    medium: {
      fontSize: ['xl', null, '3xl'],
    },
    '2xl': {
      fontSize: ['3xl', null, '4xl'],
    },
    '3xl': {
      fontSize: ['4xl', null, '5xl'],
      lineHeight: 1.2,
    },
    '4xl': {
      fontSize: ['4xl', '5xl', '6xl'],
      lineHeight: 1.2,
    },
    '5xl': {
      fontSize: ['4xl', '6xl', '7xl'],
      lineHeight: 1.2,
    },
    '6xl': {
      fontSize: ['5xl', '7xl', '8xl'],
      lineHeight: 1.2,
    },
  },
};
