import React from 'react';
import {
  Box,
  Grid,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/layout';
import { css } from '@emotion/react';

const FAQListItem = ({ children, ...props }) => {
  return (
    <ListItem
      listStyleType="initial"
      _notLast={{
        mb: 4,
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
};

const questions = [
  {
    question: 'What is the TERAWORK PromoStore?',
    answer: (
      <Text>
        TERAWORK PromoStore is a feature on the TERAWORK platform where clients
        can find gigs, services, or freelance jobs at discounted prices offered
        by the freelancers themselves.
      </Text>
    ),
  },
  {
    question: 'How does the PromoStore work for freelancers?',
    answer: (
      <Text>
        Freelancers can create promo packages and attach them to ongoing
        campaigns. These campaigns are heavily promoted by the TERAWORK team,
        providing freelancers with an opportunity to gain visibility, build
        their work statistics, and increase their earnings on the platform.
      </Text>
    ),
  },
  {
    question:
      'What are the advantages of participating in the TERAWORK PromoStore as a freelancer?',
    answer: (
      <UnorderedList>
        <FAQListItem>
          It enables freelancers to attract new client, upsell and cross-sell to
          existing clients thereby increasing their income.
        </FAQListItem>
        <FAQListItem>
          Freelancers stand a chance of joining the "proven talent" list and bid
          for larger gigs.
        </FAQListItem>
        <FAQListItem>
          The increased visibility and competitive edge gained from
          participating in the PromoStore can lead to long-term success on the
          TERAWORK platform.
        </FAQListItem>
      </UnorderedList>
    ),
  },

  {
    question: 'How can clients benefit from the PromoStore?',
    answer: (
      <UnorderedList>
        <FAQListItem>
          Significantly reduce costs by taking advantage of the promotional
          discounts offered on gigs, allowing clients to access high-quality
          services at a more affordable price point.
        </FAQListItem>
        <FAQListItem>
          Outsourcing simple tasks to freelancers via the PromoStore, Clients
          can enhance productivity and focus on core aspects of their business.
          Delegating these tasks ultimately leads to increased efficiency and
          improved overall business performance.
        </FAQListItem>
        <FAQListItem>
          Skip the extensive searching or lengthy negotiation processes with a
          more streamlined approach that helps you find and engage freelancers
          instantly, saving time and allowing clients to promptly meet project
          deadlines and achieve their desired outcomes.
        </FAQListItem>
      </UnorderedList>
    ),
  },
  {
    question:
      'Are the Promo packages in the PromoStore of the same quality as regular packages?',
    answer: (
      <Text>
        Yes, While the packages may be available at discounted prices, the
        expertise and the quality of job delivery by the freelancers remain the
        same.
      </Text>
    ),
  },
  {
    question: 'How much discount can I expect on gigs in the PromoStore?',
    answer: (
      <Text>
        The discount rates on gigs in the PromoStore may vary depending on the
        individual freelancers and their promotional offers. Some gigs may offer
        discounts of 50% or more, providing you with substantial cost savings.
      </Text>
    ),
  },
  {
    question: 'How do I hire a freelancer from the PromoStore?',
    answer: (
      <Text>
        To hire a freelancer from the PromoStore, simply browse through
        different promo profiles available to you, select the one that meets
        your requirements and place your order. You can communicate with the
        freelancer, discuss project details, and proceed with the job based on
        agreed deliverables.
      </Text>
    ),
  },
];

export const PromoStoreFAQ = () => {
  return (
    <Box
      bg="whiteAlpha.900"
      as="section"
      py={{ base: '10', md: '20' }}
      pos="relative"
    >
      <Box className="container">
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr 2fr',
            lg: '2fr 5fr',
          }}
          css={css`
            a {
              color: #37a000;
              text-decoration: underline;
            }
          `}
        >
          <Heading as="h3" size="lg" mb="8">
            PromoStore FAQs
          </Heading>
          <VStack align="stretch" spacing="8">
            {questions.map(({ question, answer }) => (
              <VStack align="start" key={question}>
                <Heading as="h4" size="md">
                  {question}
                </Heading>
                <Box>{answer}</Box>
              </VStack>
            ))}
          </VStack>
        </Grid>
      </Box>
    </Box>
  );
};
