import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { footerLinks } from "..";
import { DesktopOnly } from "../../../../../styling/breakpoints";
import LinkGroup from "../LinkGroup";
import { InstallApp } from "../InstallApp";

const DesktopWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	padding: 3rem 0;
`;

function DesktopFooter() {
	return (
		<DesktopOnly>
			<Container>
				<DesktopWrapper>
					{footerLinks.map((item, index) => (
						<LinkGroup
							{...item}
							key={item.title}
							index={index}
							makeActive={() => {}}
							activeGroup={index}
						/>
					))}

					<InstallApp />
				</DesktopWrapper>
			</Container>
		</DesktopOnly>
	);
}

export default DesktopFooter;
