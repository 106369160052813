import {
  CHAT_WS,
  CHAT_CONVERSATIONS,
  CHAT_MESSAGES,
  CHAT_MESSAGE,
  CHAT_ONLINE_USERS,
  CHAT_SOCKET,
  CHAT_TYPE,
  CHAT_USER,
  ACTIVE_CHAT,
  UPDATE_CONVERSATIONS,
  UPDATE_CONVERSATION_PARTIAL,
  ADD_NEW_USER,
  UPDATE_LAST_MESSAGE,
  UPDATE_ADMIN_LAST_MESSAGE,
  SET_ADMIN_CONV,
} from "./actionTypes";

export const setWs = (payload) => {
  return {
    type: CHAT_WS,
    payload,
  };
};

export const setConversations = (payload) => {
  return {
    type: CHAT_CONVERSATIONS,
    payload,
  };
};

export const setMessages = (payload) => {
  return {
    type: CHAT_MESSAGES,
    payload,
  };
};

export const setMessage = (payload) => {
  return {
    type: CHAT_MESSAGE,
    payload,
  };
};

export const setOnlineUsers = (payload) => {
  return {
    type: CHAT_ONLINE_USERS,
    payload,
  };
};

export const setSocket = (payload) => {
  return {
    type: CHAT_SOCKET,
    payload,
  };
};

export const setChatType = (payload) => {
  return {
    type: CHAT_TYPE,
    payload,
  };
};

export const setUser = (payload) => {
  return {
    type: CHAT_USER,
    payload,
  };
};

export const addNewUser = (payload) => {
  return {
    type: ADD_NEW_USER,
    payload,
  };
};

export const setActiveChats = (payload) => {
  return {
    type: ACTIVE_CHAT,
    payload,
  };
};

export const updateConversations = (payload) => {
  return {
    type: UPDATE_CONVERSATIONS,
    payload,
  };
};

export const updateConversationPartial = (payload) => {
  return {
    type: UPDATE_CONVERSATION_PARTIAL,
    payload,
  };
};

export const updateLastMessage = (payload) => {
  return {
    type: UPDATE_LAST_MESSAGE,
    payload,
  };
};

export const updateAdminLastMessage = (payload) => {
  return {
    type: UPDATE_ADMIN_LAST_MESSAGE,
    payload,
  };
};

export const setAdminConv = (payload) => {
  return {
    type: SET_ADMIN_CONV,
    payload,
  };
};
