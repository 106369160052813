import { requestApi } from './api';

export const getSuggestions = async (text) => {
  if (text.length < 2) {
    return [];
  }

  let endpoint = `/profile-search/suggestions?suggestion=${text}`;

  const { data } = await requestApi('get', endpoint);
  return data?.search_suggestions || [];
};
