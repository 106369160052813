import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { API_SUCCESS } from "../../../../constants/constants";
import { requestApi } from "../../../../utils/api";
import Auth from "../../../../utils/Auth";
import Notify from "../../../../utils/notify";
import {
  resendConfirmationCode,
  userConfirmation,
} from "../../../auth/service";
import { toggleAuthModal } from "../../../auth/store/actions";

const auth = new Auth();
function useVerify() {
  const history = useHistory();
  const dispatch = useDispatch();

  const signupDetails = useSelector((state) => state.auth.authDetails);
  const signupUrl = useSelector((state) => state.auth.signupUrl);
  const verifyMail = localStorage.getItem("VerificationEmail");

  const verifyRedirect = () => {
    if (signupDetails && signupDetails.type) {
      const url =
        signupDetails.type === "Seller"
          ? "/services/registration-type"
          : signupUrl;
      return history.push(url);
    }

    history.push("/");
  };

  const resendCode = async ({ email }) => {
    const [err] = await resendConfirmationCode({ email: email || verifyMail });

    if (email) {
      //Ensure email is consistent
      localStorage.setItem("VerificationEmail", email);
    }

    if (err) {
      return err;
    } else {
      Notify.success("Verification code sent");
    }
  };

  const postVerify = async () => {
    const verifyPath =
      signupDetails?.type === "Seller" ? "/seller/verify" : "/verify";

    const verifyMail = localStorage.getItem("VerificationEmail");

    const { type } = await requestApi(
      "post",
      verifyPath,
      {
        email: verifyMail,
      },
      false
    );

    if (type === API_SUCCESS) {
      Notify.success("Verification Successful!");
      return true;
    }

    return false;
  };

  const loginUser = async (
    onSuccess,
    onFailure = () => dispatch(toggleAuthModal("login"))
  ) => {
    if (signupDetails) {
      auth.Login(
        signupDetails.email,
        signupDetails.password,
        onSuccess,
        onFailure
      );
      return;
    }

    dispatch(toggleAuthModal("login"));
  };

  const verifyUser = useCallback(
    async ({ code, onSuccess, onError }) => {
      if (code.length > 1) {
        const result = await userConfirmation({
          code,
          email: verifyMail,
          onSuccess,
          onError,
        });
        return result;
      }

      Notify.error("Please input verification code");

      onError();
      return [];
    },
    [verifyMail]
  );

  const promptVerify = useCallback(
    () => dispatch(toggleAuthModal("verify")),
    [dispatch]
  );

  return {
    verifyUser,
    resendCode,
    redirect: verifyRedirect,
    promptVerify,
    postVerify,
    auth,
    loginUser,
  };
}

export default useVerify;
