import React from "react";
import Website from "../../../../../../assets/img/Hero/website.webp";
import { ImageContainer, ImageStyle } from "./style";
import { JobCard, SellerCard } from "./cards";

function WebsiteImage() {
  return (
    <ImageContainer>
      <JobCard
        title="I need a web developer"
        jobKey="web"
        time="3 days ago"
        href="/category/service/website-design-and-development"
      />
      <ImageStyle src={Website} alt="website design" />
      <SellerCard
        avatar="https://d17meyd56oy0jr.cloudfront.net/seller-logo/330004252"
        title="Web Developer"
        name="Okon"
        rating="5.00"
        reviewsCount="3"
        href="/category/service/ecommerce-website-development"
      />
    </ImageContainer>
  );
}

export default WebsiteImage;
