import { useEffect } from 'react';

function useClickOutside(ref, callback, extraCondition = true) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && extraCondition) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
}

export default useClickOutside;
