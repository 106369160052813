import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { GreenText, Small } from '../../../styling/Text';
import { VStack } from '../../library/layout/Stack';
import JobPost from './model/Jobpost';
import {
  CardBottom,
  CardTop,
  JobDescription,
  JobIcon,
  JobLinkStyle,
  JobTitle,
  MiniJobCard,
} from './style';

function MiniJobPostCard({ job }) {
  const post = new JobPost(job);
  const [more, setMore] = useState(false);

  const toggleMore = useCallback((e) => {
    e.preventDefault();
    setMore((prev) => !prev);
  }, []);

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={`/${post.isMyJob ? 'job-posts' : 'workstore'}/${post.id}`}
    >
      <JobLinkStyle className="job-card">
        <MiniJobCard>
          <CardTop>
            <JobIcon>
              <FontAwesomeIcon icon={faBriefcase} size="lg" />
            </JobIcon>
            <VStack spacing="1rem">
              <JobTitle>{job.title}</JobTitle>
              <JobDescription>
                <p>
                  {more ? post.rawText : post.miniDescription}
                  {post.miniDescription.length < post.rawText.length && (
                    <>
                      {more ? '' : '... '}
                      <span
                        className="show-more bold green-text"
                        onClick={toggleMore}
                      >
                        {' '}
                        {more ? 'less' : 'more'}{' '}
                      </span>
                    </>
                  )}
                </p>
              </JobDescription>
            </VStack>
          </CardTop>

          <CardBottom>
            <Small>{post.posted}</Small>
            <GreenText bold>{post.budget}</GreenText>
          </CardBottom>
        </MiniJobCard>
      </JobLinkStyle>
    </Link>
  );
}

export default MiniJobPostCard;
