import React, { useMemo } from 'react';
import BrandingImage from './branding';
import GraphicsImage from './graphics';
import WebsiteImage from './website';
import BookImage from './book';

const renderRandomImage = () => {
  const images = [BrandingImage, GraphicsImage, WebsiteImage, BookImage];

  return images[Math.floor(Math.random() * images.length)];
};

function HeroImage() {
  const ImageToRender = useMemo(renderRandomImage, []);
  return <ImageToRender />;
}

export default HeroImage;
