export const LIST_JOBS = 'LIST_JOBS';
export const ADD_JOB = 'ADD_JOB';

export function listJobs(payload) {
  return {
    type: LIST_JOBS,
    payload,
  };
}

export function addJob(payload) {
  return {
    type: ADD_JOB,
    payload,
  };
}
