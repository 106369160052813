import { LinkBox, LinkOverlay } from "@chakra-ui/layout";
import {
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import {
  HStack,
  VStack,
} from "../../../../../../components/library/layout/Stack";
import { ElementColor, TextColor } from "../../../../../../constants/Colors";
import { useCurrency } from "../../../../../../hooks/useCurrency";
import { DesktopOnly } from "../../../../../../styling/breakpoints";
import { GreenText, Small } from "../../../../../../styling/Text";
import { priceList } from "./prices";
import {
  CheckIcon,
  JobCardPrice,
  JobCardStyle,
  JobCardText,
  JobIcon,
  SellerCardImage,
  SellerCardStyle,
  SellerDetails,
} from "./style";

export function JobCard({ title, time, jobKey, href }) {
  const { short, symbol } = useCurrency(null, false);
  return (
    <LinkBox d="inline-flex" pos="absolute" as={JobCardStyle}>
      <HStack spacing="1rem">
        <JobIcon>
          <FontAwesomeIcon icon={faBriefcase} size="sm" color="#999" />
        </JobIcon>
        <JobCardText spacing="0.1rem">
          <LinkOverlay as={Link} to={href} className="main">
            {title}
          </LinkOverlay>
          <Small>TERAWORK Client</Small>
        </JobCardText>

        <CheckIcon $delay="7.5s">
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={TextColor.green}
            size="lg"
          />
        </CheckIcon>
      </HStack>
      <DesktopOnly>
        <JobCardPrice>
          <span className="price">{time}</span>
          <GreenText>
            {symbol || "₦"}
            {priceList[short?.toLowerCase() || "ngn"][jobKey].toLocaleString()}
          </GreenText>
        </JobCardPrice>
      </DesktopOnly>
    </LinkBox>
  );
}

export function SellerCard({
  avatar,
  name,
  title,
  rating,
  reviewsCount,
  href,
}) {
  return (
    <LinkBox d="inline-flex" pos="absolute" as={SellerCardStyle}>
      <HStack spacing="1.5rem" alignItems="flex-start">
        <SellerCardImage src={avatar} alt={name} />
        <SellerDetails>
          <VStack spacing="0.1rem">
            <LinkOverlay d="inline" as={Link} to={href}>
              <h4>{title}</h4>
            </LinkOverlay>
            <span>{name}</span>
            <DesktopOnly>
              <HStack>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xs"
                  color={ElementColor.star}
                />
                <Small>{`${rating} (${reviewsCount})`}</Small>
              </HStack>
            </DesktopOnly>
          </VStack>
        </SellerDetails>
        <CheckIcon>
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={TextColor.green}
            size="lg"
          />
        </CheckIcon>
      </HStack>
    </LinkBox>
  );
}
