import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addConversationHandler } from "../modules/buyer/pages/Chat/service";
import {
  changeChatType,
  clearActiveChat,
  generateConversationPayload,
  setActiveChat,
} from "../utils/chat";
import Notify from "../utils/notify";
import useAuthentication from "./useAuthetication";
import { useCallback } from "react";
import { setConversations } from "../modules/buyer/pages/Chat/store";
import { setAdminConv } from "../modules/buyer/pages/Chat/store/actions";

export const useChat = () => {
  const { conversations, messages, socket, activeChat, adminConv } =
    useSelector((state) => state.chat);
  const { showModal, authenticated, buyer } = useAuthentication();
  const dispatch = useDispatch();
  const history = useHistory();

  const contactSeller = (profile) => {
    const sellerId = profile.seller.id || profile.packages[0]?.sellerId;
    const username = profile.seller.displayName;
    const avatar = profile.logoOrProfilePic;

    const sellerConvo = conversations
      .filter((conv) => conv.userType.toLowerCase() === "buyer")
      .find((conv) => conv.members.includes(username) && !conv.workstoreId);

    if (sellerConvo) {
      return {
        newMessage: false,
        params: {
          ...sellerConvo,
          name: username,
          profileImage: avatar,
          avatarId: sellerId,
        },
        messages: messages,
        conversations: conversations,
        socket: socket,
        activeChat: activeChat,
      };
    }

    const conversationPayload = generateConversationPayload({
      sellerId,
      sellerName: username,
      userDetails: buyer,
      avatar,
    });

    return {
      newMessage: true,
      params: conversationPayload,
      messages: messages,
      conversations: conversations,
    };
  };

  const initiateSellerChat = async (profile) => {
    if (!authenticated) {
      showModal("login");
      return;
    }

    const isOnVacation = !!profile?.workInfo.vacationMode;

    if (isOnVacation) {
      return Notify.success("This seller is currently on vacation");
    }

    const sellerId = profile.seller.id || profile.packages[0]?.sellerId;

    if (sellerId === buyer.seller_id) {
      return Notify.success("You cannot chat with yourself");
    }

    const chatSeller = contactSeller(profile);

    if (chatSeller.newMessage) {
      dispatch(clearActiveChat());
      await dispatch(
        addConversationHandler(
          chatSeller.params,
          chatSeller.conversations,
          chatSeller.messages
        )
      );
    }

    dispatch(setActiveChat(chatSeller.params));
    dispatch(changeChatType("buyer"));
    history.push("/peer/chats");
  };

  const setNewMessageIndicator = useCallback(
    ({ msg, isAdminChat = false }) => {
      if (isAdminChat) {
        const newAdminConv = {
          ...adminConv,
          newMessage: msg.receiverType === "buyer",
        };
        return dispatch(setAdminConv(newAdminConv));
      }
      const newConversations = conversations.map((conv) => {
        if (msg.conversationId === conv.id && msg.senderId !== conv.userId) {
          //Set new message indicator for only received message
          return {
            ...conv,
            newMessage: true,
          };
        }
        return conv;
      });

      dispatch(setConversations(newConversations));
    },
    [conversations, dispatch, adminConv]
  );

  const getUnreadConversations = useCallback(() => {
    return [...conversations, adminConv].filter((conv) => !!conv?.newMessage);
  }, [conversations, adminConv]);

  const getUnreadConversationsWithType = useCallback(
    (chatType) => {
      return conversations.filter(
        (conv) =>
          chatType === conv.userType?.toLowerCase() && !!conv?.newMessage
      );
    },
    [conversations]
  );

  return {
    chatSeller: initiateSellerChat,
    setNewMessageIndicator,
    getUnreadConversations,
    getUnreadConversationsWithType,
  };
};
