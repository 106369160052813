import { Box, Center, Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const StatCard = (props) => {
  const { title, description, isLine = true } = props;
  return (
    <Center flexDir="column" gap="0.75rem" pos="relative">
      <Heading
        fontSize={['4xl', '6xl']}
        color="white"
        textAlign="center"
        fontWeight={700}
      >
        {title}
      </Heading>
      <Text fontSize={['sx', 'md']} color="white" textAlign="center">
        {description}
      </Text>

      {isLine ? (
        <Box
          w={['full', '1px']}
          h={['1px', '6.5rem']}
          bg="borderCOlorGray"
          opacity={['25%', '50%']}
          pos="absolute"
          top={['120%', '50%']}
          right={['-50%', '-1.8125rem']}
          transform="translate(-50%, -50%)"
        />
      ) : null}
    </Center>
  );
};
