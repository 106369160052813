import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextColor } from '../../../../constants/Colors';
import {
  setAuthDetails,
  toggleAuthModal,
} from '../../../../modules/auth/store/actions';
import { PlainButton } from '../../../library/Button/style';
import Modal from '../../../library/Modal';
import Form from './form';

const LoginLink = styled(PlainButton)`
  color: ${TextColor.green};
  margin-top: 0.5rem;
`;

function SignUpModal({ show, dispatch }) {
  const toggleSignupModal = useCallback(() => {
    dispatch(toggleAuthModal('signup'));
  }, [dispatch]);

  const signupSuccess = useCallback(
    (data) => {
      toggleSignupModal();
      dispatch(toggleAuthModal('verify'));
      dispatch(setAuthDetails(data));
    },
    [dispatch, toggleSignupModal]
  );

  const showLogin = useCallback(() => {
    dispatch(toggleAuthModal('signup'));
    dispatch(toggleAuthModal('login'));
  }, [dispatch]);

  return (
    <Modal isOpen={show} toggleModal={toggleSignupModal}>
      <Modal.Content>
        <Modal.Header>Sign up</Modal.Header>
        <Modal.Body>
          <Form onSignup={signupSuccess} />
          <LoginLink $isFullWidth onClick={showLogin}>
            Already a member? Please login here
          </LoginLink>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.auth.modals.signup,
  };
};

export default connect(mapStateToProps)(SignUpModal);
