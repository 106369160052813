import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { categories } from "../../../../../components/pages/Home/categories";
import { ElementColor } from "../../../../../constants/Colors";
import { HomeSection } from "../components/HomeSection";

const Category = styled(Link)`
	background: ${ElementColor.white};
	border-radius: 5px;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 180px;
	width: 180px;
	padding: 1.5rem;
	text-align: center;
	transition: 0.3s all ease;
	border: 1px solid transparent;

	img {
		width: 70px;
		height: 70px;
	}

	&:hover {
		border-color: #83bc23;
		color: #37a000;
	}
`;

const horizontalScrolling = css`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
`;

const CategoryList = styled(Box)`
	${horizontalScrolling};
	justify-content: space-between;

	& > * + * {
		margin-left: 15px;
	}
`;

function Categories() {
	return (
		<HomeSection bg="#fafafa">
			<Heading size="xl" maxW="lg">
				Browse Freelancers Under Various Services
			</Heading>
			<CategoryList
				className="no-scrollbar"
				mt={{ base: 8, lg: 16 }}
				pt="4"
			>
				{categories.map((cat) => (
					<Category to={cat.url} key={cat.id}>
						<img src={cat.icon} alt={cat.name} />
						<span>{cat.name}</span>
					</Category>
				))}
			</CategoryList>
		</HomeSection>
	);
}

export default Categories;
