import React from 'react';
import { OptionItem, SearchOptionStyle } from './style';

function SearchOptions({ show, style, optionsRef, selectType }) {
  return (
    show && (
      <SearchOptionStyle style={style} ref={optionsRef}>
        <OptionItem type="button" onClick={() => selectType('freelancers')}>
          Freelancers
        </OptionItem>
        <OptionItem type="button" onClick={() => selectType('jobs')}>
          Jobs
        </OptionItem>
      </SearchOptionStyle>
    )
  );
}

export default SearchOptions;
