import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { buttonStyle } from './components/button';
import { Heading } from './components/heading';
import { inputGroupStyle } from './components/InputGroup';
import { menuStyle } from './components/menu';

const size = {
  xl: {
    fontSize: 'xl',
    px: 4,
    h: 16,
    borderRadius: 'lg',
  },
};

export const iconSizes = {
  xs: 12,
  sm: 16,
  md: 24,
  lg: 40,
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};
const fontSizes = {
  md: '16px',
};

const overrides = {
  config,
  colors,
  sizes: {
    icon: { ...iconSizes },
  },
  fontSizes,
  radii: {
    radius: '.5rem',
    button: '30px',
  },
  components: {
    Heading,
    Button: buttonStyle,
    InputGroup: inputGroupStyle,
    Menu: menuStyle,
    Textarea: {
      variants: {
        outline: {
          field: {
            borderColor: 'borderColor',
          },
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            fontSize: {
              base: '16px', // minimum 16px mobile to prevent ios devices from auto-zooming on inputs
              sm: 'md',
            },
          },
        },
        xl: {
          field: size.xl,
          addon: size.xl,
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: 'borderColor',
          },
        },
      },
    },
    Select: {
      sizes: {
        md: {
          field: {
            fontSize: {
              base: '16px', // minimum 16px mobile to prevent ios devices from auto-zooming on inputs
              sm: 'md',
            },
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: 'borderColor',
          },
        },
      },
    },
    Avatar: {
      sizes: {
        '3xl': {
          container: {
            w: '10rem',
            h: '10rem',
            fontSize: 'calc(10rem / 2.5)',
          },
          excessLabel: {
            w: '10rem',
            h: '10rem',
          },
          label: {
            fontSize: 'calc(10rem / 2.5)',
            lineHeight: '10rem',
          },
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: 'gray.50',
        endColor: 'gray.300',
      },
    },
  },
  styles: {
    global: {
      borderColor: colors.borderColor,
    },
  },
};

export const theme = extendTheme(overrides);
