import Model from "../../../utils/Model";

class Seller extends Model {
  constructor(props) {
      super(props);

      this.initialize(props);
  }
}


export default  Seller;