import { lazy } from "react";

const CartItems = lazy(() => import("./Routes/CartItems/CartItems"));
const MakePayment = lazy(() => import("./Routes/MakePayment/MakePayment"));
const OrderDetails = lazy(() => import("./Routes/OrderDetails/OrderDetails"));
const Timing = lazy(() => import("./Routes/Timing/Timing"));
const OrderSuccess = lazy(() => import("./Routes/OrderSuccess"));
const OrderFailure = lazy(() => import("./Routes/OrderFailure"));

const routes = [
  {
    path: "/create-order/cart/:urlName/:id",
    component: CartItems,
    exact: true,
    auth: true,
    role: "Buyers",
  },
  {
    path: "/create-order/timing/:urlName/:id",
    component: Timing,
    exact: true,
    auth: true,
    role: "Buyers",
  },
  {
    path: "/make-payment/:urlName/:profileId/:orderId",
    component: MakePayment,
    exact: true,
    auth: true,
    role: "Buyers",
  },
  {
    path: "/create-order/:urlName/:id",
    component: OrderDetails,
    exact: true,
    auth: true,
    role: "Buyers",
  },
  {
    path: "/create-order/success",
    component: OrderSuccess,
    exact: true,
    auth: true,
    role: "Buyers",
  },
  {
    path: "/create-order/failed",
    component: OrderFailure,
    exact: true,
    auth: true,
    role: "Buyers",
  },
];

export default routes;
