import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import { ElementColor, TextColor } from '../../../constants/Colors';
import { Stack } from '../layout/Stack';

export const InputWapper = styled(Stack)``;

const requiredLabel = css`
  &:after {
    content: '*';
    color: red;
    margin-left: 0.3rem;
  }
`;

const optionalLabel = css`
  &:after {
    content: '(optional)';
    color: ${TextColor.label};
    margin-left: 0.3rem;
  }
`;

export const InputLabel = styled.label`
  ${(props) => props.$required && requiredLabel}
  ${(props) => props.$optional && optionalLabel}
`;

const inputHeights = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
};

const inputMixin = css`
  border: 1px solid
    ${(props) =>
      props.$hasError ? ElementColor.danger : ElementColor.lineLight};
  border-radius: 5px;
  height: ${(props) => inputHeights[props.$size]}px;
  padding: 0 1rem;
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};
  appearance: none;
`;

export const InputStyle = styled.input`
  ${inputMixin};
`;

export const PhoneInputStyle = styled(NumberFormat)`
  ${inputMixin}
`;

export const DateInputStyle = styled.div`
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};

  .react-datepicker-wrapper {
    display: block;

    input {
      ${inputMixin}
    }
  }
`;

const quillHeights = {
  xs: 50,
  sm: 80,
  md: 150,
  lg: 200,
};

export const RichInputStyle = styled.div`
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};

  .ql-toolbar.ql-snow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    border-color: ${(props) =>
      props.$hasError ? ElementColor.danger : ElementColor.lineLight};
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    border-color: ${(props) =>
      props.$hasError ? ElementColor.danger : ElementColor.lineLight};
  }

  .quill .ql-editor {
    height: ${(props) => quillHeights[props.$size]}px;
  }
`;
