import { combineReducers } from 'redux';

import auth from '../modules/auth/store/reducer';
import buyer from '../modules/buyer/store/reducer';
import seller from '../modules/seller/store/reducer';
import web from '../modules/web/store/reducer';
import chat from '../modules/buyer/pages/Chat/store/reducer';
import order from '../modules/Order/store/reducer';
import employ from '../modules/buyer/pages/Employ/redux/reducer';
import mall from '../modules/web/pages/Mall/redux/reducer';

export default combineReducers({
  auth,
  buyer,
  seller,
  web,
  chat,
  order,
  employ,
  mall,
});
