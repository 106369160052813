import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { appEnv } from './utils/appEnv';
import { useEffect } from 'react';

const tagManagerArgs = {
  gtmId: appEnv.GTM_ID,
};

//initialize google tag manager
export const initGTM = () =>
  appEnv.GTM_ID && TagManager.initialize(tagManagerArgs);

//initialize google analytics
export const initGA = () => {
  const isGAEnabled = appEnv.GA_ID && appEnv.isProd;

  if (isGAEnabled) {
    ReactGA.initialize(appEnv.GA_ID);
  }

  return isGAEnabled;
};

export function RouteTracker() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname + search });
  }, [pathname, search]);

  return null;
}

export const Tagmanager = TagManager;
