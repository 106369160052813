const enterpriseAppHost =
  process.env.NODE_ENV === 'production'
    ? 'https://enterprise.terawork.com/'
    : process.env.NODE_ENV
    ? 'http://localhost:5151/'
    : '';
export const appEnv = {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  enterpriseAppHost,
  enterpriseOAuthUrl: `${enterpriseAppHost}oauth`,
  GA_ID: process.env.REACT_APP_GA_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
};
