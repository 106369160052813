import React from 'react';
import styled from 'styled-components';
import Quote from '../../../../../components/common/icons/Quote';
import { LargeHeading } from '../../../../../styling/heading';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
  Box,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/avatar';
import { Icon } from '@chakra-ui/icon';

import breakpoints from '../../../../../constants/breakpoints';
import { VStack } from '../../../../../components/library/layout/Stack';
import { Container } from '../../../../../styling/Layout';
import { HomeSection } from '../components/HomeSection';

import StarFillIcon from 'remixicon-react/StarFillIcon';

const QuoteImg = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: -1;
  display: none;

  @media screen and (min-width: ${breakpoints.lg}px) {
    top: 4rem;
    left: 4rem;
    display: block;
  }
`;

const Headings = styled(VStack)`
  @media screen and (min-width: ${breakpoints.lg}px) {
    text-align: left;
    max-width: 600px;
    align-items: flex-start;
    margin-left: 8rem;
    margin-top: 2rem;
  }
`;

const SubHeading = styled.p`
  text-align: center;
`;

const ReviewCard = ({
  sellerName,
  jobTitle,
  comment,
  buyerName,
  sellerAvatar,
  profileLink,
}) => {
  return (
    <LinkBox
      rounded="3xl"
      p="6"
      bg="white"
      boxShadow="rgba(33, 192, 252, 0.1) 0px 10px 15px -3px, rgba(33, 192, 252, 0.05) 0px 4px 6px -2px"
    >
      <HStack align="start" spacing="4">
        <Avatar src={sellerAvatar} name={sellerName} size="lg" />
        <VStack spacing="0">
          <LinkOverlay href={profileLink} isExternal>
            <Text fontWeight={700} fontSize="xl">
              {sellerName}
            </Text>
          </LinkOverlay>
          <Text color="gray.600" fontSize="sm">
            {jobTitle}
          </Text>
        </VStack>
      </HStack>

      <Flex mt="4">
        {Array(5)
          .fill('')
          .map((item, index) => (
            <Icon
              as={StarFillIcon}
              color="#ffbe5b"
              boxSize="1.5rem"
              key={index}
            />
          ))}
      </Flex>

      <Text mt="6">{comment}</Text>

      <Text
        color="gray.500"
        fontStyle="italic"
        mt="3"
        fontSize="sm"
        textAlign="right"
      >
        - {buyerName}
      </Text>
    </LinkBox>
  );
};

const reviews = [
  {
    sellerName: 'Phpmann',
    jobTitle: 'Backend Software Developer',
    comment:
      'I was not sure if working remotely would fit at the beginning. But I found a brilliant person to work with, so patient and concentrated on my projects. Great value for money here, and fantastic job on my website project. Recommended! Phpmann is great.',
    buyerName: 'TimWaller',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330007393',
    profileLink:
      'https://www.terawork.com/seller/phpmann/7962-get-your-php-web-application-ie-framework-eg-laravel-codeignitor',
  },
  {
    sellerName: 'Blessyn Kure',
    jobTitle: 'Voice Over Artist',
    comment:
      'Freelancer delivered a great job. Totally paid attention to detail and gave me a good customer experience. Thank you Blessing.',
    buyerName: 'Jide',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330004192',
    profileLink:
      'https://www.terawork.com/seller/blessynkure/4476-get-professional-female-voice-over-english-hausa-pidgin-english',
  },
  {
    sellerName: 'Taiwo Akinde',
    jobTitle: 'Software Developer',
    comment:
      'Excellent delivery, thank u TERAWORK for connecting me with such a remarkable talent. Highly recommended by me.',
    buyerName: 'Giftrite',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330007648',
    profileLink:
      'https://www.terawork.com/seller/taiwoakinde/8212-ill-plan-deliver-end-to-end-technology-transformation-your-biz',
  },
  {
    sellerName: 'bidofvich',
    jobTitle: 'Data Analyst',
    comment:
      'Despite the short notice, I was impressed with the level of detail and commitment. I will definitely patronize him again. Thanks, TERAWORK for attracting such great talent.',
    buyerName: 'APMDC',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330007043',
    profileLink:
      'https://www.terawork.com/seller/bidofvich/7904-conduct-data-analysis-and-interpretation-using-spss-and-python',
  },
  {
    sellerName: 'Tokunbo',
    jobTitle: 'Financial Analyst',
    comment:
      'I have been working with Tokunbo for months now. He has a very good personality and pays keen attention to his work.',
    buyerName: 'Derciti',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330004301',
    profileLink:
      'https://www.terawork.com/seller/tokunbo/4605-carry-financial-statement-analysis-and-interpretations',
  },
  {
    sellerName: 'IChannel Technologies',
    jobTitle: 'Website Developer',
    comment:
      'IChannels did fantastically well. I will recommend them anytime and any day. Femi took a creative and innovative approach to my project (website) and he took it as his own. He also has good attention to detail. Well done!!!',
    buyerName: 'Frank Aroye',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330004537',
    profileLink: 'https://www.terawork.com/seller/ichanneltechnologies/4826',
  },
  {
    sellerName: 'Kelvinaire',
    jobTitle: 'Social Media Manager',
    comment:
      "Working with Kelvinaire is always a pleasure. I've been a repeat customer for over 2 years. I really value your high quality creative work.",
    buyerName: 'Araba Homemade',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330002898',
    profileLink:
      'https://www.terawork.com/seller/kelvinaire/3409-manage-your-social-media-platforms-engaging-customers-building-your-business-brand-and-posting-contents',
  },
  {
    sellerName: 'Smoothgrafeex',
    jobTitle: 'Logo Designer',
    comment:
      'Smoothgraphex did a great job transforming my thought process into visuals. communication was great and He took to all corrections and edits with ease.Five star rating for this Lad.',
    buyerName: 'Imoni',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330003714',
    profileLink:
      'https://www.terawork.com/seller/smoothgrafeex/4003-get-a-professional-logo-uniquely-crafted-suit-your-brand',
  },
  {
    sellerName: 'The Penman',
    jobTitle: 'Proofreader',
    comment:
      'It was a good experience working with The Penman through Terawork.Well done team Tera',
    buyerName: 'Beehive',
    sellerAvatar: 'https://d17meyd56oy0jr.cloudfront.net/seller-logo/330001621',
    profileLink:
      'https://www.terawork.com/seller/thepenman/2060-lets-design-your-clear-concise-and-attractive-profile-cv-and-cover-letter',
  },
];

export const ReviewTestimonial = () => {
  return (
    <Container>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 991: 2, 1150: 3 }}>
        <Masonry gutter="20px">
          {reviews.map((review) => (
            <ReviewCard {...review} key={review.profileLink} />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Container>
  );
};

function Testimonials() {
  return (
    <HomeSection bg="secondary.200">
      <QuoteImg>
        <Quote />
      </QuoteImg>
      <Headings>
        <LargeHeading as="h2">Thousands of happy customers</LargeHeading>
        <SubHeading>Read what they are saying…</SubHeading>
      </Headings>

      <Box mt={[8, null, 12]}>
        <ReviewTestimonial />
      </Box>
    </HomeSection>
  );
}

export default Testimonials;
