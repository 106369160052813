import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BuyerAlert from "../modules/buyer/classes/Alert";
import { getPendingOrders } from "../modules/buyer/service";
import { checkPayments, isPaymentOrSellerPage } from "../utils/payment";

export default function usePendingOrders() {
  const pendingOrders = useSelector((state) => state.buyer.pendingOrders);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getPendingOrdersHandler = useCallback(() => {
    dispatch(getPendingOrders());
  }, [dispatch]);

  const completePendingOrder = useCallback(async () => {
    const checkPending = localStorage.getItem("checkPending");

    if (
      isPaymentOrSellerPage(location.pathname) ||
      checkPending === "checked"
    ) {
      return;
    }

    const paymentMade = await checkPayments(pendingOrders);

    localStorage.setItem("checkPending", "checked");

    if (paymentMade === undefined) {
      const pending = await BuyerAlert.goToPendingOrder();

      pending && history.push("/pending-orders");
    } else if (paymentMade) {
      const proceed = await BuyerAlert.notifyPendingOrderComplete();

      proceed && history.push("/transactions");
    }
  }, [history, location, pendingOrders]);

  return {
    pendingOrders,
    getPendingOrders: getPendingOrdersHandler,
    completePendingOrder,
  };
}
