import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';

import MobileHeader from './mobile';
import DesktopHeader from './desktop';
import { Inter } from '../../../../styling/Text';
import { ElementColor } from '../../../../constants/Colors';
import {
  getOngoingPromos,
  setPromoBanner,
} from '../../../../modules/web/pages/PromoStore/service';
import { PromoStatus } from '../../../../modules/web/pages/PromoStore/Page';
import usePendingOrders from '../../../../hooks/usePendingOrders';

const HeaderWrapper = styled(Inter)`
  border-bottom: 1px solid ${ElementColor.darkGrey};
  position: relative;
  z-index: 10;
`;

const pickRandom = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const filterOngoingPromo = (promos = []) => {
  return promos.filter((promo) => promo.status === PromoStatus.LIVE);
};

function Header(props) {
  const dispatch = useDispatch();
  const { ongoing, banner } = useSelector((state) => state.web.promos);

  const { pendingOrders, getPendingOrders, completePendingOrder } =
    usePendingOrders();

  useEffect(() => {
    if (!ongoing.data) {
      dispatch(getOngoingPromos());
    }

    if (ongoing.data && !banner.show) {
      //Pick a promo to show at the top
      const randomPromo = pickRandom(filterOngoingPromo(ongoing.data));
      dispatch(setPromoBanner(randomPromo));
    }
  }, [dispatch, ongoing, banner]);

  useEffect(() => {
    if (props.isAuthenticated) {
      if (!pendingOrders) {
        getPendingOrders();
      } else {
        completePendingOrder();
      }
    }
  }, [
    pendingOrders,
    getPendingOrders,
    completePendingOrder,
    props.isAuthenticated,
  ]);

  return (
    <HeaderWrapper>
      <DesktopHeader {...props} />
      <MobileHeader {...props} />
    </HeaderWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authenticated,
    application: state.auth.application,
    buyer: state.buyer.user,
    seller: state.seller.user,
  };
};

export default connect(mapStateToProps)(Header);
