import { useSelector } from "react-redux";

export function useCurrency(data, serious = true) {
  const openCurrency = useSelector((state) => state.web.selectedCurrency);
  const profileCurrency = useSelector((state) => state.buyer.currency);
  const currency = serious ? profileCurrency : openCurrency;

  return data ? currency[data] : currency;
}

export const useAuthCurrency = () => {
  const profileCurrency = useSelector((state) => state.buyer.currency || {});

  const getCurrency = (data) => {
    return data ? profileCurrency[data] : profileCurrency;
  };

  const convertNumberToPrice = (number, isDecimal = true) => {
    const priceInLocale = parseFloat(number, 10).toLocaleString(undefined, {
      minimumFractionDigits: isDecimal ? 2 : 0,
      maximumFractionDigits: isDecimal ? 2 : 0,
    });

    return `${profileCurrency?.symbol} ${priceInLocale}`;
  };

  return {
    getCurrency,
    convertNumberToPrice,
  };
};

export function useGetCurrency(id) {
  const currencies = useSelector((state) => state.web.dependencies.currencies);
  return id ? currencies.find((curr) => curr.id === +id) : currencies;
}

export function useCurrencyRates() {
  const { othersToCurrency } = useSelector((state) => state.web.netConversions);

  const getRate = (buyingCurrencyId, sellingCurrencyId) => {
    return othersToCurrency[buyingCurrencyId][sellingCurrencyId].net_rate;
  };

  return { getRate };
}
