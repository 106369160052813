import React, { Component } from "react";
import ErrorPage from "../../../modules/web/pages/ErrorPage/ErrorPage";
import ReactGA from "react-ga4";

class ErrorHandler extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    ReactGA.send({
      action: "Frontend_Error",
      category: "error",
      hitType: "Frontend_Error",
      error,
      info,
      page: window.location.pathname,
    });

    console.log(error, info);
  }

  render() {
    const { hasError } = this.state;
    return hasError ? <ErrorPage /> : this.props.children;
  }
}

export default ErrorHandler;
