import React from 'react';
import Slider from 'react-slick';
import MiniJobPostCard from '../../../../../components/UI/JobPostCard/mini';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slideSettings = {
  dots: false,
  controls: true,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
};

function HomeJobs({ jobs = [] }) {
  return (
    <Slider {...slideSettings}>
      {jobs.map((job) => (
        <MiniJobPostCard job={job._source} key={job._id} />
      ))}
    </Slider>
  );
}

export default HomeJobs;
