import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import useAuthentication from '../../../../hooks/useAuthetication';
import CurrencyChanger from '../../../../components/common/Currency/CurrencyChanger';
import { promptCurrencyChange } from '../../../../utils/currency';
import MobileApp from '../Home/sections/MobileApp';
import { TopServices } from '../Home/sections/TopServices';
import { Credits } from './components/Credits';
import { ExploreFulltime } from './components/ExploreFulltime';
import { GreatCompanies } from './components/GreatCompanies';
import { Hero } from './components/Hero';
import { HiringMadeEasy } from './components/HiringMadeEasy';
import { TalentYourWay } from './components/TalentYourWay';
import { Trustees } from './components/Trustee';
import { Testimonials } from './components/Testimonials';
import { LandingCTA } from './components/LandingCTA';
import { LandingFooter } from './components/Footer';
import { Header } from './components/Header';
import HomeSeo from './components/HomeSeo';

export const HomePage = () => {
  const { authenticated, buyer } = useAuthentication();

  useEffect(() => {
    if (authenticated) {
      //prompt users to update their currency if needed
      buyer?.wallet && promptCurrencyChange(buyer.wallet, buyer.first_name);
    }
  }, [authenticated, buyer]);

  return (
    <>
      <HomeSeo />
      <Header />
      <Hero />
      <Trustees />
      <TalentYourWay />
      <GreatCompanies />
      <HiringMadeEasy />
      <Testimonials />
      <LandingCTA />
      <ExploreFulltime />
      <Box mt={[8, 20]}>
        <TopServices title="Top Freelance Services" />
      </Box>
      <Credits />
      <MobileApp />
      <CurrencyChanger />
      <LandingFooter />
    </>
  );
};
