import styled from 'styled-components';
import { fontWeight } from '../../../../constants/sizes';
import { PlainButton } from '../../../library/Button/style';
import { VStack } from '../../../library/layout/Stack';

export const VerifyText = styled(VStack)`
  margin-bottom: 1rem;

  h3 {
    font-weight: ${fontWeight.bold};
  }
`;

export const ResendCode = styled(PlainButton)`
  margin-bottom: 1.5rem;
  width: 100%;
`;
