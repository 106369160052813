import { HStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  BUYER_APPLICATION,
  SELLER_APPLICATION,
} from '../../../../../constants/constants';
import { Bold, GreenText, Small } from '../../../../../styling/Text';
import Auth from '../../../../../utils/Auth';
import { initiateBecomeASeller } from '../../../../../utils/sellerApp';
import Button from '../../../../library/Button';
import { PlainButton } from '../../../../library/Button/style';
import DropDown from '../../../../library/dropdown';
import { VStack } from '../../../../library/layout/Stack';
import { SimpleAlertDialog } from '../../../../SimpleAlertDialog/SimpleAlertDialog';
import Headlines from '../../../Header/Headlines/Headlines';
import { MessageIcon } from './icon';
import {
  HeadlineStyle,
  Logout,
  NavAvatar,
  NavDropContainer,
  NavDropLink,
  NavDropStyle,
  NavProfile,
} from './style';

const auth = new Auth();

export const navLinks = {
  seller: [
    {
      title: 'Ongoing Jobs',
      url: '/services/work/ongoing',
      key: 'work',
    },
    {
      title: 'Portfolio',
      url: '/services/portfolio',
      key: 'portfolio',
      border: true,
    },
    {
      title: 'Chat',
      url: '/peer/chats',
      key: 'chat',
      border: true,
    },
    {
      title: 'Wallet',
      url: '/services/wallet',
      key: 'wallet',
    },
    {
      title: 'Personal Info',
      url: '/services/personal-info',
      key: 'personal',
      border: true,
    },
  ],
  buyer: [
    {
      title: 'Dashboard',
      url: '/dashboard',
      key: 'dashboard',
    },
    {
      title: 'Ongoing Jobs',
      url: '/work/ongoing',
      key: 'work',
    },
    {
      title: 'Cart',
      url: '/pending-orders',
      key: 'cart',
    },
    {
      title: 'Saved Profiles',
      url: '/saved',
      key: 'saved',
    },
    {
      title: 'My Job Posts',
      url: '/job-posts',
      key: 'jobposts',
      border: true,
    },
    {
      title: 'Chat',
      url: '/peer/chats',
      key: 'chat',
      border: true,
    },
    {
      title: 'Transactions',
      url: '/transactions',
      key: 'transactions',
    },
    {
      title: 'Personal Info',
      url: '/personal-info',
      key: 'personal',
      border: true,
    },
  ],
};

export const renderSwitcher = (application, buyerHasSellerAccount) => {
  if (application === SELLER_APPLICATION) {
    return (
      <Link to="/freelance">
        <GreenText bold $isLink>
          Switch to Buying
        </GreenText>
      </Link>
    );
  }

  return buyerHasSellerAccount ? (
    <a href="/services" target="_blank" rel="noreferrer noopener">
      <GreenText bold $isLink>
        Switch to Selling
      </GreenText>
    </a>
  ) : (
    <PlainButton type="button" onClick={initiateBecomeASeller}>
      <GreenText bold $isLink>
        Become a Seller
      </GreenText>
    </PlainButton>
  );
};

export const NavDropItem = (props) => {
  return (
    <NavDropLink to={props.url} $bordered={props.border}>
      <span>{props.title}</span>
    </NavDropLink>
  );
};

export const LogoutAlert = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <SimpleAlertDialog
        isOpen={isOpen}
        onClose={onClose}
        confirmText="Logout"
        title="Confirm Logout"
        description="Are you sure you want to logout?"
        onConfirm={auth.Logout}
        buttonProps={{
          background: 'red.500',
          colorScheme: 'red',
        }}
      />
      {children({
        onOpen,
      })}
    </>
  );
};

const NavDrop = ({
  application,
  buyer,
  seller,
  headlines,
  toggleHeadlines,
}) => {
  const activeLink = navLinks[application];

  return (
    <NavDropContainer>
      <NavDropStyle spacing="1rem">
        <NavProfile
          to={
            application === SELLER_APPLICATION
              ? '/personal-info'
              : '/services/personal-info'
          }
        >
          <HStack spacing="1.5rem">
            <NavAvatar
              src={
                application === SELLER_APPLICATION
                  ? seller?.avatar
                  : buyer.logo_or_profile_pic
              }
              alt={buyer.username}
            />
            <VStack>
              <Bold>{buyer.username}</Bold>
              <Small>{`${buyer.first_name} ${buyer.last_name}`}</Small>
            </VStack>
          </HStack>
        </NavProfile>

        {activeLink.map((link) => (
          <NavDropItem {...link} />
        ))}
        <HeadlineStyle onClick={toggleHeadlines}>Headlines</HeadlineStyle>

        <LogoutAlert>
          {({ onOpen }) => <Logout onClick={onOpen}>Logout</Logout>}
        </LogoutAlert>
      </NavDropStyle>
    </NavDropContainer>
  );
};

const ProfileButton = (props) => {
  const { application, seller, buyer } = props;
  const style = {
    right: '0',
    left: 'unset',
  };

  return (
    <DropDown
      title={
        <NavAvatar
          src={
            application === SELLER_APPLICATION
              ? seller?.avatar
              : buyer.logo_or_profile_pic
          }
          alt=""
        />
      }
      showCaret
      triggerType="hover"
      dropItemStyles={style}
    >
      <NavDrop {...props} />
    </DropDown>
  );
};

const AuthenticatedNav = (props) => {
  const { application, buyer, seller } = props;
  const buyerHasSellerAccount = !!buyer.seller_id;
  const [showHeadlines, setHeadlines] = useState(false);
  // const { pendingOrders } = usePendingOrders();

  const toggleHeadlines = () => setHeadlines((prev) => !prev);

  //Show empty till buyer data arrives
  if (application === BUYER_APPLICATION && buyer && !buyer.id) {
    return null;
  }

  //Show empty till seller data arrives
  if (application === SELLER_APPLICATION && seller && !seller.id) {
    return null;
  }

  const renderSwitcher = () => {
    if (application === SELLER_APPLICATION) {
      return (
        <Link to="/">
          <GreenText bold $isLink>
            Switch to Buying
          </GreenText>
        </Link>
      );
    }

    return buyerHasSellerAccount ? (
      <a href="/services" target="_blank" rel="noreferrer noopener">
        <GreenText bold $isLink>
          Switch to Selling
        </GreenText>
      </a>
    ) : (
      <PlainButton type="button" onClick={initiateBecomeASeller}>
        <GreenText bold $isLink>
          Become a Seller
        </GreenText>
      </PlainButton>
    );
  };

  return (
    <>
      <HStack spacing="3rem">
        {renderSwitcher(application, buyerHasSellerAccount)}
        <MessageIcon />
        {/* {application === SELLER_APPLICATION ? (
        ) : (
          <CartIcon count={pendingOrders ? pendingOrders.length : 0} />
        )} */}

        <ProfileButton toggleHeadlines={toggleHeadlines} {...props} />
      </HStack>
      {showHeadlines && (
        <Headlines
          show={showHeadlines}
          toggleShow={toggleHeadlines}
          application={application}
        />
      )}
    </>
  );
};

const UnauthenticatedNav = () => {
  const location = useLocation();
  return (
    <HStack spacing="3rem" fontWeight={500}>
      <Link
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      >
        Login
      </Link>
      <Link to="/signup">Sign Up</Link>
      <Button as={Link} to="/workstore/create">
        Post a Job
      </Button>
    </HStack>
  );
};

function HeaderRight(props) {
  const { isAuthenticated } = props;
  return (
    <div>
      {isAuthenticated ? (
        <AuthenticatedNav {...props} />
      ) : (
        <UnauthenticatedNav />
      )}
    </div>
  );
}

export default HeaderRight;
