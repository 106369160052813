import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useAuthentication from '../../../../../hooks/useAuthetication';

const renderRandomVideo = () => {
  const images = ['/videos/home-map-1', '/videos/home-map-2'];

  return images[Math.floor(Math.random() * images.length)];
};

export const Hero = () => {
  const videoToRender = useMemo(renderRandomVideo, []);
  const { authenticated } = useAuthentication();

  return (
    <Box as="section" py={[12, null, 8]} mt={[20, null, 12]}>
      <Container maxW="container.xl">
        <SimpleGrid columns={[1, null, 2]} gap={[6, 8]} alignItems="center">
          <VStack align="start" maxW="500px" spacing="4">
            <Heading as="h1" size="6xl" fontWeight={700} lineHeight="1.2">
              Reimagine the future of work
            </Heading>
            <Text fontSize="2xl">
              Access the best of Africa’s talents,
              <br /> hire without borders
            </Text>
            <Box pt="6">
              <Button
                as={Link}
                to={authenticated ? '/dashboard' : '/signup'}
                size="xl"
                minW="200px"
              >
                {authenticated ? 'Go to Dashboard' : 'Get Started'}
              </Button>
            </Box>
          </VStack>

          <Box>
            <Box as="video" loop muted autoPlay preload="auto" playsInline>
              <source src={`${videoToRender}.webm`} type="video/webm" />
              <source src={`${videoToRender}.mp4`} type="video/mp4" />
              <p>
                This is fallback content to display for user agents that do not
                support the video tag.
              </p>
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};
