import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Box, Flex, Heading, Link, Text, VStack } from '@chakra-ui/layout';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';

import { Pill } from '../../../../../components/Pill';
import { LandingSection } from './LandingSection';
import { appEnv } from '../../../../../utils/appEnv';

export const Credits = () => {
  return (
    <Box bg="bg.emphasis" mb={['20', 0]}>
      <LandingSection>
        <Flex
          flexDir={['column', null, 'row']}
          justify="space-between"
          align="center"
        >
          <VStack align="start" spacing="4">
            <Pill theme="primary">Hiring Voucher</Pill>
            <Heading size="4xl">Empower a business</Heading>
            <Text fontSize="xl" z>
              Support the growth of a local business with talent credit on
              TERAWORK
            </Text>

            <Button
              variant="link"
              as={Link}
              href={`${appEnv.enterpriseAppHost}credits`}
              px="0"
              size="xl"
              rightIcon={<Icon as={ArrowRightUpLineIcon} />}
              color="text.body"
              isExternal
            >
              Learn more
            </Button>
          </VStack>
        </Flex>
      </LandingSection>
    </Box>
  );
};
