import { useDisclosure } from '@chakra-ui/hooks';
import Auth from '../../utils/Auth';
import { SimpleAlertDialog } from '../SimpleAlertDialog';

const auth = new Auth();

export const LogoutAlert = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <SimpleAlertDialog
        isOpen={isOpen}
        onClose={onClose}
        confirmText="Logout"
        title="Confirm Logout"
        description="Are you sure you want to logout?"
        onConfirm={auth.Logout}
        buttonProps={{
          background: 'red.500',
          colorScheme: 'red',
        }}
      />
      {children({
        onOpen,
      })}
    </>
  );
};
