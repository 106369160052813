import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOngoingPromos } from './service';
// import banner from '../../../../assets/img/promo/promo-banner3.png';

//Styles
import './PromoStore.scss';
import TransparentSpinner from '../../../../components/common/Spinner/TransparentSpinner';
import PromoCard from '../../../../components/UI/PromoCard/PromoCard';
import EndedPromoCard from '../../../../components/UI/PromoCard/EndedPromoCard';
import { PromoStoreFAQ } from './components/PromoStoreFAQ';
import { Center, Heading, Text, VStack } from '@chakra-ui/react';

export const PromoStatus = {
  LIVE: 10,
  ENDED: 0,
};
function Page({ promos, dispatch }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!promos.data) {
      dispatch(getOngoingPromos());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    promos.data && setLoading(false);
  }, [promos]);

  const renderPromos = (promos = []) => {
    return promos.map((promo, index) => {
      if (promo.status === PromoStatus.LIVE) {
        return <PromoCard promo={promo} key={promo.id} index={index} />;
      } else if (promo.status === PromoStatus.ENDED) {
        return <EndedPromoCard promo={promo} key={promo.id} index={index} />;
      }

      return null;
    });
  };

  return (
    <section className="promo">
      <Center
        bgImage={'/images/promo_banner.webp'}
        className="promo-header"
        h={['25vh', '35vh']}
      >
        <VStack textAlign="center">
          <Heading as="h1" size="5xl">
            PromoStore Deals
          </Heading>

          <Text fontSize={['lg', 'xl', '2xl']} maxW="350px">
            Get discounted freelance services for your business.
          </Text>
        </VStack>
      </Center>
      {promos.data && (
        <div className="container">
          <div className="promo-list">{renderPromos(promos.data)}</div>
        </div>
      )}

      <PromoStoreFAQ />

      {loading && <TransparentSpinner />}
    </section>
  );
}

Page.propTypes = {
  promos: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

Page.defaultsProps = {
  promos: {},
};

export default Page;
