export const DEFAULT_FONT_UNIT = 'rem';
export const DEFAULT_SIZE_UNIT = 'px';

export const fontSize = {
  hero: {
    lg: 50,
    sm: 35,
  },
  default: {
    xl: 48,
    lg: 40,
    md: 24,
    normal: 18,
    sm: 14,
    placeholder: 14,
  },
  mobile: {
    xl: 34,
    lg: 30,
  },
  desktop: {
    xl: 50,
    lg: 40,
    md: 30,
  },
  md: '16px',
};

export const fontWeight = {
  thick: 800,
  bold: 600,
  medium: 500,
  normal: 400,
};
