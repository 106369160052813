import moment from 'moment';
import swal from 'sweetalert';

class BuyerAlert {
  constructor(data) {
    this.username = data.username;
    this.id = data.id;
  }

  static async requestSupport() {
    try {
      const title = await swal({
        title: 'Enter Support Title',
        text: "No need to include the seller's name",
        content: {
          element: 'input',
          attributes: {
            placeholder: 'Enter a title for your request, e.g. Logo Design Job',
            type: 'text',
            maxlength: '100',
          },
        },
      });

      return title;
    } catch (err) {
      return err;
    }
  }

  static async reopenConversation(receiver = '') {
    const reopen = await swal({
      title: 'Open Conversation',
      text: `Would you like to restart a conversation with ${receiver}?`,
      buttons: {
        confirm: {
          text: 'Yes',
          value: true,
          className: 'butn butn--green',
        },
        cancel: {
          text: 'No',
          className: 'butn butn--green--outline',
          visible: true,
          value: false,
        },
      },
    });

    return reopen;
  }

  static async promptResetBalance(newCurrencySymbol) {
    const reset = await swal({
      title: 'Change Currency',
      text: `Your Client's and Seller's Wallets' Available Balances will be reset to ${newCurrencySymbol}0.00 once this change is effect. Click 'Change' to continue or 'Cancel' to stop`,
      buttons: {
        confirm: {
          text: 'Change',
          value: true,
          className: 'butn butn--green',
        },
        cancel: {
          text: 'Cancel',
          className: 'butn butn--green--outline',
          visible: true,
          value: false,
        },
      },
    });

    return reset;
  }

  static async changeCurrency() {
    const change = await swal({
      title: 'Change Currency',
      text: `Please note that you may not be able to change your currency till the next 90 days once you change it now. Do you want to proceed?`,
      buttons: {
        confirm: {
          text: 'Yes',
          value: true,
          className: 'butn butn--green',
        },
        cancel: {
          text: 'No',
          className: 'butn butn--green--outline',
          visible: true,
          value: false,
        },
      },
    });

    return change;
  }

  static async promptCurrencyChange(firstName) {
    const change = await swal({
      title: 'New Currencies Support',
      text: `Hi ${firstName}! You may change your transaction currency to any of our supported currencies right now. Click 'Next' to proceed or 'Cancel' to stop`,
      buttons: {
        confirm: {
          text: 'Next',
          value: true,
          className: 'butn butn--green',
        },
        cancel: {
          text: 'Cancel',
          className: 'butn butn--green--outline',
          visible: true,
          value: false,
        },
      },
    });
    return change;
  }

  static async goToPendingOrder() {
    const result = await swal({
      title: 'You have pending orders',
      text: `You currently have uncomplete job orders, proceed to place your order or delete them from your cart`,
      buttons: {
        confirm: {
          text: 'Proceed',
          value: true,
          className: 'square-butn butn--green no-swal-hover',
        },
        cancel: {
          text: 'cancel',
          className: 'no-butn',
          visible: true,
          value: false,
        },
      },
    });

    return result;
  }

  static async notifyPendingOrderComplete() {
    const result = await swal({
      title: 'Order Completed',
      text: `Your pending job order has been completed, click 'Proceed' to continue or 'Cancel' to stay here`,
      buttons: {
        confirm: {
          text: 'Proceed',
          value: true,
          className: 'square-butn butn--green no-swal-hover',
        },
        cancel: {
          text: 'cancel',
          className: 'no-butn',
          visible: true,
          value: false,
        },
      },
    });

    return result;
  }

  static async contactBeforeOrder() {
    const result = await swal({
      title: '',
      text: 'This freelancer advised that you first chat them before ordering their packages. Please do so to ensure they will be available to handle your requests.',
      buttons: {
        proceed: {
          text: 'Continue',
          className: 'no-butn black-text no-swal-hover',
          visible: true,
          value: true,
        },
        chat: {
          text: 'Chat',
          value: 'chat',
          className: 'square-butn butn--green no-swal-hover',
        },
      },
    });

    return result;
  }

  static async postRevisionAlert(work) {
    await swal({
      title: '',
      text: `A ticket will automatically be opened for this job on ${moment(
        work.escalation_date
      ).format('ll')} if pending tasks or corrections are not completed`,
    });
  }
}

export default BuyerAlert;
