import axios from 'axios';
import {
  API_URL,
  API_SUCCESS,
  API_FAIL,
  PUBLIC_API_URL,
} from '../constants/constants';
import Auth from './Auth';
import Notify from './notify';
import Transformer from './transformer';

const auth = new Auth();

const apiError = async (error) => {
  if (error.response?.status === 401) {
    if (auth.isAuthenticated()) {
      // await auth.refreshSession(() => window.location.reload());
      return Promise.reject(error);
    }

    window.location.href = '/login';
  }
  return Promise.reject(error);
};

//Connects to Godman API
const api = axios.create({
  baseURL: API_URL,
});

api.defaults.headers.common.Accept = 'application/json';
api.interceptors.response.use((response) => response, apiError);

//Connects to EmmaPublic API
const publicApi = axios.create({
  baseURL: PUBLIC_API_URL,
});
publicApi.defaults.headers.common.Accept = 'application/json';
publicApi.interceptors.response.use((response) => response, apiError);

export const requestApi = async (
  method,
  path,
  data,
  authenticated,
  headers = {}
) => {
  const params = [path];
  data && params.push(data);
  params.push({
    headers: {
      ...(authenticated && {
        Authorization: `Bearer ${auth.getUserToken('idToken')}`,
      }),
      ...headers,
    },
  });

  try {
    const response = await api[method](...params);

    const { data } = response.data;
    return {
      type: API_SUCCESS,
      data: data,
    };
  } catch (err) {
    const statusCode = err.response?.status ?? '';

    const data = {
      error: null,
      type: API_FAIL,
      statusCode,
    };

    if (statusCode === 422) {
      data.data = err.response?.data?.error?.message ?? '';
    } else if (statusCode === 500) {
      data.data = err.response?.data?.message ?? '';
      Notify.error(data.data);
    } else if (statusCode === 401) {
      data.data = err.response?.data?.message ?? '';
    } else {
      data.data = err.response?.data?.error?.message ?? '';
      data.data && Notify.error(data.data);
    }

    return data;
  }
};

export const requestPublicApi = async (method, path, data, authenticated) => {
  const params = [path];
  data && params.push(data);
  authenticated &&
    params.push({
      headers: {
        Authorization: `Bearer ${auth.getUserToken('idToken')}`,
      },
    });

  try {
    const response = await publicApi[method](...params);

    const { data } = response.data;
    return {
      type: API_SUCCESS,
      data: data,
    };
  } catch (err) {
    const statusCode = err.response && err.response.status;

    const data = {
      error: null,
      type: API_FAIL,
    };

    if (statusCode === 422) {
      data.error = err.response?.data?.error;
    } else if (statusCode === 401) {
      data.error = err.response?.data?.message ?? '';
      Notify.error(err.response?.data?.message ?? '');
    }

    return data;
  }
};

export const requestThirdPartyData = async (method, url) => {
  try {
    let res = await axios[method](url);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const requestHandler = async ({
  method = 'get',
  path,
  data,
  headers = {},
}) => {
  const params = [path];
  data && params.push(data);
  params.push({
    headers: {
      ...headers,
    },
  });

  try {
    const response = await api[method](...params);

    const { data } = response.data;
    return {
      type: API_SUCCESS,
      data: data,
    };
  } catch (err) {
    const statusCode = err.response?.status ?? '';

    const data = {
      error: null,
      type: API_FAIL,
      statusCode,
    };

    if (statusCode === 422) {
      data.error = Transformer.resetValidationFields({
        errors: err.response?.data?.error?.message ?? {},
      });
    } else if (statusCode === 500) {
      data.error = err.response?.data?.message ?? '';
      Notify.error(data.error);
    } else if (statusCode === 401) {
      data.error = err.response?.data?.message ?? '';
    } else {
      data.error = err.response?.data?.error?.message ?? '';
      data.error && Notify.error(data.error);
    }

    return data;
  }
};

export { api, publicApi };
