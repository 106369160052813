import React from 'react';

// import AppBuilder from './container/AppBuilder'
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Routes from './routes';
import Auth from './utils/Auth';
import { setApplicationReq, setAuthReq } from './modules/auth/service';
import { checkSellerRoute } from './utils/UrlParameter';
import * as GA from './GoogleAnalytics';
import { sentryConfig } from './utils/sentry';

//Inititate Sentry
Sentry.init({ ...sentryConfig });

//Initate Google Analytics
GA.initGTM();
const isGAEnabled = GA.initGA();

const App = ({ dispatch }) => {
  const auth = new Auth();

  //Initiate Application
  dispatch(setApplicationReq(checkSellerRoute() ? 'seller' : 'buyer'));
  dispatch(setAuthReq(auth.isAuthenticated()));

  return (
    <BrowserRouter>
      {isGAEnabled && <GA.RouteTracker />}
      <Routes />
    </BrowserRouter>
  );
};

export default connect(null)(App);
