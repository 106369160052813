import { Button } from "@chakra-ui/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthetication";
import { resendConfirmationCode } from "../../modules/auth/service";
import { Banner } from "../Banner";

export const VerifyBar = () => {
  const { buyer } = useAuthentication();
  const history = useHistory();
  const location = useLocation();

  const initVerification = async () => {
    const [err] = await resendConfirmationCode({ email: buyer.email });

    if (err) {
      return;
    }

    localStorage.setItem("VerificationEmail", buyer.email);
    history.push(`/verify-code?referrer=${encodeURI(location.pathname)}`);
  };

  return (
    <>
      <Banner showCloseButton={false}>
        Your account is pending verification!{" "}
        <Button
          variant="unstyled"
          textDecor="underline"
          onClick={initVerification}
        >
          Verify Now
        </Button>
      </Banner>
    </>
  );
};
