import React from 'react';
import SignupBody from '../../../pages/Signup/SignupBody';
import '../../../pages/Signup/Signup.scss';

function Form({ onSignup }) {
  return (
    <SignupBody type="Buyer" endpoint="buyer-signup" onSignup={onSignup} />
  );
}

export default Form;
