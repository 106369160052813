import {
  ADD_SERVICE,
  ADD_FAQ_TO_SERVICE,
  ADD_SERVICE_PROFILE,
  EXTRACT_PROFILE,
  ADD_SEARCH_RESULT,
  SET_CATEGORIES,
  SET_SELLER_PROFILE,
  SET_PROFILE_COLLECTION,
  SET_FAQS,
} from "./action-types";

export const addService = (payload) => {
  return {
    type: ADD_SERVICE,
    payload,
  };
};
export const addFaqToService = (serviceString, faq) => {
  return {
    type: ADD_FAQ_TO_SERVICE,
    payload: {
      serviceString,
      faq,
    },
  };
};

export const addServiceProfiles = (payload) => {
  return {
    type: ADD_SERVICE_PROFILE,
    payload,
  };
};

export const extractProfiles = (payload) => {
  return {
    type: EXTRACT_PROFILE,
    payload,
  };
};

export const addSearchResult = (payload) => {
  return {
    type: ADD_SEARCH_RESULT,
    payload,
  };
};

export const setCategories = (payload) => {
  return {
    type: SET_CATEGORIES,
    payload,
  };
};

export const setSellerProfile = (payload) => {
  return {
    type: SET_SELLER_PROFILE,
    payload,
  };
};

export const setProfileCollection = (payload) => {
  return {
    type: SET_PROFILE_COLLECTION,
    payload,
  };
};

export const setFaqs = (payload) => {
  return {
    type: SET_FAQS,
    payload,
  };
};
