import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const isColumn = (direction) => {
  const columnDirections = ['column', 'column-reverse'];

  return columnDirections.includes(direction);
};

const isRow = (direction) => {
  const rowDirections = ['row', 'row-reverse'];

  return rowDirections.includes(direction);
};

const StackStyle = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$direction};
  align-items: ${(props) => props.$alignItems};
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};

  & > * ~ * {
    margin-top: ${(props) => (isColumn(props.$direction) ? props.$spacing : 0)};
    margin-inline-start: ${(props) =>
      isRow(props.$direction) ? props.$spacing : 0};
  }
`;

export const Stack = (props) => {
  const {
    direction = 'column',
    spacing = '0.5rem',
    as,
    children,
    alignItems = 'center',
    isFullWidth = true,
    stackAs,
    ...rest
  } = props;

  return (
    <StackStyle
      $direction={direction}
      $spacing={spacing}
      $alignItems={alignItems}
      $isFullWidth={isFullWidth}
      as={as || stackAs}
      {...rest}
    >
      {children}
    </StackStyle>
  );
};

export const HStack = (props) => {
  const { children, ...rest } = props;
  return (
    <Stack direction="row" {...rest}>
      {children}
    </Stack>
  );
};

export const VStack = (props) => {
  const { children, alignItems = 'flex-start', ...rest } = props;
  return (
    <Stack direction="column" alignItems={alignItems} {...rest}>
      {children}
    </Stack>
  );
};

StackStyle.propTypes = {};

const StackTypes = {
  spacing: PropTypes.string,
  as: PropTypes.string,
  alignItems: PropTypes.string,
};

Stack.propTypes = {
  direction: PropTypes.string,
  ...StackTypes,
};

HStack.propTypes = {
  ...StackTypes,
};

VStack.propTypes = {
  ...StackTypes,
};
