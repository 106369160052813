import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../../library/Button';
import Input from '../../../library/Input';
import { VStack } from '../../../library/layout/Stack';
import Textarea from '../../../library/Textarea';
import { openTicket } from './service';

function Form({ post }) {
  const [data, setData] = useState({
    email: '',
    custom_request: '',
    phone_no: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await openTicket({
      ...data,
      start_date: moment().format(),
    });
    setLoading(false);
    post(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing="1rem">
        <Input
          type="email"
          value={data.email}
          label="Email"
          name="email"
          onChange={handleChange}
          isRequired
        />
        <Input
          type="tel"
          value={data.phone_no}
          label="Phone Number"
          name="phone_no"
          onChange={handleChange}
          isRequired
        />
        <Textarea
          value={data.custom_request}
          name="custom_request"
          label="Tell us about the job"
          onChange={handleChange}
          isRequired
        />
        <Button type="submit" size="lg" isLoading={loading} isFullWidth>
          Get Started
        </Button>
      </VStack>
    </form>
  );
}

export default Form;
