import moment from 'moment';
import store from '../../../../store';

const { default: Model } = require('../../../../utils/Model');

class JobPost extends Model {
  constructor(props) {
    super(props);
    this.initialize(props);
    this.APPROVED_STATUS = 10;
    this.DECLINED_STATUS = 2;
  }

  findCurrency(id) {
    const state = store.getState();
    return (
      state.web.dependencies?.currencies?.find((curr) => curr.id === id) || {}
    );
  }

  findCountry(id) {
    const state = store.getState();
    return (
      state.web.dependencies?.countries?.find((count) => count.id === id) || {}
    );
  }

  isSameUser(jobBuyerId) {
    const { auth, buyer } = store.getState();

    if (auth.authenticated) {
      return +jobBuyerId === +buyer.user.id;
    }

    return false;
  }

  getSlotsLeft(props) {
    const slotsCount = props.max_no_of_expected_response - props.no_of_replies;
    return slotsCount > 0 ? slotsCount : 'Closed';
  }

  initialize(props) {
    super.initialize(props);

    this.id = props.message_id;
    this.title = props.title || 'No Title';
    this.status = {
      value: props.stage || '',
      color: props.stage === 'Open' ? 'green' : 'gray',
    };
    this.currency = this.findCurrency(props.currency_id);
    this.rawText = props.custom_request.replace(/<[^>]*>|&nbsp;/g, '');
    this.miniDescription = this.rawText.substr(0, 150);
    this.budget =
      Number(props.budget) >= 10
        ? `${this.currency?.symbol ?? '₦'}${Number(
            props.budget
          ).toLocaleString()}`
        : 'Flexible';
    this.localPrice = props.budget;
    this.posted = moment(props.post_date).fromNow();
    this.responses =
      props.no_of_replies > 2 ? props.no_of_replies : 'Less than 5';
    this.startDate = moment(props.work_start_date).format('MMM Do, YYYY');
    this.slotLeft = this.getSlotsLeft(props);
    this.country = props.country_id
      ? this.findCountry(props.country_id).name
      : (props.buyer && this.findCountry(props.buyer.country_id).name) ||
        'Nigeria';
    this.type = props.project_type;
    this.location = props.country || this.country;
    this.project_type =
      props.project_type === 'One_off' ? 'One-off' : props.project_type;
    this.isMyJob = this.isSameUser(props.buyer_id);

    this.duration = props.job_duration
      ? `${props.job_duration} ${props.job_duration_unit || 'days'}`
      : 'a few days';
  }
}

export default JobPost;
