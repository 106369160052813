import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Link,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import MenuLineIcon from 'remixicon-react/MenuLineIcon';
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon';
import { Logo } from '../../../../../components/layout/new/Header/mobile/styles';
import { navLinks } from './navLinks';
import useAuthentication from '../../../../../hooks/useAuthetication';
import { LogoutAlert } from '../../../../../components/LogoutAlert';

const navTitles = ['Products', 'Find Talents', 'Find Jobs'];

export const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authenticated } = useAuthentication();

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody bg="white" py="8">
            <Box as={RouterLink} to="/" mt="8">
              <Logo
                w="258px"
                src="/images/logo_black.svg"
                alt="terawork logo"
              />
            </Box>

            <Divider borderColor="borderColor" my="6" />

            <VStack align="stretch">
              {Object.values(navLinks).map((links, index) => (
                <Accordion display={{ lg: 'none' }} allowToggle>
                  <AccordionItem w="full" border="none">
                    <AccordionButton
                      w="full"
                      display="flex"
                      justifyContent="space-between"
                      px="0"
                      as={Button}
                      variant="unstyled"
                      fontWeight={400}
                      color="text.body"
                    >
                      <Text textAlign="center">{navTitles[index]}</Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <VStack align="start" fontSize="sm">
                        {links.map(({ title, url, isExternal }) => (
                          <Link
                            {...(isExternal
                              ? { href: url, isExternal: true }
                              : { as: RouterLink, to: url })}
                            py="1"
                            key={title}
                          >
                            {title}
                          </Link>
                        ))}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </VStack>

            <Divider my="6" borderColor="borderColor" />
            {authenticated ? (
              <LogoutAlert>
                {({ onOpen }) => (
                  <Button
                    variant="link"
                    size="lg"
                    leftIcon={<Icon as={LogoutBoxLineIcon} />}
                    py="4"
                    onClick={onOpen}
                  >
                    Logout
                  </Button>
                )}
              </LogoutAlert>
            ) : (
              <>
                <RouterLink to="/login" isExternal>
                  Login
                </RouterLink>

                <Button
                  as={RouterLink}
                  to="/signup"
                  isExternal
                  w="full"
                  colorScheme="primary"
                  mt="6"
                  size="lg"
                >
                  Sign up
                </Button>
              </>
            )}
          </DrawerBody>
          <DrawerCloseButton />
        </DrawerContent>
      </Drawer>
      <IconButton
        variant="ghost"
        size="md"
        display={{ lg: 'none' }}
        aria-label="show nav"
        onClick={onOpen}
      >
        <Icon as={MenuLineIcon} boxSize="icon.sm" />
      </IconButton>
    </>
  );
};
