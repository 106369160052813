import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useContext } from 'react';
import { ModalContext } from '.';
import { ElementColor } from '../../../constants/Colors';
import { PlainButton } from '../Button/style';
import { ModalHeader } from './style';

function Header({ children, showCloseButton = true }) {
  const { toggleModal } = useContext(ModalContext);
  return (
    <ModalHeader>
      <div>{children}</div>
      {showCloseButton && (
        <PlainButton type="button" onClick={toggleModal}>
          <FontAwesomeIcon icon={faTimes} color={ElementColor.darkGrey} />
        </PlainButton>
      )}
    </ModalHeader>
  );
}

export default Header;
