import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MobileSearchForm } from "./style";
import { MobileAndTablet } from "../../../styling/breakpoints";
import { useRef } from "react";
import { Button, Input, VStack } from "@chakra-ui/react";

function MobileSearch({ text, onChange, autoFocus, ...props }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.click(() => {
        inputRef.current.focus();
      });
    }
  }, [autoFocus]);

  return (
    <MobileAndTablet>
      <VStack w="full" align="stretch" spacing="4">
        <MobileSearchForm>
          <Input
            value={text}
            onChange={onChange}
            placeholder="Try “writing blog article”"
            size="lg"
            w="full"
            border="none"
            outline="none"
            _focus={{ outline: "none" }}
            maxLength={40}
            {...props}
          />
        </MobileSearchForm>
        <Button type="submit" size="xl">
          Search
        </Button>
      </VStack>
    </MobileAndTablet>
  );
}

MobileSearch.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default MobileSearch;
