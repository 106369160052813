import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/layout';
import { StatCard } from './StatCard';

export const GreatCompanies = () => {
  return (
    <Box bg="bg.black">
      <Container
        h="full"
        maxW="container.xl"
        pb={['3.5rem', '6.5rem']}
        pt={['3rem', '6rem']}
        pos="relative"
      >
        <Heading fontSize={['2xl', '5xl']} textAlign="center" color="white">
          This is where great companies hire great talents
        </Heading>

        <SimpleGrid columns={[1, null, 3]} gap="3.625rem" mt={[12, null, 36]}>
          <StatCard
            title="10%"
            description="Top quality talents at your fingertips"
          />
          <StatCard title="99%" description="Client satisfaction rate" />
          <StatCard
            title="30 mins"
            description="Hire vetted talent in minutes"
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
