export const priceList = {
  ngn: { graphics: 3500000, web: 150000, book: 100000, brand: 25000 },
  usd: { graphics: 10000, web: 500, book: 300, brand: 40 },
  eur: { graphics: 9000, web: 400, book: 250, brand: 50 },
  gbp: { graphics: 7000, web: 300, book: 200, brand: 30 },
  aed: { graphics: 35000, web: 1300, book: 1000, brand: 140 },
  aud: { graphics: 13000, web: 450, book: 300, brand: 60 },
  brl: { graphics: 47500, web: 1700, book: 1200, brand: 180 },
  cad: { graphics: 12500, web: 450, book: 270, brand: 50 },
  ghs: { graphics: 75000, web: 2500, book: 1800, brand: 300 },
  inr: { graphics: 750000, web: 27000, book: 20000, brand: 3000 },
  kes: { graphics: 1150000, web: 40000, book: 28000, brand: 4500 },
  nzd: { graphics: 15000, web: 550, book: 400, brand: 60 },
  rwf: { graphics: 10000000, web: 360000, book: 250000, brand: 40000 },
  zar: { graphics: 150000, web: 6000, book: 4000, brand: 650 },
};
