import Seller from '../Model/Seller';

import {
  ADD_SELLER_PROFILE,
  SET_SELLERDATA,
  SET_WORKINFO,
  SET_WORK_INFO_ADMIN,
  SET_WORK_PROFILE,
  SET_PROFILES,
  SET_ONGOING_WORK,
  SET_WORK_HISTORY,
  ADD_PROMO_PROFILE,
  SET_WALLET,
  SET_REVIEWS,
  SET_NOTIFICATIONS,
  SET_PROFILE_PREVIEW,
  SET_QUALIFICATION,
  SET_SERVICE_INFO_ADMIN,
  SET_JOB_TITLES,
} from './actionTypes';

const initialState = {
  user: new Seller({}),
  workInfo: null,
  workProfile: null,
  profiles: [],
  qualifications: null,
  promoProfiles: {},
  notifications: null,
  previews: {},
  jobTitles: null,
  sellerProfile: {},
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case SET_SELLERDATA:
      return setSellerData(state, payload);
    case SET_WORKINFO:
      return setWorkInfo(state, payload);
    case SET_WORK_INFO_ADMIN:
      return setWorkInfoAdmin(state, payload);
    case SET_QUALIFICATION:
      return setQualification(state, payload);
    case SET_WORK_PROFILE:
      return setWorkProfile(state, payload);
    case SET_PROFILES:
      return setProfiles(state, payload);
    case SET_ONGOING_WORK:
      return setOngoingWork(state, payload);
    case SET_WORK_HISTORY:
      return setWorkHistory(state, payload);
    case ADD_PROMO_PROFILE:
      return addPromoProfile(state, payload);
    case SET_WALLET:
      return setWallet(state, payload);
    case SET_REVIEWS:
      return setReviews(state, payload);
    case SET_NOTIFICATIONS:
      return setNotifications(state, payload);
    case SET_PROFILE_PREVIEW:
      return setProfilePreview(state, payload);
    case SET_SERVICE_INFO_ADMIN:
      return setServiceInfoAdmin(state, payload);
    case SET_JOB_TITLES:
      return setJobTitles(state, payload);
    case ADD_SELLER_PROFILE:
      return addSellerProfile(state, payload);
    default:
      return state;
  }
};

function setOngoingWork(state, payload) {
  return {
    ...state,
    ongoingWork: payload,
  };
}

function setWorkHistory(state, payload) {
  return {
    ...state,
    workHistory: payload,
  };
}

function setSellerData(state, payload) {
  return {
    ...state,
    user: payload,
  };
}

function setWorkInfo(state, payload) {
  return {
    ...state,
    workInfo: payload,
  };
}

function setWorkInfoAdmin(state, payload) {
  return {
    ...state,
    workInfoAdmin: payload,
  };
}

function setQualification(state, payload) {
  return {
    ...state,
    qualifications: payload.qualifications,
    qualificationsAdmin: payload.qualification_admins,
  };
}

function setWorkProfile(state, payload) {
  return {
    ...state,
    workProfile: payload,
  };
}

function setProfiles(state, payload) {
  return {
    ...state,
    profiles: payload,
  };
}

function addPromoProfile(state, payload) {
  return {
    ...state,
    promoProfiles: {
      ...state.promoProfiles,
      [`profile_${payload.id}`]: payload,
    },
  };
}

function setWallet(state, payload) {
  return {
    ...state,
    wallet: payload,
  };
}
function setReviews(state, payload) {
  return {
    ...state,
    reviews: payload,
  };
}

function setNotifications(state, payload) {
  return {
    ...state,
    notifications: payload,
  };
}

function setProfilePreview(state, payload) {
  return {
    ...state,
    previews: { ...state.previews, [payload.profileId]: payload },
  };
}

function setServiceInfoAdmin(state, payload) {
  return {
    ...state,
    serviceInfoAdmin: payload,
  };
}

function setJobTitles(state, payload) {
  return {
    ...state,
    jobTitles: payload,
  };
}

function addSellerProfile(state, payload) {
  return {
    ...state,
    sellerProfile: { ...state.sellerProfile, [payload.id]: payload },
  };
}

export default reducer;
