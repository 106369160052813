import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTransition } from 'react-spring';
import useOverlayUtility from '../../../hooks/useOverlayUtility';
import { ModalStyle } from './style';
import Content, { CloseButton } from './content';
import Header from './header';
import Body from './body';
import Footer from './footer';

export const ModalContext = React.createContext({});

function Modal({ isOpen, toggleModal, children, ...props }) {
  const { showBackDrop = true } = props;

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, transform: 'translateY(-40px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
  });

  const contextValues = useMemo(
    () => ({
      isOpen,
      toggleModal,
    }),
    [isOpen, toggleModal]
  );

  useOverlayUtility(isOpen, toggleModal);

  return (
    <ModalContext.Provider value={contextValues}>
      {transitions(
        (styles, item) =>
          item && (
            <ModalStyle $showBackDrop={showBackDrop} style={{ ...styles }}>
              {children}
            </ModalStyle>
          )
      )}
    </ModalContext.Provider>
  );
}

Modal.Content = Content;
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showBackDrop: PropTypes.bool,
};

export default Modal;
