import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthetication";
import { resendConfirmationCode } from "../../modules/auth/service";
import { PageMain } from "../../styling/Layout";

export const VerifyModal = () => {
  const { isOpen, onClose } = useDisclosure({ isOpen: true });
  const { buyer } = useAuthentication();

  const history = useHistory();
  const location = useLocation();

  const initVerification = async () => {
    const [err] = await resendConfirmationCode({ email: buyer.email });

    if (err) {
      return;
    }
    onClose();
    localStorage.setItem("VerificationEmail", buyer.email);
    history.push(`/verify-code?referrer=${encodeURI(location.pathname)}`);
  };

  return (
    <PageMain>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Account</ModalHeader>
          <ModalBody>
            <Text mb="2">Hello {buyer.first_name}!</Text>
            <Text mb="8">
              Your account is pending verification, kindly verify to proceed.
            </Text>
            <Button
              variant="primary"
              d="block"
              mx="auto"
              mb="6"
              onClick={initVerification}
            >
              Verify Now
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageMain>
  );
};
