export const getAllowedInputType = (type = '') => {
  const allowedTypes = [
    'date',
    'datetime-local',
    'email',
    'file',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ];

  return allowedTypes.includes(type) ? type : 'text';
};
