/* eslint-disable */

export const cookies = {
  getItem(sKey) {
    if (typeof document !== "object") return null;

    if (!sKey) {
      return null;
    }
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null
    );
  },

  setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (typeof document !== "object") return false;
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    var sExpires = "";
    if (vEnd) {
      if (typeof vEnd === "number") {
        sExpires =
          vEnd === Infinity
            ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
            : "; max-age=" + vEnd;
      } else if (typeof vEnd === "string") {
        sExpires = "; expires=" + vEnd;
      } else if (vEnd instanceof Date) {
        sExpires = "; expires=" + vEnd.toUTCString();
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=" +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      "; path=" +
      (sPath || "/") +
      (bSecure ? "; secure" : "");
    return true;
  },

  removeItem(sKey, sPath, sDomain) {
    if (typeof document !== "object") return false;
    if (!this.hasItem(sKey)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (sDomain ? "; domain=" + sDomain : "") +
      "; path=" +
      (sPath || "/");
    return true;
  },

  hasItem(sKey) {
    if (typeof document !== "object") return false;
    if (!sKey) {
      return false;
    }
    return new RegExp(
      "(?:^|;\\s*)" +
        encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie);
  },

  keys() {
    if (typeof document !== "object") return [];
    var aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  },
};
