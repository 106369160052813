import Notify from './notify';
import { compareStorageKey } from '../constants/constants';

const setCompareLimit = () => {
  const w = window,
    d = document,
    documentElement = d.documentElement,
    body = d.getElementsByTagName('body')[0],
    width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
  if (width < 576) {
    return 2;
  } else if (width < 992) {
    return 3;
  } else {
    return 4;
  }
};

export const compare = {
  limit: setCompareLimit(),

  save: function (url) {
    const savedCompare = this.get();

    if (!savedCompare.includes(url) && savedCompare.length < this.limit) {
      localStorage.setItem(compareStorageKey + (savedCompare.length + 1), url);
      Notify.success(`${savedCompare.length + 1} of ${this.limit} selected!`);
      return true;
    } else if (savedCompare.includes(url)) {
      Notify.error('Service already selected');
      return false;
    } else {
      Notify.error('Maximum number reached');
      return false;
    }
  },

  get: function () {
    let compareStorage = [];

    //Get all the saved sellers from localStorage if any
    for (let i = 1; i <= this.limit; i++) {
      const seller = localStorage.getItem(compareStorageKey + i);
      if (seller) {
        compareStorage.push(seller);
      }
    }
    return compareStorage;
  },

  clearAll: function () {
    for (let i = 1; i <= 4; i++) {
      const seller = localStorage.getItem(`x_comp_id${i}`);
      if (seller) {
        localStorage.removeItem(`x_comp_id${i}`);
      }
    }

    setTimeout(() => {
      window.history.back();
    }, 500);
  },
};
