import {
  ACTIVE_MENU,
  ADD_PACKAGE,
  CHANGE_QUANTITY,
  CLEAR_SELECTED_PACKAGES,
  REMOVE_DISCOUNT,
  REMOVE_PACKAGE,
  SET_DELIVERY_METHOD,
  SET_DISCOUNT_AMOUNT,
  SET_LOCATION,
  SET_ORDER_DETAILS,
  SET_PACKAGES,
  SET_PAYMENT_CHARGE,
  SET_SOURCE,
  SET_START_DATE,
  SET_STATE,
  SET_SUB_TOTAL,
  SET_TOTAL,
  SET_VOUCHER,
} from './action-types';

/*Menu*/
export const setActiveMenu = (payload) => {
  return {
    type: ACTIVE_MENU,
    payload,
  };
};

export const addPackage = (payload) => {
  return {
    type: ADD_PACKAGE,
    payload,
  };
};

export const removePackage = (payload) => {
  return {
    type: REMOVE_PACKAGE,
    payload,
  };
};

export const clearSelectedPackages = () => {
  return {
    type: CLEAR_SELECTED_PACKAGES,
  };
};

export const setPackages = (payload) => {
  return {
    type: SET_PACKAGES,
    payload,
  };
};

export const changeQuantity = (payload) => {
  return {
    type: CHANGE_QUANTITY,
    payload,
  };
};

export const setOrderDetails = (payload) => {
  return {
    type: SET_ORDER_DETAILS,
    payload,
  };
};

export const setDeliveryMethod = (payload) => {
  return {
    type: SET_DELIVERY_METHOD,
    payload,
  };
};

export const setLocation = (payload) => {
  return {
    type: SET_LOCATION,
    payload,
  };
};

export const setVoucher = (payload) => {
  return {
    type: SET_VOUCHER,
    payload,
  };
};

export const setStartDate = (payload) => {
  return {
    type: SET_START_DATE,
    payload,
  };
};

export const setSubTotal = (payload) => {
  return {
    type: SET_SUB_TOTAL,
    payload,
  };
};

export const setTotal = (payload) => {
  return {
    type: SET_TOTAL,
    payload,
  };
};

export const setSource = (payload) => {
  return {
    type: SET_SOURCE,
    payload,
  };
};

export const setState = (payload) => {
  return {
    type: SET_STATE,
    payload,
  };
};

export const setDiscountamout = (payload) => {
  return {
    type: SET_DISCOUNT_AMOUNT,
    payload,
  };
};

export const removeDiscount = () => {
  return {
    type: REMOVE_DISCOUNT,
  };
};

export const setPaymentCharge = (payload) => {
  return {
    type: SET_PAYMENT_CHARGE,
    payload,
  };
};
