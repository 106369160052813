import React from 'react';
import { Route } from 'react-router-dom';
import CurrencyChanger from '../components/common/Currency/CurrencyChanger';
import { MainAppLayout } from '../components/layout/MainAppLayout';
import HeaderCategories from '../components/layout/new/Header/categories';
import PromoBanner from '../components/layout/new/Header/promo-banner';
import { VerifyBar } from '../components/VerifyBar';
import useAuthentication from '../hooks/useAuthetication';
import { Inter } from '../styling/Text';
import { getPath } from '../utils/UrlParameter';
import TalkToUs from '../components/UI/TalkToUs';

const PublicRoutes = ({
  component: Component,
  layout: Layout = MainAppLayout,
  fullScreen,
  showPromoBanner = true,
  showCurrencyChanger = true,
  ...rest
}) => {
  const isPromoRoute = getPath(rest.path, 1) === 'promo';
  const { authenticated, buyer } = useAuthentication();

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {showPromoBanner && (
            <Inter>
              {authenticated && buyer?.status === 1 ? (
                <VerifyBar />
              ) : (
                // <BeyondWorkBanner />
                <PromoBanner showBanner={!isPromoRoute} />
              )}
              {fullScreen ? null : <HeaderCategories />}
            </Inter>
          )}

          <Component {...props} />
          {showCurrencyChanger && <CurrencyChanger />}
          <TalkToUs />
        </Layout>
      )}
    />
  );
};

export default PublicRoutes;
