import {
  SET_APLLICATION,
  SET_AUTH,
  AUTH_LOGIN,
  AUTH_SIGNUP,
  SET_SIGN_UP,
  SET_SIGN_UP_URL,
  TOGGLE_AUTH_MODAL,
  SAVE_AUTH_DETAILS,
} from './actionTypes';

const initialState = {
  authenticated: false,
  application: '',
  token: null,
  submitted: false,
  signup: null,
  signupUrl: '/',
  modals: {
    signup: false,
    login: false,
    verify: false,
    jobpost: false,
  },
  authDetails: null,
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case SET_APLLICATION:
      return setApplication(state, payload);
    case SET_AUTH:
      return setAuth(state, payload);
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_SIGNUP:
      return signUp(state, payload);
    case SET_SIGN_UP:
      return setSignUp(state, payload);
    case SET_SIGN_UP_URL:
      return setSignupUrl(state, payload);
    case TOGGLE_AUTH_MODAL:
      return toggleAuthModal(state, payload);
    case SAVE_AUTH_DETAILS:
      return setAuthDetails(state, payload);
    default:
      return state;
  }
};

function setApplication(state, payload) {
  return {
    ...state,
    application: payload,
  };
}

const setAuth = (state, payload) => {
  return {
    ...state,
    authenticated: payload,
  };
};

const login = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    token: payload,
  };
};

const signUp = (state, payload) => {
  return {
    ...state,
    submitted: payload,
  };
};

const setSignUp = (state, payload) => {
  return {
    ...state,
    signup: payload,
  };
};

const setSignupUrl = (state, payload) => {
  return {
    ...state,
    signupUrl: payload,
  };
};

const toggleAuthModal = (state, payload) => {
  return {
    ...state,
    modals: { ...state.modals, [payload]: !state.modals[payload] },
  };
};

const setAuthDetails = (state, payload) => {
  return {
    ...state,
    authDetails: payload,
  };
};

export default reducer;
