import {
  Box,
  Heading,
  Icon,
  IconButton,
  Image,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';

import GhostWriting from '../../../../../assets/img/Services/ghost-writing.webp';
import LogoDesign from '../../../../../assets/img/Services/logo-design.webp';
import BusinessPlans from '../../../../../assets/img/Services/business-plan.webp';
import ResearchWriting from '../../../../../assets/img/Services/research-writing.webp';
import SocialMedia from '../../../../../assets/img/Services/social-media-marketing.webp';
import SEO from '../../../../../assets/img/Services/seo-writers.webp';
import WordPress from '../../../../../assets/img/Services/wordpress.webp';
import Software from '../../../../../assets/img/Services/software-development.webp';
import ShortVideo from '../../../../../assets/img/Services/short-video-ads.webp';
import VoiceOver from '../../../../../assets/img/Services/voice-over.webp';
import VirtualAssistant from '../../../../../assets/img/Services/virtual-assistants.webp';
import Accounting from '../../../../../assets/img/Services/accounting.webp';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { HomeSection } from '../components/HomeSection';

const topServices = [
  {
    title: 'Ghost Writing',
    copy: 'You name it, we write it',
    href: '/category/service/ghost-writing',
    image: GhostWriting,
  },
  {
    title: 'Logo Design',
    copy: 'Represent your brand',
    href: '/category/service/corporate-branding-and-logo-design',
    image: LogoDesign,
  },
  {
    title: 'Business Plans',
    copy: 'Strategies to grow',
    href: '/category/service/business-plans-and-strategy-documents',
    image: BusinessPlans,
  },
  {
    title: 'Research Writing',
    copy: 'Put the facts together',
    href: '/category/service/research-and-project-writing',
    image: ResearchWriting,
  },
  {
    title: 'Social Media',
    copy: 'Grow your customer base',
    href: '/category/service/social-media-marketing',
    image: SocialMedia,
  },
  {
    title: 'SEO Writers',
    copy: 'Content is king',
    href: '/category/service/web-content-creation',
    image: SEO,
  },
  {
    title: 'WordPress',
    copy: 'Impress your customers',
    href: '/category/service/website-design-and-development',
    image: WordPress,
  },
  {
    title: 'Software Development',
    copy: 'Build your dream app',
    href: '/category/service/computer-software-development',
    image: Software,
  },
  {
    title: 'Short Video Ads',
    copy: 'Charm your audience',
    href: '/category/service/promotional-videos-and-short-ads',
    image: ShortVideo,
  },
  {
    title: 'Voice Over',
    copy: 'Grab their attention',
    href: '/category/service/voice-over-productions-',
    image: VoiceOver,
  },
  {
    title: 'Virtual Assistants',
    copy: 'The support you need',
    href: '/category/service/virtual-assistance',
    image: VirtualAssistant,
  },
  {
    title: 'Accounting',
    copy: 'Balance the books',
    href: '/category/service/accounting-bookkeeping-and-audit-service',
    image: Accounting,
  },
];

const HomeCard = ({ href, title, copy, image }) => {
  return (
    <LinkOverlay as={Link} to={href} bg="#00000020">
      <Box rounded="2xl" overflow="hidden" pos="relative" mr="6">
        {/**Overlay */}
        <Box
          pos="absolute"
          w="full"
          h="full"
          top="0"
          left="0"
          bgGradient="linear(to-b, transparent 40%, #00000080 90%)"
        />

        <Image src={image} alt={copy} />
        <VStack
          p="6"
          py="8"
          pos="absolute"
          bottom="0"
          left="0"
          w="full"
          // h="20%"
          align="start"
          bg=""
          color="white"
        >
          <Heading as="h4" size="lg">
            {title}
          </Heading>
          <Text>{copy}</Text>
        </VStack>
      </Box>
    </LinkOverlay>
  );
};

export const HomeServices = () => {
  const items = useMemo(() => {
    return topServices.map((service) => (
      <HomeCard key={service.title} {...service} />
    ));
  }, []);

  return (
    <HomeSection
      css={`
        //make all cards equal height
        .alice-carousel__stage {
          display: flex;
        }

        .alice-carousel__stage-item {
          flex: 1 0 auto;
          height: auto;
        }

        //buttons
        .alice-carousel__prev-btn {
          position: absolute;
          top: -6.5rem;
          right: 3.5rem;
          width: fit-content;
        }

        .alice-carousel__next-btn {
          position: absolute;
          top: -6.5rem;
          right: 0;
          width: fit-content;
        }

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
          @media screen and (max-width: 990px) {
            display: none;
          }
        }
      `}
    >
      <Heading size="xl" mb={{ base: 6, md: 12 }}>
        Freelance Services in Demand
      </Heading>

      <AliceCarousel
        mouseTracking
        keyboardNavigation
        items={items}
        infinite
        autoPlay
        autoPlayInterval={3000}
        disableDotsControls
        paddingRight={50}
        responsive={{
          0: {
            items: 1,
          },

          // 640: {
          //   items: 2,
          // },
          990: {
            items: 3,
          },
          1120: {
            items: 4,
          },
        }}
        renderPrevButton={() => {
          return (
            <IconButton
              aria-label="previous"
              icon={<Icon as={ChevronLeftIcon} boxSize="icon.md" />}
              variant="outline"
              size="lg"
              bg="whiteAlpha.700"
            />
          );
        }}
        renderNextButton={() => (
          <IconButton
            aria-label="next"
            icon={<Icon as={ChevronRightIcon} boxSize="icon.md" />}
            variant="outline"
            size="lg"
            bg="whiteAlpha.700"
          />
        )}
      />
    </HomeSection>
  );
};
