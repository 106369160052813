import React from 'react';
import aws from '../../../../../assets/img/brands/aws.png';
import ibm from '../../../../../assets/img/brands/ibm.png';
import forbes from '../../../../../assets/img/brands/forbes.png';
import google from '../../../../../assets/img/brands/google.png';
import raeng from '../../../../../assets/img/brands/RAENG.png';
import stripe from '../../../../../assets/img/brands/stripe.png';
import { Container } from '../../../../../styling/Layout';
import styled from 'styled-components';
import breakpoints from '../../../../../constants/breakpoints';
import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

const PartnerLogo = styled(Image)`
  max-height: 30px;
  max-width: 50px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-height: 50px;
    max-width: 85px;
  }
`;

const StackStyle = {
  justifyContent: 'space-between',
};
const forbesStyle = {
  marginTop: '20px',
  marginLeft: '45%',
};

export default function Partner() {
  const showItem = useBreakpointValue({ base: false, md: true });
  return (
    <Box bg="#fafafa" as="section" py="4">
      <Container>
        <Center>
          <HStack
            spacing={{ base: '6', md: 12 }}
            style={StackStyle}
            align="center"
            flexWrap="wrap"
          >
            {showItem && (
              <Text whiteSpace="nowrap" d={{ base: 'none', md: 'block' }}>
                Trusted By:
              </Text>
            )}

            <PartnerLogo src={google} alt="google" />
            <Image src={raeng} alt="raeng" w="130px" maxH="auto" />

            <PartnerLogo src={aws} alt="aws" />
            {showItem && <PartnerLogo src={stripe} alt="stripe" />}
            <PartnerLogo
              src={forbes}
              alt="forbes"
              className="lg"
              style={!showItem ? forbesStyle : null}
            />
            {showItem && <PartnerLogo src={ibm} alt="ibm" />}
          </HStack>
        </Center>
      </Container>
    </Box>
  );
}
