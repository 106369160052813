import {
  AspectRatio,
  Box,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import media from '../../../../../assets/video/business-people.webm';
import icon1 from '../../../../../assets/img/icons/business1.svg';
import icon2 from '../../../../../assets/img/icons/business2.svg';
import icon3 from '../../../../../assets/img/icons/business3.svg';
import icon4 from '../../../../../assets/img/icons/business4.svg';
import icon5 from '../../../../../assets/img/icons/business5.svg';
import { DesktopOnly } from '../../../../../styling/breakpoints';
import { HomeSection } from '../components/HomeSection';

const content = [
  {
    title: 'Quality',
    description: 'Extensive pool of top-tier freelancers you can trust.',
    icon: icon1,
  },
  {
    title: 'Cost Effective',
    description:
      'Forget the old ways, hire only when needs arise and save up to 45% on hiring cost.',
    icon: icon2,
  },
  {
    title: 'Speed',
    description: 'Hire faster, onboard the team you need quickly.',
    icon: icon3,
  },
  {
    title: 'Assurance',
    description:
      'Hire professional freelancers that deliver value or money back guaranteed.',
    icon: icon4,
  },
  {
    title: '24/7 Support',
    description: 'Our customer success team is available to help anytime.',
    icon: icon5,
  },
];

export const Businesses = () => {
  return (
    <HomeSection bg="#eaf4da">
      <Box maxW="550px" mb={{ base: 8, md: 12 }}>
        <Heading size="xl">Why Businesses Stick with TERAWORK</Heading>
      </Box>

      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        alignItems="center"
        gap="8"
        rowGap="12"
      >
        <VStack align="start" spacing="8" maxW="500px">
          {content.map((item) => (
            <HStack key={item.title} align="center" spacing="8">
              <Image src={item.icon} alt="icon" />
              <VStack align="start">
                <Heading as="h4" size="lg">
                  {item.title}
                </Heading>
                <Text>{item.description}</Text>
              </VStack>
            </HStack>
          ))}
        </VStack>
        <DesktopOnly>
          <AspectRatio
            ratio={37 / 40}
            borderRadius="1.5rem"
            overflow="hidden"
            maxW="500px"
          >
            <Box
              as="video"
              autoPlay
              loop
              muted
              src={media}
              width="100%"
              height="100%"
            />
          </AspectRatio>
        </DesktopOnly>
      </SimpleGrid>
    </HomeSection>
  );
};
