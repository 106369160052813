import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HStack } from '@chakra-ui/layout';

import useBoop from '../../../../../hooks/animations/useBoop';
import useClickOutside from '../../../../../hooks/useClickOutside';
import { replaceSpaces } from '../../../../../utils/transfomer';

import { Input, SearchInputWrapper, SearchModeButton } from './style';
import { useEffectOnMountOnly } from '../../../../../hooks/useMountEffects';
import SearchIcon from './icon';
import useDrop from '../../../../../hooks/animations/useDrop';
import SearchOptions from './options';
import { SELLER_APPLICATION } from '../../../../../constants/constants';
import { getSuggestions } from '../../../../../utils/search';
import Suggestions from '../../../../common/Suggestions';

function SearchInput({
  toggleSearch,
  application,
  isAuthenticated,
  buyerHasSellerAccount,
}) {
  const [type, setType] = useState(
    application === SELLER_APPLICATION ? 'jobs' : 'freelancers'
  );
  const [suggestions, setSuggestions] = useState({ data: null, show: false });
  const [text, setText] = useState('');
  const clickRef = useRef();
  const optionsRef = useRef();
  const history = useHistory();

  const [style, trigger] = useBoop({ y: 3 });
  const { toggleDropped, isDropped, menuStyle, caretStyle } = useDrop({});

  const selectType = (type) => {
    setType(type);
    toggleDropped();
  };

  useClickOutside(clickRef, toggleSearch, !isDropped);
  useClickOutside(optionsRef, toggleDropped);

  //trigger boop animation for down caret
  useEffectOnMountOnly(trigger);

  const handleSearch = (e) => {
    e.preventDefault();
    const query = replaceSpaces(text, '+');

    //Return search state to initial
    toggleDropped();
    toggleSearch();

    if (type === 'freelancers') {
      //search for services
      history.push(`/profile-search?query=${query}`);
      return;
    } else if (type === 'jobs') {
      //Search the workstore
      history.push(`/workstore?query=${query}`);
    }
  };

  const onSuggestClick = (text) => {
    const query = replaceSpaces(text, '+');

    //Return search state to initial
    toggleDropped();
    toggleSearch();
    setSuggestions({ show: false, data: null });

    history.push(`/profile-search?query=${query}`);
  };

  const handleSuggest = useCallback(async (text) => {
    const data = await getSuggestions(text);
    setSuggestions({ show: true, data });
  }, []);

  useEffect(() => {
    if (text && type === 'freelancers') {
      const debounce = setTimeout(() => handleSuggest(text), 1000);

      return () => clearTimeout(debounce);
    } else {
      setSuggestions({ show: false, data: null });
    }
  }, [text, type, handleSuggest]);

  const toggleSuggest = () => {
    setSuggestions((prev) => ({ ...prev, show: !prev.show }));
  };

  return (
    <SearchInputWrapper
      ref={clickRef}
      onSubmit={handleSearch}
      onMouseEnter={trigger}
    >
      {application !== SELLER_APPLICATION &&
        (!isAuthenticated || buyerHasSellerAccount) && (
          <SearchIcon
            toggle={toggleDropped}
            styles={{ ...style, ...caretStyle }}
          />
        )}
      <Input
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={`Search for ${type}`}
        autoFocus
      />
      <SearchOptions
        show={isDropped}
        style={menuStyle}
        optionsRef={optionsRef}
        selectType={selectType}
      />
      <Suggestions
        toggle={toggleSuggest}
        suggestions={suggestions.data}
        show={suggestions.show}
        className="search-suggest"
        onClick={onSuggestClick}
      />
    </SearchInputWrapper>
  );
}

function HeaderSearch(props) {
  const [searchMode, setSearchMode] = useState(false);
  const { application } = props;

  const toggleSearch = () => setSearchMode((prev) => !prev);

  return searchMode ? (
    <SearchInput toggleSearch={toggleSearch} {...props} />
  ) : (
    <>
      {application !== SELLER_APPLICATION && (
        <HStack spacing="1.5rem">
          {/* <Link to="/how-it-works">How it Works</Link> */}
          {/* <HStack>
            <ChakraLink href="https://enterprise.terawork.com" isExternal>
              Enterprise
            </ChakraLink>
            <Badge size="sm" colorScheme="green">
              New
            </Badge>
          </HStack> */}
        </HStack>
      )}
      <SearchModeButton onClick={toggleSearch}>Search</SearchModeButton>
    </>
  );
}

export default HeaderSearch;
