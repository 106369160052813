import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import {
  Box,
  Flex,
  HStack,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/layout';
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon';
import { LandingSection } from './LandingSection';

const Feature = ({ title, children, image }) => {
  return (
    <Flex
      flexDir={{ base: 'column', lg: 'row' }}
      _odd={{
        flexDir: { base: 'column', lg: 'row-reverse' },
      }}
      _notLast={{
        mb: '20',
      }}
      align={['start', null, 'center']}
      gap={[6, null, 12]}
    >
      <Box flex={1}>
        <VStack align="start" maxW="450px">
          <Heading size="xl" color="text.header" mb="4">
            {title}
          </Heading>
          <Box>{children}</Box>
        </VStack>
      </Box>

      <Box
        h={{ base: 'auto', lg: '400px' }}
        w={{ base: 'full', lg: '55%' }}
        bg="gray.200"
        pos="relative"
      >
        <Image src={image} alt={title} w="full" h="full" objectFit="cover" />
      </Box>
    </Flex>
  );
};

const content = [
  'Schedule tasks and set up your recurring terms',
  'Pay your team with a single tap',
  'Track deliverables assigned',
];

export const HiringMadeEasy = () => {
  return (
    <LandingSection>
      <Heading
        size="3xl"
        maxW="md"
        textAlign="center"
        lineHeight={1}
        letterSpacing="-2px"
        color="text.header"
        mx="auto"
        mb={['12', null, '10']}
      >
        Hiring made easy:
        <br /> your team, your terms
      </Heading>

      <Feature
        title="Borderless hiring"
        image="/images/landing/borderless.webp"
      >
        <Text>
          Hire African talents from anywhere you are, authorize payment when
          they deliver, and pay in currencies you prefer
        </Text>
      </Feature>

      <Feature
        title="Manage your full-time and freelance team seamlessly"
        image="/images/landing/seamless-hire.webp"
      >
        <VStack as={UnorderedList} align="start" spacing="6" my="4">
          {content.map((text) => (
            <HStack As={ListItem}>
              <Icon
                as={CheckboxCircleFillIcon}
                color="text.brand"
                boxSize="icon.md"
              />

              <Text>{text}</Text>
            </HStack>
          ))}
        </VStack>
      </Feature>
    </LandingSection>
  );
};
