import styled, { css } from 'styled-components';
import { ElementColor } from '../../../constants/Colors';

const buttonHeight = {
  sm: 30,
  md: 40,
  lg: 50,
  xl: 64,
};

const disabledStyle = css`
  opacity: 0.3;
`;

export const ButtonStyle = styled.button`
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  outline: none;
  outline-offset: 2px;
  border: 1px solid ${(props) => ElementColor[props.$colorScheme]};
  border-radius: ${(props) => `${props.rounded ? '50%' : '30px'}`};
  width: ${(props) => `${props.$isFullWidth ? '100%' : 'auto'}`};
  height: ${(props) => `${buttonHeight[props.size]}px`};
  padding: 0 ${(props) => `${props.size === 'sm' ? '1rem' : '2rem'}`};
  background-color: ${(props) =>
    props.variant === 'outline'
      ? ElementColor.white
      : ElementColor[props.$colorScheme]};
  color: ${(props) =>
    props.variant === 'outline'
      ? ElementColor[props.$colorScheme]
      : ElementColor.white};
  ${(props) => props.disabled && disabledStyle};
  transition: 0.3s all ease;

  &:hover {
    background: ${(props) =>
      props.variant === 'outline'
        ? ElementColor[props.$colorScheme]
        : ElementColor.white};
    color: ${(props) =>
      props.variant === 'outline'
        ? ElementColor.white
        : ElementColor[props.$colorScheme]};
  }
`;

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;
  ${(props) => `margin-${props.$spacingPosition}: 10px`};
`;

export const PlainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0.5rem;
  border: none;
  outline: none;
  appearance: none;
  color: inherit;
  ${(props) => props.$isFullWidth && 'width: 100%;'}
`;
