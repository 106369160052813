import { useSpring } from '@react-spring/core';
import { useCallback, useState } from 'react';

function useDrop({ initialState = false, caretRotation = 180 }) {
  const [isDropped, setIsDropped] = useState(initialState);

  const caretStyle = useSpring({
    rotate: isDropped ? caretRotation : 0,
  });

  const menuStyle = useSpring({
    transform: isDropped ? 'translate3D(0,0,0)' : `translate3D(0,-40px,0)`,
    opacity: isDropped ? 1 : 0,
  });

  const toggleDropped = useCallback((value) => {
    setIsDropped((prev) => value || !prev);
  }, []);

  return { caretStyle, menuStyle, toggleDropped, isDropped };
}

export default useDrop;
