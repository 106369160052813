import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { Box, Divider, Link as ChakraLink } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';

import { useSelector } from 'react-redux';
import { isTalent } from '../../../../../utils/talent';
import { appEnv } from '../../../../../utils/appEnv';
import { initiateBecomeASeller } from '../../../../../utils/sellerApp';

const buttonStyles = {
  variant: 'ghost',
  display: 'flex',
  justifyContent: 'space-between',
  w: 'full',
  px: 0,
  size: 'lg',
  fontSize: '14px',
};

const FreelanceSwitcher = () => {
  const buyer = useSelector((state) => state.buyer.user);

  if (!!buyer?.seller_id) {
    return (
      <Button as={ChakraLink} href="/services" isExternal {...buttonStyles}>
        Talent for Freelance
      </Button>
    );
  }

  return (
    <Button onClick={initiateBecomeASeller} {...buttonStyles}>
      Create Freelance Account
    </Button>
  );
};

const TalentSwitcher = () => {
  const userIsTalent = isTalent();

  return (
    <Button
      as={ChakraLink}
      href={`${appEnv.enterpriseAppHost}talent/login`}
      isExternal
      {...buttonStyles}
    >
      {userIsTalent ? 'Talent for Full-time' : 'Create CV'}
    </Button>
  );
};

export const Switcher = () => {
  return (
    <Accordion allowToggle w="full">
      <AccordionItem w="full">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              w="full"
              as={Button}
              justifyContent="space-between"
              bg="primary.200"
              color="primary.500"
              rounded="lg"
              h="48px"
              _hover={{
                bg: 'primary.200',
                color: 'primary.600',
              }}
              shadow="none"
              fontWeight={700}
            >
              <Box>Switch to Talent Mode</Box>

              {isExpanded ? (
                <Icon as={ArrowRightIcon} boxSize="1.2rem" />
              ) : (
                <Icon
                  as={ArrowRightIcon}
                  boxSize="1.2rem"
                  transform="rotate(90deg)"
                />
              )}
            </AccordionButton>
            <AccordionPanel pt="2">
              <FreelanceSwitcher />
              <Divider my={2} borderColor="borderColor" />
              <TalentSwitcher />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
