export const ACTIVE_MENU = 'ACTIVE_MENU';

export const ADD_PACKAGE = 'ADD_PACKAGE';
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE';
export const CLEAR_SELECTED_PACKAGES = 'CLEAR_SELECTED_PACKAGES';
export const SET_PACKAGES = 'SET_PACKAGES';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_VOUCHER = 'SET_VOUCHER';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_SUB_TOTAL = 'SET_SUB_TOTAL';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_SOURCE = 'SET_SOURCE';
export const SET_STATE = 'SET_STATE';
export const SET_DISCOUNT_AMOUNT = 'SET_DISCOUNT_AMOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const SET_PAYMENT_CHARGE = 'SET_PAYMENT_CHARGE';

const actionTypes = {
  ACTIVE_MENU,
  ADD_PACKAGE,
  REMOVE_PACKAGE,
  CLEAR_SELECTED_PACKAGES,
  SET_PACKAGES,
  CHANGE_QUANTITY,
  SET_ORDER_DETAILS,
  SET_DELIVERY_METHOD,
  SET_LOCATION,
  SET_VOUCHER,
  SET_START_DATE,
  SET_SUB_TOTAL,
  SET_TOTAL,
  SET_SOURCE,
  SET_STATE,
  SET_DISCOUNT_AMOUNT,
  REMOVE_DISCOUNT,
  SET_PAYMENT_CHARGE,
};

export default actionTypes;
