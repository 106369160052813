import { lazy } from 'react';
import { MainAppLayout } from '../../components/layout/MainAppLayout';
import { FullScreenLayout } from '../../components/layout/FullScreenLayout';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
const Login = lazy(() => import('./pages/Login/Login'));
const NotFound = lazy(() => import('./pages/Notfound/NotFound'));
const About = lazy(() => import('./pages/About/About'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Terms = lazy(() => import('./pages/Terms/Terms'));
const Sitemap = lazy(() => import('./pages/Sitemap/Sitemap'));
const Faq = lazy(() => import('./pages/Faq/Faq'));
const Category = lazy(() => import('./pages/Category/Category'));
const Compare = lazy(() => import('./pages/Compare/Compare'));
const SignupBuyer = lazy(() => import('./pages/SignupBuyer/SignupBuyer'));
const SignupSeller = lazy(() => import('./pages/SignupSeller/SignupSeller'));
const SignupTalent = lazy(() => import('./pages/SignupTalent'));
const Verify = lazy(() => import('./pages/Verification/Verify'));
const HowItWorks = lazy(() => import('./pages/HowItWorks/HowItWorks'));
const HowItWorksBuyer = lazy(() => import('./pages/HowItWorks/buyer'));
const HowItWorksSeller = lazy(() => import('./pages/HowItWorks/seller'));
const SellerHome = lazy(() => import('./pages/SellerHome/SellerHome'));

const SellerGallery = lazy(() => import('./pages/SellerGallery'));
// const Search = lazy(() => import('./pages/Search/Search'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const WorkStore = lazy(() => import('./pages/WorkStore'));
const MainWorkstore = lazy(() => import('./pages/MainWorkstore'));
const WorkPost = lazy(() => import('./pages/WorkStorePost/index'));
const PasswordReset = lazy(() => import('./pages/PasswordReset/PasswordReset'));

const Promos = lazy(() => import('./pages/PromoStore'));
const Promo = lazy(() => import('./pages/PromoStore/promo'));
const PromoProfile = lazy(() => import('./pages/PromoStore/profile'));
const MaintenancePage = lazy(() => import('./pages/Maintenance'));
const ShareReview = lazy(() => import('./pages/ShareReview/ShareReview'));

const EmployJob = lazy(() => import('./pages/Employ/job'));
const ApplyEmployJob = lazy(() => import('./pages/Employ/apply'));

const Service = lazy(() => import('./pages/Mall/profiles'));
const Search = lazy(() => import('./pages/Mall/search'));
const Categories = lazy(() => import('./pages/Mall/categories'));
const SellerProfile = lazy(() => import('./pages/Mall/seller/profile'));
const ProfileCollection = lazy(() => import('./pages/Mall/collection'));

const ContactUs = lazy(() => import('./pages/ContactUs'));
const ProjectRequest = lazy(() => import('./pages/ContactUs/project'));
const SupportRequest = lazy(() => import('./pages/ContactUs/support'));

const SellerEtiQuette = lazy(() => import('./pages/SellerEtiquette'));
const TaxResources = lazy(() => import('./pages/Resources/Tax'));

const Homepage = lazy(() => import('./pages/MainHome'));

const routes = [
  {
    path: '/home',
    component: Homepage,
    exact: true,
    auth: false,
    fullScreen: true,
    showPromoBanner: false,
    layout: DefaultLayout,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    auth: false,
    fullScreen: true,
    layout: FullScreenLayout,
  },
  {
    path: '/services/login',
    component: Login,
    exact: true,
    auth: false,
    layout: FullScreenLayout,
  },
  {
    path: '/about',
    component: About,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/contact',
    component: ContactUs,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/project-request',
    component: ProjectRequest,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/talk-to-us',
    component: SupportRequest,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/terms-of-service',
    component: Terms,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/sitemap',
    component: Sitemap,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/faq',
    component: Faq,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/service-categories',
    component: Categories,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/hire/:slug',
    component: ProfileCollection,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/category/service/:string',
    component: Service,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/profile-search',
    component: Search,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/category/:string',
    component: Category,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/compare',
    component: Compare,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/buyer-signup',
    component: SignupBuyer,
    exact: true,
    auth: false,
    fullScreen: true,
    layout: FullScreenLayout,
  },
  {
    path: '/talent-signup',
    component: SignupTalent,
    exact: true,
    auth: false,
    fullScreen: true,
    layout: FullScreenLayout,
  },
  {
    path: '/seller-signup',
    component: SignupSeller,
    exact: true,
    auth: false,
    fullScreen: true,
    layout: FullScreenLayout,
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
    auth: false,
    fullScreen: true,
    layout: FullScreenLayout,
  },
  {
    path: '/verify-code',
    component: Verify,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/reset-password',
    component: PasswordReset,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/how-it-works/buyer',
    component: HowItWorksBuyer,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/how-it-works/seller',
    component: HowItWorksSeller,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/seller/promo/:urlName/:id',
    component: PromoProfile,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/seller/:urlName/:profileId',
    component: SellerProfile,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/home/:name',
    component: SellerHome,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/home/:name/gallery',
    component: SellerGallery,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/workstore',
    component: MainWorkstore,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/freelance-jobs',
    component: WorkStore,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/freelance-jobs/:id',
    component: WorkPost,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/promo',
    component: Promos,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/promo/:id',
    component: Promo,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/job/apply/:id',
    component: ApplyEmployJob,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/job/:id',
    component: EmployJob,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/seller-etiquette',
    component: SellerEtiQuette,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/resources/tax',
    component: TaxResources,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/review-share/:urlName/:id',
    component: ShareReview,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    path: '/under-construction',
    component: MaintenancePage,
    exact: true,
    auth: false,
    layout: MainAppLayout,
  },
  {
    component: NotFound,
    auth: false,
    layout: MainAppLayout,
  },
];

export const RedirectRoutes = [
  {
    path: '/grow',
    exact: true,
    redirect: '/',
    auth: false,
  },
  {
    path: '/acc',
    exact: true,
    redirect: '/category/service/accounting-bookkeeping-and-audit-service',
    auth: false,
  },
  {
    path: '/seo',
    exact: true,
    redirect: '/profile-search?query=SEO',
    auth: false,
  },
  {
    path: '/smm',
    exact: true,
    redirect: '/category/service/social-media-marketing',
    auth: false,
  },
  {
    path: '/gph',
    exact: true,
    redirect:
      '/profile-search?query=Logo%20design%20flyer%20design%20presentation%20template%20Graphic%20design',
    auth: false,
  },
  {
    path: '/ecom',
    exact: true,
    redirect: '/category/service/ecommerce-website-development',
    auth: false,
  },
];

export default routes;
