export const SET_AUTH = 'SET_AUTH';
export const SET_APLLICATION = 'SET_APLLICATION';
export const SET_SIGN_UP = 'SET_SIGN_UP';

export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';

export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';
export const SAVE_AUTH_DETAILS = 'SAVE_AUTH_DETAILS';

export const SET_SIGN_UP_URL = 'SET_SIGN_UP_URL';
