import React from 'react';
import { connect } from 'react-redux';
import {
  setAuthDetails,
  toggleAuthModal,
} from '../../../../modules/auth/store/actions';
import Modal from '../../../library/Modal';
import Form from './form';

function PostJobModal({ show, toggle, setJobDetails }) {
  const handlePost = (post) => {
    toggle();
    setJobDetails(post);
  };

  return (
    <Modal isOpen={show} toggleModal={toggle}>
      <Modal.Content>
        <Modal.Header>Post a Job</Modal.Header>
        <Modal.Body>
          <Form post={handlePost} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.auth.modals.jobpost,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: () => dispatch(toggleAuthModal('jobpost')),
    setJobDetails: (data) => dispatch(setAuthDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostJobModal);
