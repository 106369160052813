import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { setApplicationReq } from '../../../../modules/auth/service';
import TopInfo from '../../../common/TopInfo/TopInfo';
import LoginModal from '../../../UI/auth/LoginModal';
import SignUpModal from '../../../UI/auth/SignUpModal';
import VerifyModal from '../../../UI/auth/VerifyModal';
import PostJobModal from '../../../UI/auth/PostJob';
import ChangeHeader from '../../../util/ScrollToTop/ChangeHeader';
import ScrollToTop from '../../../util/ScrollToTop/ScrollToTop';

function LayoutUtilities({ comparable, dispatch, application }) {
  const setApplication = (data) => {
    dispatch(setApplicationReq(data));
  };

  return (
    <>
      <ToastContainer />
      <TopInfo active={comparable}>
        <span>You have selected to compare services</span>{' '}
        <a className="butn butn--green" href="/compare">
          Compare Now
        </a>
      </TopInfo>
      <ScrollToTop />
      <ChangeHeader setApplication={setApplication} application={application} />
      <SignUpModal />
      <VerifyModal />
      <LoginModal />
      <PostJobModal />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    comparable: state.web.comparable,
    application: state.auth.application,
  };
};

export default connect(mapStateToProps)(LayoutUtilities);
