import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Styles
import './PromoCard.scss';

function EndedPromoCard({ promo, borderStyle }) {
  return (
    <Link to="#" className={`promo-card  ended ${borderStyle}`}>
      <div className="card-left">
        <div className="card-left-text">
          <h4> {promo.title} </h4>
          <p> {promo.description} </p>
        </div>
      </div>
      <img
        className="mini-banner"
        src={promo.mini_banner || promo.banner}
        alt={promo.title}
      />
    </Link>
  );
}

EndedPromoCard.propTypes = {
  promo: PropTypes.object.isRequired,
  borderStyle: PropTypes.string,
};

EndedPromoCard.defaultProps = {
  borderStyle: 'shadowed',
};

export default EndedPromoCard;
