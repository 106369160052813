import Building2LineIcon from 'remixicon-react/Building2LineIcon';
import CustomerService2LineIcon from 'remixicon-react/CustomerService2LineIcon';
import Store2LineIcon from 'remixicon-react/Store2LineIcon';
import {
  faPenFancy,
  faPercentage,
  faSearchDollar,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { ElementColor, TextColor } from '../../../../../constants/Colors';

export const navLinks = {
  products: [
    {
      title: 'Freelance',
      url: '/freelance',
      icon: Store2LineIcon,
      iconColor: ElementColor.dark,
    },
    {
      title: 'Outsourcing',
      url: 'https://enterprise.terawork.com/outsourcing',
      isExternal: true,
      icon: CustomerService2LineIcon,
      iconColor: ElementColor.danger,
    },

    {
      title: 'Full-time',
      url: 'https://enterprise.terawork.com/full-time',
      isExternal: true,
      icon: Building2LineIcon,
      iconColor: TextColor.green,
    },
  ],
  talent: [
    {
      title: 'Submit a Full-time Job Request',
      description: 'Hire top talents for full-time or contract roles',
      url: 'https://enterprise.terawork.com/post-job',
      isExternal: true,
      icon: faBuilding,
      iconColor: ElementColor.secondary,
    },
    {
      title: 'Post a Freelance Job',
      description: 'Get bids for your project',
      url: '/workstore/create',
      icon: faPenFancy,
      iconColor: TextColor.green,
    },
  ],
  job: [
    {
      title: 'Search the WorkStore',
      description: 'Explore freelance and full-time opportunities',
      url: '/workstore',
      icon: faSearchDollar,
      iconColor: ElementColor.secondary,
    },
    {
      title: 'Join our Talent Pool',
      description: 'Get access to full-time and contract roles',
      url: '/talent-signup',
      icon: faPercentage,
      iconColor: ElementColor.dark,
    },
  ],
};
