import { Heading } from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PlainButton } from "../../../library/Button/style";
import { VStack } from "../../../library/layout/Stack";

const LinksButton = styled(PlainButton)`
  justify-content: space-between;
  color: white;
  opacity: 0.9;
  width: 100%;
  font-size: 1.3rem;
`;

const Links = styled(VStack)`
  padding-left: 1rem;
  opacity: 0.8;
`;

function FooterLink({ title, href, external }) {
  return external ? (
    <a href={href} rel="noopener noreferrer" target="_blank">
      {title}
    </a>
  ) : (
    <Link to={href}>{title}</Link>
  );
}

function LinkGroup({ title, links, makeActive, index, activeGroup }) {
  return (
    <VStack spacing="1rem" style={{ width: "100%" }}>
      <LinksButton onClick={() => makeActive(index)}>
        <Heading as="h4" size="md">
          {title}
        </Heading>
        {activeGroup !== index && <FontAwesomeIcon icon={faChevronDown} />}
      </LinksButton>
      {activeGroup === index && (
        <Links spacing="0.8rem">
          {links.map((props) => (
            <FooterLink key={props.title} {...props} />
          ))}
        </Links>
      )}
    </VStack>
  );
}

export default LinkGroup;
