import {
  CHAT_WS,
  CHAT_CONVERSATIONS,
  CHAT_MESSAGES,
  CHAT_MESSAGE,
  CHAT_ONLINE_USERS,
  CHAT_SOCKET,
  CHAT_TYPE,
  CHAT_USER,
  ACTIVE_CHAT,
  UPDATE_CONVERSATIONS,
  UPDATE_CONVERSATION_PARTIAL,
  ADD_NEW_USER,
  UPDATE_LAST_MESSAGE,
  SET_ADMIN_CONV,
  UPDATE_ADMIN_LAST_MESSAGE,
} from "./actionTypes";

const initialState = {
  users: [],
  conversations: [],
  activeChat: {},
  messages: {},
  message: [],
  socket: null,
  ws: {},
  onlineUsers: [],
  chatType: "",
  adminConv: null,
};

const setWs = (state, payload) => {
  return {
    ...state,
    ws: payload,
  };
};

const conversations = (state, payload) => {
  return {
    ...state,
    conversations: payload,
  };
};

const messages = (state, payload) => {
  return {
    ...state,
    messages: payload,
  };
};

const message = (state, payload) => {
  return {
    ...state,
    message: payload || [],
  };
};

const onlineUsers = (state, payload) => {
  return {
    ...state,
    onlineUsers: payload,
  };
};

const socket = (state, payload) => {
  return {
    ...state,
    socket: payload,
  };
};

const chatType = (state, payload) => {
  return {
    ...state,
    chatType: payload,
  };
};

const user = (state, payload) => {
  return {
    ...state,
    users: payload,
  };
};

const addUser = (state, payload) => {
  return {
    ...state,
    users: [payload, ...state.users],
  };
};

const activeChat = (state, payload) => {
  return {
    ...state,
    activeChat: payload,
  };
};

const updateConversations = (state, payload) => {
  const oldIdentifier = payload.oldPK ? payload.oldPK : payload.conversationId;
  const newIdentifier = payload.oldPK ? "PK" : "conversationId";

  const newConvos = state.conversations.filter(
    (conv) => conv[newIdentifier] !== oldIdentifier
  );
  return {
    ...state,
    conversations: [payload, ...newConvos],
  };
};

const updateConversationPartial = (state, payload) => {
  const { conversationId, ...rest } = payload;

  const conversations = [...state.conversations];

  const currentConvoIndex = conversations.findIndex(
    (conv) => conv.conversationId === conversationId
  );

  conversations.splice(currentConvoIndex, 1, {
    ...conversations[currentConvoIndex],
    ...rest,
  });

  const newState = { ...state, conversations };

  if (conversationId === state.activeChat?.conversationId) {
    newState.activeChat = conversations[currentConvoIndex];
  }

  return newState;
};

const updateLastMessage = (state, payload) => {
  const oldIdentifier = payload.oldPK ? payload.oldPK : payload.conversationId;
  const newIdentifier = payload.oldPK ? "PK" : "conversationId";

  const newConvs = state.conversations.map((conv) => {
    if (conv[newIdentifier] === oldIdentifier) {
      return { ...conv, ...payload };
    }
    return conv;
  });

  return {
    ...state,
    conversations: [...newConvs],
  };
};

const updateAdminLastMessage = (state, payload) => {
  return {
    ...state,
    adminConv: { ...state.adminConv, ...payload },
  };
};

const setAdminConv = (state, payload) => {
  return {
    ...state,
    adminConv: payload,
  };
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case CHAT_WS:
      return setWs(state, payload);
    case CHAT_CONVERSATIONS:
      return conversations(state, payload);
    case CHAT_MESSAGES:
      return messages(state, payload);
    case CHAT_MESSAGE:
      return message(state, payload);
    case CHAT_ONLINE_USERS:
      return onlineUsers(state, payload);
    case CHAT_SOCKET:
      return socket(state, payload);
    case CHAT_TYPE:
      return chatType(state, payload);
    case CHAT_USER:
      return user(state, payload);
    case ADD_NEW_USER:
      return addUser(state, payload);
    case ACTIVE_CHAT:
      return activeChat(state, payload);
    case UPDATE_CONVERSATIONS:
      return updateConversations(state, payload);
    case UPDATE_CONVERSATION_PARTIAL:
      return updateConversationPartial(state, payload);
    case UPDATE_LAST_MESSAGE:
      return updateLastMessage(state, payload);
    case UPDATE_ADMIN_LAST_MESSAGE:
      return updateAdminLastMessage(state, payload);
    case SET_ADMIN_CONV:
      return setAdminConv(state, payload);
    default:
      return state;
  }
};

export default reducer;
