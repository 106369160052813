import React from 'react';

function Quote({ fill = '#21C0FC', width = 100, height = 84 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.5615 13.1062C84.6494 20.3875 77.1935 28.9428 77.1935 38.7725C83.5494 39.5006 88.8052 42.0794 92.9611 46.5088C97.1169 50.9382 99.1948 56.0654 99.1948 61.8904C99.1948 68.0795 97.178 73.2976 93.1444 77.545C89.1108 81.7924 84.0383 83.9161 77.9268 83.9161C71.082 83.9161 65.1539 81.1553 60.1425 75.6337C55.131 70.1121 52.6253 63.4073 52.6253 55.5193C52.6253 31.8552 65.9482 13.349 92.5944 0L99.5615 13.1062ZM46.9361 13.1062C31.9018 20.3875 24.3848 28.9428 24.3848 38.7725C30.863 39.5006 36.1799 42.0794 40.3357 46.5088C44.4915 50.9382 46.5694 56.0654 46.5694 61.8904C46.5694 68.0795 44.5221 73.2976 40.4274 77.545C36.3327 81.7924 31.2297 83.9161 25.1182 83.9161C18.2733 83.9161 12.3758 81.1553 7.42544 75.6337C2.47512 70.1121 0 63.4073 0 55.5193C0 31.8552 13.2618 13.349 39.7857 0L46.9361 13.1062Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Quote;
