import { requestThirdPartyData } from "./api";

const IP_SAVE_KEY = "ip_data";

export const getUserIpData = async () => {
	const ipEndpoint = `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IPSTACK_ACCESS}`;
	const ipData = await requestThirdPartyData("get", ipEndpoint);

	return ipData.ip ? ipData : null;
};

export const getLocalIP = () => {
	const savedIP = localStorage.getItem(IP_SAVE_KEY);
	if (savedIP === "undefined") {
		localStorage.removeItem(IP_SAVE_KEY);
		return null;
	}

	return JSON.parse(savedIP);
};

export const saveLocalIP = (data) => {
	if (data && data.ip) {
		localStorage.setItem(IP_SAVE_KEY, JSON.stringify(data));
	}
};
