import {
  ACTIVE_MENU,
  ADD_PACKAGE,
  CHANGE_QUANTITY,
  CLEAR_SELECTED_PACKAGES,
  REMOVE_DISCOUNT,
  REMOVE_PACKAGE,
  SET_DELIVERY_METHOD,
  SET_DISCOUNT_AMOUNT,
  SET_LOCATION,
  SET_ORDER_DETAILS,
  SET_PACKAGES,
  SET_PAYMENT_CHARGE,
  SET_SOURCE,
  SET_START_DATE,
  SET_STATE,
  SET_SUB_TOTAL,
  SET_TOTAL,
  SET_VOUCHER,
} from "./action-types";

import OrderState from ".";

const reducer = (state = OrderState, { type, payload = null }) => {
  switch (type) {
    case ACTIVE_MENU:
      return setActiveMenu(state, payload);
    case ADD_PACKAGE:
      return addPackage(state, payload);
    case REMOVE_PACKAGE:
      return removePackage(state, payload);
    case CLEAR_SELECTED_PACKAGES:
      return clearSelectedPackages(state);
    case SET_PACKAGES:
      return setPackages(state, payload);
    case CHANGE_QUANTITY:
      return changeQuantity(state, payload);
    case SET_ORDER_DETAILS:
      return setOrderDetails(state, payload);
    case SET_DELIVERY_METHOD:
      return setDeliveryMethod(state, payload);
    case SET_LOCATION:
      return setLocation(state, payload);
    case SET_VOUCHER:
      return setVoucher(state, payload);
    case SET_START_DATE:
      return setStartDate(state, payload);
    case SET_TOTAL:
      return setTotal(state, payload);
    case SET_SUB_TOTAL:
      return setSubTotal(state, payload);
    case SET_SOURCE:
      return setSource(state, payload);
    case SET_STATE:
      return setState(state, payload);
    case SET_DISCOUNT_AMOUNT:
      return setDiscountAmout(state, payload);
    case REMOVE_DISCOUNT:
      return removeDiscount(state);
    case SET_PAYMENT_CHARGE:
      return setPaymentCharge(state, payload);
    default:
      return state;
  }
};

//Set active menu on Nav tab, based on current page
const setActiveMenu = (state, payload) => {
  if (state.activeMenu === payload) {
    return { ...state };
  }

  return Object.assign({}, state, { activeMenu: payload });
};

//Add newly selected package or replace toggled package(Express or Normal)
const addPackage = (state, payload) => {
  return { ...state, selectedPackages: payload };
};

//Remove package when unselected
const removePackage = (state, payload) => {
  return Object.assign({}, state, { selectedPackages: payload });
};

const clearSelectedPackages = (state) => {
  return { ...state, selectedPackages: [] };
};

const setPackages = (state, payload) => {
  return Object.assign({}, state, { packages: payload });
};

const changeQuantity = (state, payload) => {
  return { ...state, selectedPackages: [...payload] };
};

const setOrderDetails = (state, payload) => {
  return Object.assign({}, state, { orderDetails: payload });
};

const setDeliveryMethod = (state, payload) => {
  return Object.assign({}, state, { deliveryMethod: payload });
};

const setLocation = (state, payload) => {
  return Object.assign({}, state, { deliveryLocation: payload });
};

const setVoucher = (state, payload) => {
  return Object.assign({}, state, { voucher: payload });
};

const setStartDate = (state, payload) => {
  return Object.assign({}, state, { startDate: payload });
};

//sets total price(unconverted), converted total and discounted total
const setTotal = (state, payload) => {
  const newState = { ...state, ...payload };

  //Compute discount total if discount code is applied
  if (state.discountAmount > 0 && state.voucher) {
    const discountedTotal = payload.convertedTotal - state.discountAmount;

    newState.discountedTotal = discountedTotal;
  }

  return newState;
};

const setSubTotal = (state, payload) => {
  return Object.assign({}, state, {
    subTotal: payload,
    convertedSubTotal: payload,
  });
};

const setSource = (state, payload) => {
  return Object.assign({}, state, { source: payload });
};

const setState = (state, payload) => {
  return Object.assign({}, state, payload);
};

const setDiscountAmout = (state, payload) => {
  const discountedTotal = state.convertedTotal - payload;
  return {
    ...state,
    discountAmount: payload,
    discountedTotal: discountedTotal,
  };
};

const removeDiscount = (state) => {
  return {
    ...state,
    voucher: "",
    discountAmount: 0,
    discountedTotal: null,
  };
};

const setPaymentCharge = (state, payload) => {
  return {
    ...state,
    paymentCharge: payload,
    convertedTotal: state.convertedTotal + payload,
    discountedTotal: state.discountedTotal + payload,
  };
};

export default reducer;
