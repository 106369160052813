import queryString from 'query-string';
import { SELLER_URL_PATH } from '../constants/constants';

export const parseQuery = (query) => queryString.parse(query);

export const stringifyQuery = (query) => queryString.stringify(query);

export const checkSellerRoute = () => {
  const urlPath = window.location.pathname;

  const firstPathParameter = urlPath.split('/')[1].toLowerCase();

  return firstPathParameter === SELLER_URL_PATH;
};

export const getPath = (pathname = '', index = 1) => {
  return pathname?.split('/')[index]?.toLowerCase();
};

export const removeFirstForwardSlash = (url_path = '') => {
  const [firstChar, ...rest] = url_path.split('');

  return firstChar === '/' ? rest.join('') : url_path;
};
