import { Box, Button, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import { OnboardingCheckpoint } from '../components/OnboardingCheckpoint';
import { TitledContent } from '../components/TitledContent';
import { useDispatch } from 'react-redux';
import { getAuthSeller } from '../../../service';

export default function OnboardingCompletion() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthSeller());
  }, [dispatch]);

  return (
    <OnboardingCheckpoint
      title="Onboarding Complete!"
      description={
        'Congratulations on completing your application to become a terawork freelancer. An admin will review and approve your profile shortly.'
      }
    >
      <TitledContent title="What next?" />
      <UnorderedList spacing="4" maxW="lg">
        <ListItem listStyleType="inherit" listStylePos="inside">
          <Link
            as={RouterLink}
            to="/services/gallery/create"
            color="text.brand"
          >
            Add Gallery contents to your profile{' '}
          </Link>{' '}
          - impress prospective clients with pictures or write-ups of different
          projects you have worked on.
        </ListItem>
        <ListItem listStyleType="inherit" listStylePos="inside">
          <Link
            as={RouterLink}
            to="/services/promo-profile/create"
            color="text.brand"
          >
            Build a promo profile{' '}
          </Link>{' '}
          - a proven way to get you going as a newbie on the platform
        </ListItem>
      </UnorderedList>
      <Box maxW="md" mt="8">
        <Button
          as={RouterLink}
          to="/services/portfolio"
          variant="primary"
          size="lg"
          w="full"
        >
          Go to Portfolio
        </Button>
      </Box>
    </OnboardingCheckpoint>
  );
}
