import { toast } from "react-toastify";
import { css } from "glamor";

const Notify = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: "toast-success-container",
      progressClassName: css({
        visibility: "hidden",
      }),
    });
  },

  error(msg, options = {}) {
    return toast.error(msg, {
      autoClose: 5000,
      className: "toast-error-container",
      progressClassName: css({
        display: "none",
        visibility: "hidden",
      }),
      ...options,
    });
  },

  info(msg, options = {}) {
    return toast(msg, {
      ...options,
      className: "toast-info-container",
      progressClassName: css({
        visibility: "hidden",
      }),
    });
  },

  warning(msg, options = {}) {
    return toast(msg, {
      ...options,
      className: "toast-warning-container",
      progressClassName: css({
        visibility: "hidden",
      }),
    });
  },

  simple(msg, options = {}) {
    return toast(msg, {
      ...options,
      // className: 'toast-error-container',
      progressClassName: css({
        display: "none",
        visibility: "hidden",
      }),
    });
  },
};

export default Notify;
