import React, { useEffect, useMemo, useState, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import shortid from 'shortid';
import { connect } from 'react-redux';

import Auth from '../utils/Auth';
import { api, publicApi } from '../utils/api';

//import components
import { compare } from '../utils/compare';
import { getCurrency } from '../utils/currency';
import { checkSellerRoute } from '../utils/UrlParameter';
import routes from './routes';
import SocketHandler from '../SocketHandler.jsx';
import PublicRoute from './Public';
import PrivateRoute from './Private';
import AppLoader from '../components/common/AppLoader/AppLoader';
import ErrorHandler from '../components/util/ErrorHandler/ErrorHandler';
import { setAuthReq } from '../modules/auth/service';
import {
  selectedCurrencyReq,
  getConversionRates,
  setDependency,
} from '../modules/web/service';
import { setComparableReq } from '../modules/web/pages/Compare/service';
import { getUserProfile, authChecks } from './service';
import LayoutUtilities from '../components/layout/new/Utility';

//Pages
import home from '../modules/web/pages/Home';
import mainHome from '../modules/web/pages/MainHome';
import { useDependencies } from '../hooks/useDependencies';
import * as webActions from '../modules/web/store/actions';
import useAuthentication from '../hooks/useAuthetication';
import { setBuyerDataReq } from '../modules/buyer/service';
import { setSellerDataReq } from '../modules/seller/service';
import { MainAppLayout } from '../components/layout/MainAppLayout';
import { DefaultLayout } from '../components/layout/DefaultLayout';
import { useDiscountInterceptor } from '../hooks/useDiscount';
import { useAffiliateInterceptor } from '../hooks/useAffiliate';
import { usePremiumInterceptor } from '../hooks/usePremium';

const Routes = React.memo((props) => {
  const [loading, setLoading] = useState(true);
  const { getStoredDependencies } = useDependencies();
  const { getBuyerFromLocal, getSellerFromLocal } = useAuthentication();

  useEffect(() => {
    (async function () {
      const { dispatch } = props;

      //get depedencies from localStore and dispatch without waiting for dependency from backend
      const storedDeps = await getStoredDependencies();
      if (storedDeps) {
        await dispatch(webActions.setDependency(storedDeps));
        dispatch(setDependency());
      } else {
        await dispatch(setDependency());
      }

      const authCheck = authChecks();
      const selectedCurrency = await getCurrency();
      const auth = new Auth();

      if (authCheck) {
        //Set the tokens
        api.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${auth.getUserToken('idToken')}`;

        publicApi.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${auth.getUserToken('idToken')}`;

        const storedBuyer = await getBuyerFromLocal();
        const storedSeller = await getSellerFromLocal();

        const isSellerRoute = checkSellerRoute();

        if (storedBuyer && !isSellerRoute) {
          await dispatch(setBuyerDataReq(storedBuyer));
          dispatch(getUserProfile(isSellerRoute));
        } else if (storedSeller && isSellerRoute) {
          await dispatch(setBuyerDataReq(storedBuyer));
          await dispatch(setSellerDataReq(storedSeller));
          dispatch(getUserProfile(isSellerRoute));
        } else {
          await dispatch(getUserProfile(isSellerRoute));
        }
        //Get seller profile if its sellerApp
      }

      dispatch(setComparableReq(compare.get().length > 1));
      dispatch(selectedCurrencyReq(selectedCurrency));
      dispatch(getConversionRates(selectedCurrency.id));
      await dispatch(setAuthReq(authCheck));

      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoutes = useMemo(
    () =>
      routes.map((route) =>
        route.redirect ? (
          <Redirect
            key={shortid.generate()}
            from={route.path}
            to={route.redirect}
            {...route}
          />
        ) : route.auth ? (
          <PrivateRoute
            key={shortid.generate()}
            {...route}
            authenticated={authChecks()}
            application={props.application}
            buyer={props.buyer}
            seller={props.seller}
          />
        ) : (
          <PublicRoute key={shortid.generate()} {...route} />
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //Save any discount from url
  useDiscountInterceptor();
  useAffiliateInterceptor();
  usePremiumInterceptor();

  return (
    <Router>
      <SocketHandler>
        <LayoutUtilities />
        <ErrorHandler>
          <Suspense fallback={<AppLoader />}>
            <Switch>
              <PublicRoute
                layout={DefaultLayout}
                path="/"
                exact
                fullScreen
                showPromoBanner={false}
                component={mainHome}
                showCurrencyChanger={false}
              />
              <PublicRoute
                layout={MainAppLayout}
                path="/freelance"
                exact
                component={home}
              />
              <PublicRoute
                layout={DefaultLayout}
                path="/index"
                fullScreen
                showPromoBanner={false}
                exact
                component={mainHome}
              />
              {loading ? <AppLoader /> : renderRoutes}
            </Switch>
          </Suspense>
        </ErrorHandler>
      </SocketHandler>
    </Router>
  );
});

const mapStateToProps = (state) => {
  return {
    selectedCurrency: state.web.selectedCurrency,
    authenticated: state.auth.authenticated,
    buyer: state.buyer.user,
    pendingOrders: state.buyer.pendingOrders,
    seller: state.seller.user,
    application: state.auth.application,
  };
};

export default connect(mapStateToProps)(Routes);
