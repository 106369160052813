import styled from "styled-components";
import breakpoints from "../../../constants/breakpoints";
import { ElementColor } from "../../../constants/Colors";

export const SearchWrapper = styled.div`
  width: 100%;
  position: relative;

  .search-suggest {
    top: 100%;
    z-index: 1;
    margin-top: 1rem;

    @media screen and (min-width: ${breakpoints.lg + 1}px) {
      margin-top: -1rem;
    }
  }
`;

export const SearchForm = styled.form`
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    padding: 1rem;
    box-shadow: 0px 4px 25px rgba(197, 197, 197, 0.2);
    border-radius: 50px;
    background: #fff;
  }
`;

export const MobileSearchForm = styled.div`
  border: 1px solid ${ElementColor.line};
  border-radius: 50px;
  padding: 0.2rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileSearchInput = styled.input`
  border: none;
  outline: none;
`;

export const MobileSearchButton = styled.button`
  border-radius: 50%;
  padding: 0.7rem;
  border: none;
  background: ${ElementColor.primary};
  height: 100%;
`;

export const DesktopSearchInput = styled.input`
  border: 1px solid ${ElementColor.line};
  border-radius: 50px;
  padding: 1rem 0.2rem;
  padding-left: 0.5rem;
  width: 100%;
  outline: none;
`;
