import { lazy } from "react";
import { DefaultLayout } from "../../../../components/layout/DefaultLayout";
const Title = lazy(() => import("./Title"));
const Description = lazy(() => import("./Description"));
const CreatePackages = lazy(() => import("./Packages"));
const Skills = lazy(() => import("./Skills"));
const Media = lazy(() => import("./Media"));
const Delivery = lazy(() => import("./Delivery"));

export const editProfileRoutes = [
	{
		path: "/services/edit-profile/title",
		component: Title,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
	{
		path: "/services/edit-profile/description",
		component: Description,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
	{
		path: "/services/edit-profile/packages",
		component: CreatePackages,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
	{
		path: "/services/edit-profile/skills",
		component: Skills,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
	{
		path: "/services/edit-profile/delivery",
		component: Delivery,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
	{
		path: "/services/edit-profile/media",
		component: Media,
		exact: true,
		auth: true,
		role: "Sellers",
		layout: DefaultLayout,
	},
];
