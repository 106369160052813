import swal from 'sweetalert';
import moment from 'moment';
import { requestApi } from './api';

export const paymentSuccess = (callback) => {
  swal({
    title: 'TRANSACTION SUCCESSFUL',
    text: '…yet another giant stride. Keep achieving new milestones',
    icon: 'success',
  }).then(callback);
};

export const paymentMade = async () => {
  swal({
    icon: 'success',
    title: 'PAYMENT MADE',
  }).then(() => (window.location.href = '/transactions'));
};

export const filterPaystackAndRavePayments = (orders = []) => {
  const VENDOR_STRING_PAYSTACK = 'paystack';
  const VENDOR_STRING_RAVE = 'flutterwave';

  return orders.filter(
    (order) =>
      order.vendor_ref === VENDOR_STRING_PAYSTACK ||
      order.vendor_ref === VENDOR_STRING_RAVE
  );
};

const filterExpiredPayments = (orders = []) => {
  return orders.filter((order) => {
    if (!order.expiry_date) {
      return false;
    }

    const today = moment(Date.now());
    const expiryDate = moment(order.expiry_date);

    return today.isBefore(expiryDate, 'minute');
  });
};

const verifyPayment = async (order) => {
  const { data } = await requestApi(
    'post',
    `/buyer/payment/pay-with-${order.vendor_ref}/${order.id}/${order.tx_ref}`
  );

  return data;
};

export const checkPayments = async (payments) => {
  const unexpiredPayments = filterExpiredPayments(payments);
  const check = filterPaystackAndRavePayments(unexpiredPayments);

  if (check.length === 0) {
    return false;
  }

  const result = await Promise.all(
    check.map((payment) => verifyPayment(payment))
  );

  return result.find((order) => order.payment_status);
};

export const isPaymentOrSellerPage = (pathname = '') => {
  const firstPath = pathname.split('/')[1];
  const paymentUrls = ['pending-orders', 'services'];

  return paymentUrls.includes(firstPath);
};
