import * as actions from "./store/actions";
import { setApplication } from "../auth/store";

import { API_SUCCESS } from "../../constants/constants";

import { requestApi, requestHandler } from "../../utils/api";
import Transformer from "../../utils/transformer";

export function setSellerDataReq(val) {
  return (dispatch) => dispatch(actions.setSellerData(val));
}

export function setWorkInfoReq(val) {
  return (dispatch) => dispatch(actions.setWorkInfo(val));
}

export function getAuthSeller() {
  return async (dispatch) => {
    const { data, type } = await requestApi("get", "seller/profile");
    if (type === API_SUCCESS && data.user) {
      dispatch(setSellerDataReq(data.user));
    }
  };
}

export function setSellerProfile() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      "get",
      "seller/service-info-admin",
      false,
      true
    );

    type === API_SUCCESS &&
      dispatch(actions.setProfiles(data?.service_infos ?? []));
  };
}

export async function renewVow() {
  try {
    const result = await requestHandler({
      method: "post",
      path: "/seller/renew-your-vow",
    });

    return [null, result];
  } catch (err) {
    return [err];
  }
}

export const switchToSellerAppHanlder = (seller) => {
  return async (dispatch) => {
    if (!seller.id) {
      const { data, type } = await requestApi(
        "get",
        "seller/profile",
        false,
        true
      );

      if (type === API_SUCCESS) {
        await dispatch(actions.setSellerData(data.user));
        dispatch(setApplication("seller"));
        return data?.user;
      }
      return false;
    }

    dispatch(setApplication("seller"));
    return seller;
  };
};

export function getOngoingWork() {
  return async (dispatch) => {
    const { data, type: status } = await requestApi(
      "get",
      `seller/ongoing-works`,
      false,
      true
    );

    if (status === API_SUCCESS) {
      const ongoingWorks = data.ongoing_works.reverse();

      dispatch(actions.setOngoingWork(ongoingWorks));
      return;
    }

    dispatch(actions.setOngoingWork([]));
  };
}

export function getWorkHistory({ page = 1 }) {
  return async (dispatch) => {
    const { data, type: status } = await requestApi(
      "get",
      `seller/work-histories?page=${page}`,
      false,
      true
    );

    if (status === API_SUCCESS) {
      dispatch(actions.setWorkHistory(data.work_histories));
      return;
    }

    dispatch(actions.setWorkHistory({}));
  };
}

export function getReview({ page = 1, url = "seller/post-reviews" }) {
  return async (dispatch) => {
    url = `${url}?page=${page}`;
    const { data } = await requestApi("get", url, false);
    const { data: reviews, ...rest } = data.reviews;

    data.reviews &&
      dispatch(actions.setReview({ data: reviews.reverse(), ...rest }));
    return data.reviews;
  };
}

export function getWallet() {
  return async (dispatch) => {
    const url = "/seller/wallets";
    const { data } = await requestApi("get", url, false);

    data.wallet && dispatch(actions.setWallet(data.wallet));
    return data.wallet;
  };
}

export function getNotifications() {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      "get",
      "seller/notifications",
      false
    );

    if (type === API_SUCCESS && data) {
      dispatch(actions.setNotifications(data));
    }
  };
}

export function getProfilePreview({ urlName, profileId }) {
  return async (dispatch) => {
    const { data, type } = await requestApi(
      "get",
      `/seller/service-info-admin/preview/${urlName}/${profileId}`
    );

    if (type === API_SUCCESS && data) {
      const transformedResult = Transformer.fetch(data.seller_service);
      dispatch(actions.setProfilePreview(transformedResult));
    }
  };
}
