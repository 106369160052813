import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ElementColor, TextColor } from '../../../constants/Colors';

const PillStyle = styled(Link)`
  border-radius: 30px;
  padding: 0.1rem 0.7rem;
  font-size: 0.8rem;
  background: ${ElementColor.primaryTrns};
  color: ${TextColor.green};
`;

function Pill({ link, colorScheme = 'primary', onClick, as, children }) {
  return (
    <PillStyle to={link} onClick={onClick && onClick()}>
      {children}
    </PillStyle>
  );
}

Pill.propTypes = {
  link: PropTypes.string,
  colorScheme: PropTypes.string,
  onClick: PropTypes.func,
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Pill;
